import React, { Component } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

class ElasticLoadBalancers extends Component {

    openUrl(value){
        window.open(value, false);
      }
      
      
  render() {
    return (
      <div className="pad-top-30 pad-left-page">
          <div class="row">
            <div class="col-sm-7 col-md-5">
                  <div class="">
                    <h2>Elastic Load Balancers</h2>                    
                    

                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                Elastic Load Balancers
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           
                            <AccordionItemPanel>
                            load balance -  virtual clients that spreads the load serve web traffic <br/><br/>

                            classic load balancer<br/>
                            usecase ex: app built with ec2 classic<br/>
                            supports http/https/ssl(secure tcp) and tcp<br/>
                            backend server authentication available (not in ALB)<br/>
                            layer 4. does usual routing decisions at TCP laye. can do some layer 7 routing<br/>
                            application load balancer<br/>
                            usecase ex: if flexible app management and TLS termination required<br/>
                            supports http/https/ssl(secure tcp) and tcp. http/2 support<br/>
                            layer 7. works in application layer. http/https. can do real clever routing thorugh this<br/>
                                backend server authentication NOT available<br/>
                            Additional benefits (compared to classic) available<br/>
                            - Websockets/secure websockets<br/>
                            - Request Tracing<br/><br/>
                            
                            network load balancer<br/>
                            - for extreme performance and static ip<br/>
                                supports layer 4 (TCP) listeners<br/><br/>


                            can be internal or external layer<br/>
                            can enable advanced VPC configuration<br/>

                            Application LB requires "Target Group"<br/>
                            ALB have option to "override" port number. By default on the traffic port ("ex: 80 for http)<br/>
                            ALB takes an option fo "Success Codes" - 200 to 299<br/>

                            listener configuration requires protocol - http or https. Ports - http/80<br/>
                            security group needs to be provide<br/>
                            security settings accepts SSL information<br/>
                            can configure health check<br/>
                            - ping protocol can be http/tcp & specific ping port/file<br/>
                            - response timeout - time out to wait when receiving response from hc (2 sec to 60 sec)<br/>
                            - interval - time betweeen health check (5 sec to 300 sec)<br/>
                            - unhealthy threshold - no of consecutive hc failures before declaring unhealthy<br/>
                            - healthy threshold - no of consecutive hc before declaring healthy<br/><br/>


                            Cross-Zone load balancing - by default selected in Classic LB<br/>
                            - to split across all the instances within the availability zone<br/>

                            ex: 2 AZ, 1-AZ: 6 servers, 2-AZ:4 servers. LB would route 50% across each zones<br/>
                            if cross zone LB is enabled, 60% to 1AZ (across 6 servers) & 40% to 2AZ (for 4 servers)<br/>

                            Connection Draining - ex: 300 seconds <br/>
                            to handle in-flight requests that are being served before draining the connection<br/><br/>

                            Points<br/>
                            - ELB has DNS name only. no IP address provided (amazon manages it)<br/>
                            - have more than one instance on an AZ to have highly available architecture<br/>
                            - ALB defaults have cross zone load balancing. CLB has checkbox by default selected<br/>
                            - "InService" or "OutOfService" - instances monitored by ELB are reported as<br/>
                            - HTTPS termination : Both ALB & CLB supports it. <br/>
                            1) install an SSL certificate on your load balancer<br/>
                            2) ELB uses this certificate to terminate the connection<br/>
                            3) decrypts the request before sending them to targets<br/><br/>

                            Developer Notes:<br/>
                            aws elb describe-load-balancers    - describe classic load balancer<br/>
                            aws elbv2 describe-load-balancers  - describe application load balancer		<br/>		
                                                        
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>    
                    
                      
                </div>              
            </div>
           
          </div>
      </div>
    );
  }
}
 
export default ElasticLoadBalancers;