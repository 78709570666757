import React, { Component } from "react";
import TypoGraphy from '@material-ui/core/Typography'
import { Grid, Paper, Typography, List, ListItem } from "@material-ui/core";

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

class Overview extends Component {

    constructor(props){
        super(props)
           window.clickLog("load", "Overview")    
      } 

    openUrl(value){
        window.open(value, false);
      }
      
      
  render() {
    return (
        <div>
            <div style={{ marginTop: 20, padding: 30 }}>
                <Grid container spacing={40} justify="center">
                    <TypoGraphy variant="h4">
                        <center>AWS Services</center>
                    </TypoGraphy>
                </Grid>  
                <div className="pad-left-page">
            <div class="row">
                <div class="col-sm-7 col-md-5">
                    <div class="">
                        
                        <Accordion>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    AWS Overview / Notes 
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                            
                                <AccordionItemPanel>
                                Approximately 16 regions (numbers grow everyday/peridically) - geographical area. 
                                <br/>	Mostly 2 or more availability zones - availability zone is a data center Ex: North america - virgina, oregon, govclod, ohio, north carolina
                                <br/>
                                
                                CDN - content delivery network is a system of distributed servers(network) that delivers webpages and other web content 
                                to a user based on geographic locations of the user, the origin of the webpage and a content delivery server
                                <br/>
                                AWS Region: A distinct location within a geographic area designed to provide high availability to a specific geography.
                                <br/>
                                Availability Zones: Distinct locations from within an AWS region that are engineered to be isolated from failures.
                                <br/>
                                    
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>    
                        <div className="pad-top-10"></div>
                        <Accordion>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    Databases
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                            
                                <AccordionItemPanel>
                                
                                - 1) RDS - Relational database service - mysql, postgresql, maria, sql, oracle<br/><br/>

                                - 2) DynamoDB - non relational database. NoSql. Really scalable and high performance.<br/><br/>

                                - 3) Redshift - Amazon's data ware house solution.  Ex: copy production data to redshift for reporting etc.,<br/><br/>

                                - 4) Elasticache - way of caching the data in the cloud. <br/><br/>
                                <br/>

                                <br/>


                                <br/>
                                Elastic Beanstalk - beanstalk helps to upload code. Provisions and deploys underlying structure
                                <br/>
                                Lambda -  servlets. Uploads the code and doesn't connect physically like EC2.  ex: connecting to Alexa is through lambda
                                <br/>
                                Lightsail - brand new service. Out of box cloud. Ex: wordpress site can be deployed for people who doesn't have any idea on AWS

                                    
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>    
                        <div className="pad-top-10"></div>
                        <Accordion>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    EBS / Storage - 4 components
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                            
                                <AccordionItemPanel>
                                S3 - Simple storage service virtual disk in the cloud where we can save objects/files (word docs/ppts/movies/text files etc.,) . Place to install a DB, computer game - ex: block based
                                Drop box started saving their objects in S3. saves their metadata inside their data center

                                    s3 provides dev and it secure durable and highly scalable object  storage. s3 is easy to use, with a simple web services interface to store and retrieve any amount of data from anywhere on the web

                                        <br/>
                                                
                                        - 
                                        a) Glacier - archives the files from S3. low cost archive in glacier for compliance (or any other access). But takes 3/4 hours to retrieve
                                        <br/><br/>
                                        - 
                                        b) EFS - elastic file service. Block based service where we can save it. Can install save applications, database and can save it with multiple
                                        AWS service is effectively a NAS in the cloud, allowing you to connect it to multiple EC2 instances at once?
                                        <br/><br/>
                                        - 
                                        c) Storage gateway - connecting a S3 to an on premise data central. Generally a virtual m/c that connects to S3
                                        <br/><br/>
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>    
                        <div className="pad-top-10"></div>
                        <Accordion>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    Networking & content Delivery:
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                            
                                <AccordionItemPanel>
                                Networking & content Delivery: <br/>
                                VPC - Virtual private cloud. Similar to a virtual data center. Multiple vpcs per region. We can connect from one VPC to another
                                <br/>
                                Security & Identity <br/>
                                IAM - Identity Access Management <br/>
                                - setup new users, assign users/groups - fundamental identity and access management<br/>
                                - allows you to manage users and their level of access to aws console. <br/>
                                - centralized conrol of aws account <br/>
                                - shared access  <br/>
                                - granular permissions <br/>
                                - identity federation (include Active directory, facebook, linked in) <br/>
                                - multifactor authentication <br/>
                                - provides temp access for users/devices and service where necessary <br/>
                                - password/rotation policy <br/>
                                - integrates with other multiple aws services <br/>
                                - PCI/DSS compliance <br/>
                                - Users - end users <br/>
                                - group - collection of users under one set of permissions <br/>
                                - roles - what an user or group[ can do to aws resources <br/>
                                - policies - document that identifies one or more permissions <br/>
                                <br/>
                                Inspector - agent on VM. Inspects those machines and security reporting
                                <br/>
                                Certificate Manager - gives free SSO certificates for domain services
                                <br/>
                                Directory Service - Way of using active directory to AWS
                                <br/>
                                WAF - web application firewall. Traditionally firewall gives network protection. WAF gives application level protection with sql injection etc.,
                                <br/>
                                Artifacts - document in the AWS console.  Access controlled documents for compliance & security etc.,
                                <br/>
                                    
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>  
                        <div className="pad-top-10"></div>
                        <Accordion>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    Management Tools
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                            
                                <AccordionItemPanel>
                                - Cloud Watch - used to monitor performance of AWS environement like EC2 - cpu, disk utilization, network, status checks - , ram utilization can be customized,<br/>

                                - Cloud Formation - way of turning infrastructure into code. Instead of physical  network switches, load balancers, servers, firewalls aws documents into template. 
                                Ex: run one single command line and provision 50 servers and deploy them at will<br/>

                                - Cloud Trail - way of auditing the AWS. Ex: record when an user is created<br/>
                                &nbsp;
                                - Opsworks - automating deployments<br/>

                                - Config - config manager monitors environment when config is broken. Helps to audit and helps to set alerts (ex: set alert for security group while monitoring  an user action)<br/>

                                - Service catalog - service designed for larger enterprises. Specific images for EC2 or specific services that are authorized. <br/>

                                - Trusted Advisor - gives tips of perf optimization, automated way of scanning the environment and gives tips<br/>

                                - Cloud Front: AWS service is a Content Delivery Network?<br/>
                                    
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>  
                        <div className="pad-top-10"></div>
                        <Accordion>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    Route53
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                            
                                <AccordionItemPanel>
                                Route53 - Amazon's DNS Service. 53 is the DNS port route 66 is the first interstate
                                <br/>
                                Cloud Front - was in storage section of AWS. Now under networking and content delivery. Basically consists of edge locations 
                                that will cache assets like media
                                <br/>
                                Direct Connect - connects office or physical centers using dedicated telephone line. Reliable internet connection
                                <br/>
                                EC2 - elastic compute cloud. Virtual machines in cloud. 
                                <br/>
                                EC2 container service - highly scalable container management service. docker container. On virtual machines. 
                                <br/>
                                Sometimes referred as ECS
                                    
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>  
                        <div className="pad-top-10"></div>
                        <Accordion>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    Migration Services 
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                            
                                <AccordionItemPanel>
                                - 1) Snowball - for connecting and transferring data from disks  like sata. To move terrabytes of data. It’s a brief case sized appliance and it loads the data and sends it to cloud<br/>

                                - 2) DMS - database migration services. Migrate on prem data to AWS cloud. Also from inside aws cloud to other regions or redshift etc.  Oracle, sql, mysql, postgresql, aurora,<br/>

                                - 3) SMS - server migration services.  This targets virtual machines. Specifically VMs. Replicates VM machines to AWS cloud<br/>

                                - 4) Athena - allows to run SQL queries in S3. ex: to run queries on a flat files and return data<br/>
                                <br/>
                                    
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>  
                        <div className="pad-top-10"></div>
                        <Accordion>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    Analytics Services
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                            
                                <AccordionItemPanel>
                                - 1) EMR - elastic map reduce - for big data processing. Helps to reduce parse data. Uses "Hadoop" as framework
                                AWS service is specifically designed to assist you in processing large data sets?<br/>

                                    - 2) create a search engine for the app
                                        Cloud Search - fully managed search provided
                                        ElasticSearch - open source <br/>

                                    - 3) Kinesis - way of streaming realtime data. Capture terrabytes of data and analyze, analyze social media feed etc.,. Ex: to perform a sentiment analysis, election etc.,
                                    AWS service is used for collating large amounts of data streamed from multiple sources<br/>

                                    - 4) Data pipeline - service to move data from one to another. Ex: from S3 to dynamoDB. Or vice versa<br/>

                                    - 5) Quick Sight - business analytic tool. Rich dashboard in AWS. Analyze data from s3, dynamodb etc.,<br/>
                                <br/>
                                    
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>  
                        <div className="pad-top-10"></div>
                        <Accordion>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    Application Services
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                            
                                <AccordionItemPanel>
                                - Step functions - way of visualizing inside the applications.<br/>

                                - SWF - simple workflow functions - uses in amazon fulfillment. Coordinates human led and automated tasks<br/>

                                - API Gateway - door to allows create, publish maintain apis to access, access backend business logic etc., ex: connects to api gateway and connects to lambda function <br/>

                                - AppStream - way of streaming desktop applications to users<br/>

                                - Elastic Transcoder - Your digital media agency needs to convert its media files to formats that can be viewed on a variety of devices. Which AWS service should you use to meet this need?<br/>

                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>  
                        <div className="pad-top-10"></div>
                        <Accordion>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    AWS Developer Platform Services
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                            
                                <AccordionItemPanel>
                                - Codecommit - code maintain<br/>
                                - Codebuild - compiling the code<br/>
                                - Codedeploy - deploy in Ec2 instances<br/>
                                - Codepipeline - tracking maintaining different versions of code<br/>
                                <br/>
                                    
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>  
                        <div className="pad-top-10"></div>
                        <Accordion>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    Mobile Service
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                            
                                <AccordionItemPanel>
                                - Mobile hub - own console for mobile app. user authentication, push notification, <br/>
                                - Cognito - user sign up etc., ex: sign in with gmail<br/>
                                - Device farm - enables to improve the quality of android/ios by quickly testing in aws data center<br/>
                                - Mobile analytics - cost effeective way of analyzing mobile data<br/>
                                - Pinpoint - enables to understand and engage with app users. Ex: for target marketing. <br/>
                                - Similar to google analytics<br/>
                                    
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>  
                        <div className="pad-top-10"></div>
                        <Accordion>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    Artificial Intelligence
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                            
                                <AccordionItemPanel>
                                - Alexa - amazons voice service. Essentially talking to lambda<br/>
                                - Lex  - interface internally connects to lex.  We can use without alexa<br/>
                                - Polly - text to speech. <br/>
                                - Machine learning - based on dataset<br/>
                                - Rekognition - upload pic. Picture analysis . Facial recognition<br/>
                                
                                    
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>  
                        <div className="pad-top-10"></div>
                        <Accordion>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    Messaging
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                            
                                <AccordionItemPanel>
                                - SNS - simple notification service - email. Text, we can publish to http/https. Your system administrators need to receive notification of specified events affecting your AWS environment (such as alarms, etc.) Which service should you enable?<br/>
                                - SQS - Queue system.decoupling application. <br/>
                                - SES - simple email service. Email using AWS<br/>
                                
                                    
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>  
                        
                    </div>              
                </div>
            
            </div>
        </div>
            </div>
        </div>
    );
  }
}
 
export default Overview;