import React, { Component } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

class KinesisTranscoders extends Component {

    openUrl(value){
        window.open(value, false);
      }
      
      
  render() {
    return (
      <div className="pad-top-30 pad-left-page">
          <div class="row">
            <div class="col-sm-7 col-md-5">
                  <div class="">
                    <h2>Kinesis</h2>    <br/>                
                   

                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                Kinesis
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           
                            <AccordionItemPanel>
                            is a platform on aws to send your streaming data too

                            what is streaming data - data that is generated continuously thousands of data sources
                            ex: stock , game data, social network , purchase from online stors (amazon), geospatial data (uber), iot sensor
                            <br/><br/>
                            Kinesis services<br/>
                                -Kinesis streams<br/>
                                -Kinesis firehose<br/>
                                -Kinesis analytics<br/><br/>

                            - kinesis streams<br/>
                                Producers (ec2/tablets/phones/laptops) sends data to kinesis streams (Shard) - then sent to consumers (EC2) finaly sent to DB/analytics/elastic map reduce etc
                                automatic data retention window - 24 hours upto 7 days<br/>
                            Producers --> Shard --> Ec2 --> backend (eb or EMR or red shift)<br/>
                            <br/>
                            - kinesis firehose<br/>
                                Producers (ec2/tablets/phones/laptops) sends data to kinesis firehose and sends to S3<br/>
                                firehose does not have any shards. completely automated it can analyse realtime with lambda and send for analytics.<br/>
                                automatic data retention window - analyzed immediately and sent. ex: to S3 and to red shift. we can also send it to elastic search cluster<br/>
                                Producers --> firehose --> s3<br/>
                            <br/>
                            - Kinesis analytics<br/>
                                allows you to run sql queries of the data. and use across kinesis streams and firehoses<br/>
                            <br/>
                            <br/>
                            <br/>
                            SHARDS
                            <br/>
                            a shard is uniquely identified group of data records in a stream. <br/>
                            can support 5 transations per second for read, upto a max total read 2mb/s, <br/>
                            1000 records per second write, max total rate 1mb/s<br/>

                            number_of_shards = max(incoming_write_bandwidth_in_KB/1000, outgoing_read_bandwidth_in_KB/2000)<br/><br/>
                                
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>    
                    <div className="pad-top-10"></div>
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                Elastic Transcoders & Kinesis
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           
                            <AccordionItemPanel>
                                    media transcoder in teh cloud. 
                                converts media files from their original source to different formats for phones, tablets etc., 
                                pay as you go
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>  
                      
                </div>              
            </div>
           
          </div>
      </div>
    );
  }
}
 
export default KinesisTranscoders;