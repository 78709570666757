import React, { Component } from "react";

import TypoGraphy from '@material-ui/core/Typography'
import { Grid} from "@material-ui/core";
import MLBase from "./MLBase";

class MLOverview extends Component {


      
  render() {
    return (
        <div>
        <div style={{ marginTop: 20, padding: 30 }}>
            <Grid container spacing={40} justify="center">
                <TypoGraphy variant="h4">
                    <center>AWS Certified Machine Learning – Specialty</center>
                </TypoGraphy>
            </Grid>
            <div className="pad-left-page">
                <MLBase></MLBase>
            </div>
        
        </div>
      </div>
    );
  }
}
 
export default MLOverview;