//loader.js
window.$ = window.jQuery = require('jquery')

window.S4 = function S4() {
    return (((1+Math.random())*0x10000)|0).toString(16).substring(1); 
}
 
window.getGuid = function(){
    return (window.S4() + window.S4() + "-" + window.S4() + "-4" + window.S4().substr(0,3) + "-" + window.S4() + "-" + window.S4() + window.S4() + window.S4()).toLowerCase();
}
window.guid = (window.S4() + window.S4() + "-" + window.S4() + "-4" + window.S4().substr(0,3) + "-" + window.S4() + "-" + window.S4() + window.S4() + window.S4()).toLowerCase();

window.clickLog = function(action, component){
    var callerid=window.appConstants.AppName; 
    var url=window.appConstants.clicklogUrl; 
    var contextid = window.appConstants.Allow + "-" + window.guid;
    var requestid = window.getGuid();
    var type = "webpage"
    var hval = "ALLOW=" + window.appConstants.Allow;
      
    var request= {
        "requestid": requestid,
        "contextid": contextid,
        "callerid": callerid,
        "component": component,
        "action": action,
        "type": type
    }
    
    if(window.appConstants.enableClickLogs){
        window.clickLogPost(url, hval, request);
    }
    
  }

window.clickLogPost = function(url, hval, request){
    window.$.ajax({
        type: "POST",
        //url: "https://wbk8ptlj12.execute-api.us-east-1.amazonaws.com/dev/clicklogger",
        url: url,
        crossDomain: true,
        data: JSON.stringify(request),
        contentType: "application/json",
        header: {
            'Authorization': hval
        },
        dataType: "json",
        success: function(data, status){
           //console.log("Data: " + data.statusCode);
        }
   });
    // window.$.ajax({
    //     url: url,
    //     type: 'POST',
    //     data: request,
    //     crossDomain: true,
    //     headers: {
    //         Authorization: hval,  
    //     },
    //     dataType: 'json',
    //     success: function (data) {
    //         console.info(data);
    //     },
    //     error: function(xhr,textStatus,errorThrown){
    //         console.log(textStatus + " error thrown - " + errorThrown);
    //     }
    //     ,
    //     beforeSend: function(xhr) {
    //        xhr.setRequestHeader("Authorization", hval); 
    //     }
    // });
}