import React, { Component } from "react";
import {
    //Route,
    NavLink,
    HashRouter
  } from "react-router-dom";
import { slide as Menu } from 'react-burger-menu'
import superagent from "superagent";
import Home from "./Home";
import Microsoft from "./codeandtech/Microsoft";
import Opensource from "./codeandtech/Opensource";
import AWSDedication from "./codeandtech/AWSDedication";
import LearnToCode from "./codeandtech/LearnToCode";
import GIT from "./codeandtech/GIT";

import Overview from "./cloudopsguru/Overview";
import CoreServices from "./cloudopsguru/CoreServices";
import Storage from "./cloudopsguru/Storage";
import Network from "./cloudopsguru/Network";
import Messaging from "./cloudopsguru/Messaging";
import DeployOrchestration from "./cloudopsguru/DeployOrchestration";
import MLOverview from "./cloudopsguru/MLOverview";
import AzureOverview from "./cloudopsguru/AzureOverview";
import Others from "./cloudopsguru/Others";
import DataAnalytics from "./cloudopsguru/DataAnalytics";

import "../js/loader.js";
import "../js/readmeConstant.js";
import "../js/appConstants.js";
// import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
export default class Main extends Component  {

  constructor(props){
    super(props)
    this.state = {
        githubreadmes: window.readmeConstant,
        appConstants: window.appConstants,
        appPrefix: window.appConstants.Allow,
        contextid: window.appConstants.Allow + "-" + window.guid,
        blogUrl: window.appConstants.blogUrl
    }
    //console.log("call clicklog")
    window.clickLog("load", "homepage")    
  }  

  openUrl(value){
    window.clickLog("load", "blog")  
    if(value ==='blogurl'){
        value = this.state.blogUrl
    }  
    window.open(value, false);
  }

  componentDidMount(){
      this.getCodeAndTechReadmes();
  }

  getCodeAndTechReadmes(){
      const url=this.state.appConstants.CodeAndTechReadmesUrl;
      superagent
        .get(url)
        .query(null)
        .set('Accept', 'application/json')
        .end((error, response) => {
            if(response && response.text){
                try{
                    var data = JSON.parse(response.text);

                    if(data && data.tabs){
                        this.setState({
                            githubreadmes : data
                        })
                    }

                }
                catch(Exception){
                    this.setState({
                        githubreadmes:  window.readmeConstant
                    })
                }
            }
        })
  }


  render() {
      
    var appConstants = this.state.appConstants;

    if(appConstants && appConstants.AppName === "CodeAndTech"){
        return (
            <HashRouter>
                <div>
                    <div>
                        <Menu >                       
                            <li><NavLink  className="menu-item--small" exact to="/">Home &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</NavLink></li>
                            <li><NavLink  className="menu-item--small" to="/Microsoft">Microsoft</NavLink></li>
                            <li><NavLink  className="menu-item--small" to="/Opensource">Opensource</NavLink></li>
                            <li><NavLink  className="menu-item--small" to="/AWSDedication">CloudOpsGuru</NavLink></li>
                            <li><NavLink  className="menu-item--small" to="/LearnToCode">LearnToCode</NavLink></li>
                            <li><NavLink  className="menu-item--small" to="/GIT">GIT IT</NavLink></li>
                            <li>&nbsp;&nbsp;&nbsp;&nbsp;<span className="menu-item--small pad-right-10 blogMenuText" onClick={() => this.openUrl('blogurl')}>Blog!</span></li>
                        </Menu>
                    </div>
                    <div className="header">
                        <ul className="pad-left-60">
                            <li><NavLink exact to="/">Home</NavLink></li>
                            <li><NavLink to="/Microsoft">Microsoft</NavLink></li>
                            <li><NavLink to="/Opensource">Opensource</NavLink></li>
                            <li><NavLink to="/AWSDedication">CloudOpsGuru</NavLink></li>
                            <li><NavLink to="/LearnToCode">LearnToCode</NavLink></li>
                            <li><NavLink to="/GIT">GIT IT&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</NavLink></li> 
                            
                            <span className="right pad-right-10 blogText" onClick={() => this.openUrl('blogurl')}>Blog!</span>
                        </ul>
                        
                    </div>
                   
                    <div className="content ">
                        
                        <Route exact path="/" component={Home}/>
                        <Route path="/Microsoft" component={Microsoft}/>
                        <Route path="/Opensource" component={Opensource}/>
                        <Route path="/AWSDedication" component={AWSDedication}/>
                        <Route path="/LearnToCode" render={(props) => (
                            <LearnToCode {...props} GithubReadmes={this.state.githubreadmes} />
                        )} />
                        <Route path="/GIT" component={GIT}/> 
                    </div>
                </div>
          </HashRouter>
        );
    }
    else{
        return (
            <HashRouter>
                <div>
                    <div>
                        <Menu >                       
                            <li><NavLink  className="menu-item--small" exact to="/">Home &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</NavLink></li>
                            <li><NavLink  className="menu-item--small" to="/Overview">Overview</NavLink></li>
                            <li><NavLink  className="menu-item--small" to="/CoreServices">CoreServices</NavLink></li>
                            <li><NavLink  className="menu-item--small" to="/Storage">Storage</NavLink></li>
                            <li><NavLink  className="menu-item--small" to="/DataAnalytics">BigData</NavLink></li>
                            <li><NavLink  className="menu-item--small" to="/Network">Network</NavLink></li>
                            <li><NavLink  className="menu-item--small" to="/Messaging">Messaging</NavLink></li>
                            <li><NavLink  className="menu-item--small" to="/DeployOrchestration">Deploy</NavLink></li>
                            <li><NavLink  className="menu-item--small" to="/MLOverview">MLOverview</NavLink></li>
                            <li><NavLink  className="menu-item--small" to="/Others">Others &nbsp;&nbsp;</NavLink></li>
                            <li><NavLink  className="menu-item--small" to="/AzureOverview">AzureOverview</NavLink></li>
                            <li>&nbsp;&nbsp;&nbsp;&nbsp;<span className="menu-item--small pad-right-10 blogMenuText" onClick={() => this.openUrl('blogurl')}>Blog!</span></li>
                        </Menu>
                    </div>
                    <div className="header">
                        <ul className="pad-left-60">
                            <li><NavLink exact to="/">Home</NavLink></li>
                            <li><NavLink to="/Overview">Overview</NavLink></li>
                            <li><NavLink to="/CoreServices">CoreServices</NavLink></li>
                            <li><NavLink to="/Storage">Storage</NavLink></li>
                            <li><NavLink to="/DataAnalytics">BigData</NavLink></li>
                            <li><NavLink to="/Network">Network</NavLink></li>
                            <li><NavLink to="/Messaging">Messaging</NavLink></li> 
                            <li><NavLink to="/DeployOrchestration">Deploy</NavLink></li> 
                            <li><NavLink to="/MLOverview">MLOverview</NavLink></li> 
                            <li><NavLink to="/Others">Others</NavLink></li> 
                            <li><NavLink to="/AzureOverview">AzureOverview</NavLink></li> 
                            <span className="right pad-right-10 blogText" onClick={() => this.openUrl('blogurl')}>Blog!</span>
                        </ul>
                        
                    </div>
                   
                    <div className="content ">
                        
                        <Route exact path="/" component={Home}/>
                        <Route path="/Overview" component={Overview}/>
                        <Route path="/CoreServices" component={CoreServices}/>
                        <Route path="/Storage" component={Storage}/>
                        <Route path="/DataAnalytics" component={DataAnalytics}/>
                        <Route path="/Network" component={Network}/>
                        <Route path="/Messaging" component={Messaging}/>
                        <Route path="/DeployOrchestration" component={DeployOrchestration}/>
                        <Route path="/MLOverview" component={MLOverview}/>
                        <Route path="/Others" component={Others}/> 
                        <Route path="/AzureOverview" component={AzureOverview}/>
                    </div>
                </div>
          </HashRouter>
        );
    }

    
  }
}
 