import React, { Component } from "react";
import IAMAndSecurity from "./IAMandSecurity";
import EC2 from "./EC2";
import EBSAndStorageGateway from "./EBSAndStorageGateway";
import CloudWatch from "./CloudWatch";
import TypoGraphy from '@material-ui/core/Typography'
import { Grid, Paper, Typography, List, ListItem } from "@material-ui/core";


class CoreServices extends Component {

  constructor(props){
    super(props)
       window.clickLog("load", "CoreServices")    
  } 

    openUrl(value){
        window.open(value, false);
      }
      
      
  render() {
    return (
      <div>
        <div style={{ marginTop: 20, padding: 30 }}>
            <Grid container spacing={40} justify="center">
                <TypoGraphy variant="h4">
                    <center>Core Services</center>
                </TypoGraphy>
            </Grid>
            <div className="pad-left-page">
                <IAMAndSecurity></IAMAndSecurity>
                <div className="pad-top-10"></div>
                <EC2></EC2>
                <div className="pad-top-10"></div>
                <EBSAndStorageGateway></EBSAndStorageGateway>
                <div className="pad-top-10"></div>
                <CloudWatch></CloudWatch>
              
            </div>
        </div>
      </div>
    );
  }
}
 
export default CoreServices;