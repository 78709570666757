import React, { Component } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

class IAMAndSecurity extends Component {

    openUrl(value){
        window.open(value, false);
      }
      
      
  render() {
    return (
      <div className="pad-top-30 pad-left-page">
          <div class="row">
            <div class="col-sm-7 col-md-5">
                  <div class="">
                    <h2>IAM and Security</h2>   <br/>                 
                   
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                IAM and Security
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           
                            <AccordionItemPanel>
                            Roles

                            -     All the roles are global. you cannot create for specific region <br/>
                            - 	AWS Service Role - amazon ec2 or directory service or lambda, redshift etc.,<br/>
                            - 	AWS service linked role - for voice bots and channels<br/>
                            - 	Role for cross account - access other aws accounts and 3party aws account etc.,<br/>
                            - 	Role for identiy provider access - access facebook, google, sso or saml<br/>
                            &nbsp;&nbsp;<br/>



                            Security group is a firewall <br/><br/>

                            - All inbound traffic is blocked by default.   <br/>
                            - All outboud traffic is allowed by default<br/>
                            - Changes to securoty group takes effect immediately<br/>
                            - you can have any number of Ec2 instances within a security group<br/>
                            - you can have multiple security groups attached to ec2 instances<br/>
                            - Security groups are stateful. Any inbound rule will have default reply. That traffic is automatically allowed back again<br/>
                            - You can allow rules but Cannot deny rules i.e a port on inbound rules<br/>
                            - you cannot block specific ip addresses using security groups instead use network access control lists
                                
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>    
                    <div className="pad-top-10"></div>
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                Using Web Identity federation
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           
                            <AccordionItemPanel>
                            - 1) Authenticate with identity provider like google<br/>
                            - 2) provider returns a web identity token<br/>
                            - 3) ap call "AssumeRoleWithIdentity" STS API<br/>
                            - 4) STS returns credentials<br/>
                            - 15 mins to 60 mins. By default for 60 mins<br/>
                                
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>  
                      
                </div>              
            </div>
           
          </div>
      </div>
    );
  }
}
 
export default IAMAndSecurity;