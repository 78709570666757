import React, { Component } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

class SQS extends Component {

    openUrl(value){
        window.open(value, false);
      }
      
      
  render() {
    return (
      <div className="pad-top-30 pad-left-page">
          <div class="row">
            <div class="col-sm-7 col-md-5">
                  <div class="">
                    <h2> SQS - Simple Queing Service</h2>                    
                   

                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                SQS - Simple Queing Service
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           
                            <AccordionItemPanel>
                                                
                                This is one of the oldest AWS service
                                <br/>
                                SQS IS A PULL BASED SYSTEM. SNS IS A PUSH BASED SYSTEM
                                <br/>
                                simple queing serice - distributed service. queue is a temporary  messaging
                                SQS is a pull based system
                                <br/>
                                SNS - simple notification service is a push based service
                                messages can be upto 256kb iof text in any format
                                messages kept from 1 min to 14 days. default is 4 days
                                <br/>
                                types of queues:
                                standard queues - default - message could be processed at leaast once. tries to be first in first out
                                <br/>
                                High Throughput: Standard queues have nearly-unlimited transactions per second (TPS).	
                                At-Least-Once Delivery: A message is delivered at least once, but occasionally more than one copy or a message is delivered.	
                                Best-Effort Ordering: Occasionally, messages are delivered in an order different from which they were sent.
                                <br/>
                                Fifo queues - makes sure fifo. max 300 transaction per second. message will be deliverd once
                                First-ln-First-out Delivery: The order in which messages are sent and received is strictly preserved.
                                Exactly-Once Processing: A message is guaranteed to be delivered at least once, but all duplicates of the message are removed.
                                Limited Throughput: 300 transactions per second (TPS).
                                <br/>
                                visibility time out is the amout of time that eh message is invisible in the sqs after a reader picks up the message
                                visibility time out max is 12 hours
                                <br/>
                                long polling - polls once and waits for time out instead of polling repeatedly
                                short polling - polls and returns immediately. again polls again. this could increase the cost
                                <br/>
                                Simple Queue Service
                                using pull mechanism
                                <br/>
                                A dead-letter queue is a queue that other (source) queues can target for messages that can't be processed (consumed) successfully. ex: due to error
                                <br/>

                                <h3>
                                    Developers Guide - API
                                </h3>
                                <br/>
                                    APIs 
                                    <br/>
                                    <a href="#SQS" onclick="window.open('http://docs.aws.amazon.com/AWSSimpleQueueService/latest/APIReference/API_Operations.html');">Amazon API Reference:</a>
                                    

                                    <li>AddPermission - Adds a permission to a queue for a specific principal. This allows sharing access to the queue.
                                    </li>
                                    <li>ChangeMessageVisibility  - Changes the visibility timeout of a specified message in a queue to a new value
                                        12 hours (43200) - max allowed time
                                        in-flight queues - messages picked but not deleted. 120000 max for standard queues, 20000 for fifo queues
                                        OverLimit error - if we dont delete msgs after they are processed and if we go beyond the limit. system will throw 
                                        
                                        Request Parameters - QueueUrl, ReceiptHandle, VisibilityTimeout
                                    </li>
                                    <li>ChangeMessageVisibilityBatch - change visibilty for multiple messages</li>
                                    <li>CreateQueue - Creates a new standard or FIFO queue	(by default standard queue)
                                        QueueName  - mandatory
                                        
                                        Request Parameters - DelaySeconds (0 to 15 mins), MaximumMessageSize - (1KB to 256KB), 
                                                            MessageRetentionPeriod - 1 Min to 14 days (default is 4 days), VisibilityTimeout - 0 to 12 hours,
                                                            RedrivePolicy - The string that includes the parameters for the dead-letter queue functionality of the source 
                                    </li>
                                    <li>DeleteMessage - Deletes the specified message from the specified queue
                                        Request Parameters - QueueUrl, ReceiptHandle
                                    </li>
                                    <li>DeleteMessageBatch - Deletes up to ten messages from the specified queue
                                    </li>
                                    <li>DeleteQueue - Deletes the queue specified by the QueueUrl, regardless of the queue's contents
                                    </li>
                                    <li>GetQueueAttributes - Gets attributes for the specified queue.
                                        response includes - ReceiveMessageWaitTimeSeconds,  VisibilityTimeout, ApproximateNumberOfMessages, 
                                                            ApproximateNumberOfMessagesNotVisible, QueueArn, MaximumMessageSize, 
                                                            MessageRetentionPeriod, CreatedTimestamp, LastModifiedTimestamp
                                    </li>					
                                    <li>GetQueueUrl - Returns the URL of an existing queue						
                                        QueueOwnerAWSAccountId  - parameter to specify the account ID of the queue's owner
                                    </li>
                                    <li>ListDeadLetterSourceQueues - Returns a list of your queues that have the RedrivePolicy queue attribute configured with a dead-letter queue.
                                    </li>
                                    <li>ListQueues - Returns a list of your queues (max 1000 queues)
                                        QueueNamePrefix - pulls only specific queues that begin with that name
                                    </li>
                                    <li>PurgeQueue - Deletes the messages in a queue specified by the QueueURL parameter (when purged you cannot retrieve the message deleted in that queue)
                                    </li>
                                    <li>ReceiveMessage - Retrieves one or more messages (up to 10), from the specified queue
                                        WaitTimeSeconds - Enables long polling poll support
                                    </li>
                                    <li>RemovePermission - Revokes any permissions in the queue policy that matches the specified Label parameter. Only the owner of the queue can remove permissions</li>

                                    <li>SendMessage - Delivers a message to the specified queue.
                                        A message can include only XML, JSON, and unformatted text. The following Unicode characters are allowed:
                                                #x9 | #xA | #xD | #x20 to #xD7FF | #xE000 to #xFFFD | #x10000 to #x10FFFF	


                                        Request Parameters - DelaySeconds (0 to 15 mins), MessageAttribute, MessageBody - max 256 kb, MessageDeduplicationId - only for fifo, MessageGroupId - only for fifo, queueUrl</li>
                                        

                                    <li>SendMessageBatch - Delivers up to ten messages to the specified queue. This is a batch version of SendMessage.	</li>

                                    <li>SetQueueAttributes - Sets the value of one or more queue attributes</li>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>    
                   
                      
                </div>              
            </div>
           
          </div>
      </div>
    );
  }
}
 
export default SQS;