import React, { Component } from "react";
import VPC from "./VPC";
import DNSRoute53 from "./DNSRoute53";
import VPN from "./VPN";
import DirectConnect from "./DirectConnect";
import VPCNetworkSpecialty from "./VPCNetworkSpecialty";
import ElasticLoadBalacers from "./ElasticLoadBalacers";
import TypoGraphy from '@material-ui/core/Typography'
import { Grid, Paper, Typography, List, ListItem } from "@material-ui/core";



class Storage extends Component {

  constructor(props){
    super(props)
       window.clickLog("load", "Network")    
  } 

    openUrl(value){
        window.open(value, false);
      }
      
      
  render() {
    return (
      <div>
        <div style={{ marginTop: 20, padding: 30 }}>
            <Grid container spacing={40} justify="center">
                <TypoGraphy variant="h4">
                    <center>Network</center>
                </TypoGraphy>
            </Grid>
            <div className="pad-left-page">
                <VPC></VPC>
                <div className="pad-top-10"></div>
                <VPN></VPN>
                <div className="pad-top-10"></div>
                <DirectConnect></DirectConnect>
                <div className="pad-top-10"></div>
                <DNSRoute53></DNSRoute53>
                <div className="pad-top-10"></div>
                <VPCNetworkSpecialty></VPCNetworkSpecialty>
                <div className="pad-top-10"></div>
                <ElasticLoadBalacers></ElasticLoadBalacers>

              
            </div>
        </div>
      </div>
    );
  }
}
 
export default Storage;