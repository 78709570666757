import React, { Component } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

class EC2 extends Component {

    openUrl(value){
        window.open(value, false);
      }
      
      
  render() {
    return (
      <div className="pad-top-30 pad-left-page">
          <div class="row">
            <div class="col-sm-7 col-md-5">
                  <div class="">
                    <h2>Elastic Compute Cloud</h2>                    
                   

                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                Elastic Compute Cloud
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           
                            <AccordionItemPanel>
                            <h6>
                                    EC2 is a web service that provides resizable compute capacity in the cloud . 
                                    Ec2 reduces the time required to obtain and boot new server instances to minutes allowing to quickly scale capacity both up and down, 
                                    as your computiung requirements change

                                    Provides developers with failure resilient tools
                            </h6>
                            
                            EC2 Options
                            - 
                                On demand = pay a fixed rate by hour. short term, spiky or unpredictable workloads that  cannot be interrupted
                            <br/>
                            - 
                                Reserved - provide a capacity reservation. Ex: 1 or 3 year terms. Get a significant discount. 
                                apps with steady state or predictabe usage. 
                                apps that require reserved capacity ,
                                users able to make upfront payments, apps with steady state or predictable usage
                            <br/>
                            - 
                                Spot - enable you to bid whatever price you want for instance capacity.  especially with flexible start and end times for instance.
                                apps that have flexible start and end times, 
                                apps that are only feasible at very compute prices, ex: pharmaceutical companies, 
                                users with urgent computing needs for large amounts of additional capacity - ex: brexit analysis
                            <br/>
                            - 
                                Dedicated Hosts - physical ec2 server dedicated for your use. 
                                useful for regulatory requirements that may not supported multi tenant virtualization
                                    great for licensing which does not support multi-tenancy or cloud deployments, can be purchased on -demand (hourly), can be purchased as a reservation
                            <br/>
                            <br/>
                            <br/>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>    
                    <div className="pad-top-10"></div>
                    <Accordion>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                header
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            General purpose, compute optimized, memory optimized, GPU, storage optiized
                            <br/>        
                            Remember - DRMCGIFTPX<br/><br/>
                            <h3>
                                Instances types
                            </h3>
                            <br/>
                                - 
                                    spot instances - unused capacity, bid, no upfront commitment
                                <br/>
                                - 
                                    reserved instances - commitment for 1 or 3 years. with no, partial upfront or full payment
                                <br/>
                                - 
                                on demand instances - no commitment 
                                
                            <br/>
                        </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>    
                    <div className="pad-top-10"></div>
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                EC2 placement group
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           
                            <AccordionItemPanel>
                                    is a logical grouping of instances within a single availability zone. using placement groups enables apps to 
                        participate in a low latency, 10 GBPS network. placement groups are recommended for apps that benefit from low latency, high network throughput or both
                        <br/>
                        Ex: cassandra or hadoop.. Where high network and need low latency between
                        <br/>
                        has to be in same availability zone. placement group cannot span multiple availability zones. 
                        the name you specify has to be unique within your aws account
                        only certain types of instances can be launched in a placement group - compute optimized, GPU, memory optimized, storage optimized
                        aws recommend homogenous instances within placement groups - instances of same size or same family.. 
                        cannot merge placement groups
                        cannot move an existing instance into a placement group
                        <br/>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>  
                    <div className="pad-top-10"></div>
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                Developer Notes:
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           
                            <AccordionItemPanel>
                            API Calls: <br/>
                            <button  className="linkbutton" onClick={() => this.openUrl('http://docs.aws.amazon.com/AWSEC2/latest/APIReference/API_Operations.html')}>amazon reference</button>
                            - AllocateAddress - allocates an elastic IP address (by default upto 5 elastic IP adress per region) <br/>
                            - AssciateAddress - associates an elastic IP address with an instance or a network interface <br/>
                            - AssciateRouterTable - associates a subnet with a route table. subnet and ruote table must be in same VPC <br/>
                            - AttachVolume - Attaches an EBS volume to a running or stopped instance <br/>
                            - CopyImge - Initiates the copy of an AMI from specifid source region to current region <br/>
                            - CopySnapshot - copies point in time snapshot of an ebs volume and stores it in s3. both within a region or across. <br/>
                            - CreateImage - creates an ebs backed ami from an ebs backed instance that is either running or stopped <br/>
                            - CreateRoute,CreateRouteTable,CreateSecurityGroup,CreateSnapshot,CreateVolume <br/>

                            - RunInstances - launches specifid number of instances. ex: to run 20 on-demand instances <br/>
                            - RunScheduledInstances - launches the specified scheduled instances <br/>
                            - DescribeInstances - to check the status on the instances <br/>
                            - TerminateInstances - to terminate any number of instances <br/>
                            - StopInstances - To stop and release the compute resoureces  <br/>
                            - StartInstances - start a specific instance that was previously stopped <br/>
                            - DescribeImages, DescribeInstances, DescribeKeyPairs, DescribeRegions, DescribeSnapshts, DescribeVolumes <br/>
                            - DescribeReservedInstancesListings <br/>
                            - DescribeSpotInstanceRequests / ec2-describe-spot-instance-requests (CLI Command) <br/>
                            <br/><br/>
                            
                            Other APIs
                            - AllocateHosts, AttachNetworkInterface, AttachInternetGateway, CreateCustomGateway, CreateDefaultVPC, CreateFlowLogs, CreateKeyPair <br/>
                                
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>  
                      
                </div>              
            </div>
           
          </div>
      </div>
    );
  }
}
 
export default EC2;