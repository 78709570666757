import React, { Component } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

class CloudFront extends Component {

    openUrl(value){
        window.open(value, false);
      }
      
      
  render() {
    return (
      <div className="pad-top-30 pad-left-page">
          <div class="row">
            <div class="col-sm-7 col-md-5">
                  <div class="">
                    <h2>CloudFront</h2>                    

                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                CloudFront
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           
                            <AccordionItemPanel>
                            Edge location - content delivery network end points for cloudFront. Way to cache very large media objects in cloud.  
                                Currently there are 66 edge locations. 
                                edge location - this is the location where content will be cached. this is separate to an AWS region/AZ
                                <br/>
                                
                                Distribution = This is the name given the CDN which consists of a collection of edge locations
                                <br/>
                                
                                Origin - this is the origin of all ther files that the CDN will districbute. this can be either an s3 bucket, an EC2 instance, an elastic load balance or Route53
                                <br/>
                                
                                Invalidating objects removes them from cloudfront edge caches. ex: time to leave is 24 hours. 
                                but if you want to clear because there is a sensitive information we can invalidate. but it costs money

                                not for jst downloading.. for uploading files
                                deploy in cloudfront and use it for edgelocations across the globe
                                <br/>
                                
                                AWS WAF - web application firewall web ACL - prevents sql injection etc.,
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>    
                    <div className="pad-top-10"></div>
                    
                </div>              
            </div>
           
          </div>
      </div>
    );
  }
}
 
export default CloudFront;