import React, { Component } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

class GeneralTips extends Component {

    openUrl(value){
        window.open(value, false);
      }
      
      
  render() {
    return (
      <div className="pad-top-30 pad-left-page">
          <div class="row">
            <div class="col-sm-7 col-md-5">
                  <div class="">
                    <h2>General tips</h2>                    
                    
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                General Connectivity
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           
                            <AccordionItemPanel>
                            <b>ec2 connection to ssh</b> <br/>
                            host key not found <br/>
                            - incorrect user name<br/><br/>

                            Unprotected Private Key File error <br/>
                            - The private key file has the wrong file permission<br/><br/>

                            Server refused our key <br/>
                            - verify .pem is converted to .ppk<br/>
                            - incorrect user name<br/><br/>

                            No Supported authentication methods available<br/>
                            - verify .pem is converted to .ppk<br/>
                            - incorrect user name<br/>
                                
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>    
                    <div className="pad-top-10"></div>
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                User Data - sample bootstrapping in linux
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           
                            <AccordionItemPanel>
                                
                                #!/bin/bash<br/>
                                yum install httpd php php-mysql -y<br/>
                                yum update -y<br/>
                                chkconfig httpd on<br/>
                                service httpd start<br/>
                                cd /var/www/html<br/>
                                echo "This is myserver" > /var/www/html/index.html<br/>
                                echo "php phpinfo();?>"  > /var/www/html/index.php<br/>
                                wget https://s3.eu-west-2.amazonaws.com/acloudguru-example/connect.php<br/>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>  
                      
                </div>              
            </div>
           
          </div>
      </div>
    );
  }
}
 
export default GeneralTips;