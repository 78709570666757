import React, { Component } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

class ElasticBeanStalk extends Component {

    openUrl(value){
        window.open(value, false);
      }
      
      
  render() {
    return (
      <div className="pad-top-30 pad-left-page">
          <div class="row">
            <div class="col-sm-7 col-md-5">
                  <div class="">
                    <h2>Elastic Beanstalk</h2>    <br/>                
                    

                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                Elastic Beanstalk
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           
                            <AccordionItemPanel>
                            AWS Elastic Beanstalk uses proven AWS features and services, such as 
                            <br/>
                            
                                - Amazon EC2, <br/>
                                - Amazon RDS,  <br/>
                                - Elastic Load Balancing, <br/> 
                                - Auto Scaling,  <br/>
                                - Amazon S3, and  <br/>
                                - Amazon SNS,  <br/>
                                
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>    
                    <div className="pad-top-10"></div>
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                Platform support
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           
                            <AccordionItemPanel>
                            to create an environment that runs your application. <br/>
                            The current version of AWS Elastic Beanstalk uses the Amazon Linux AMI or the Windows Server 2012 R2 AMI.
                            <br/>
                            Platforms supported by Elastic Beanstalk
                            <br/>
                            - Single Container Docker <br/>
                            - Multicontainer Docker <br/>
                            - Preconfigured Docker <br/>
                            - Go <br/>
                            - Java SE <br/>
                            - Java with Tomcat <br/>
                            - .NET on Windows Server with IIS <br/>
                            - Node.js <br/>
                            - PHP <br/>
                            - Python <br/>
                            - Ruby <br/>
                                
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>  
                    <div className="pad-top-10"></div>
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                features
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           
                            <AccordionItemPanel>
                            handles capacity provisiong, load balancing , autoscaling, app helath monitoring <br/>
                            we will be able to control the underlying resources like EC2, auto scaling, load balancing, S3 <br/>
                            monitor using cloud watch <br/>
                            helps to quickly deploy and manage apps<br/>
                            provides platforms for <br/>
                            -  programming languages<br/>
                            -  webservers and containers<br/>
                            
                            <br/><br/>
                            - supports custom platforms<br/>
                            - can use Git, upload deployable code<br/>
                            - has integration to visual studio or eclipse<br/>
                            - free to use but have to pay for the resources<br/>
                            - you can control access/limit permission to read only access  using IAM<br/>
                            
                                
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>  
                    <div className="pad-top-10"></div>
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                Developer Notes
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           
                            <AccordionItemPanel>
                            <b>Think</b> application, platform, environment/configuration template <br/><br/>

                            - Environment related -<br/>
                                -  AbortEnvironmentUpdate, ApplyEnvironmentManagedAction, ComposeEnvironments, CreateEnvironment , <br/>
                                - DescribeEnvironmentHealth, DescribeEnvironmentManagedActionHistory, <br/>
                                - DescribeEnvironmentManagedActions, DescribeEnvironmentResources, DescribeEnvironments,DeleteEnvironmentConfiguration, <br/>
                                - RebuildEnvironment, RequestEnvironmentInfo, RetrieveEnvironmentInfo, TerminateEnvironment, UpdateEnvironment
                                                <br/><br/>
                            - Application related - CreateApplication, CreateApplicationVersion, DeleteApplication, DeleteApplicationVersion,<br/>
                                - DescribeApplications, DescribeApplicationVersions, UpdateApplication, <br/>
                                - UpdateApplicationResourceLifecycle, UpdateApplicationVersion<br/>
                                                <br/><br/>
                            - Configuration/template related - CreateConfigurationTemplate, DescribeConfigurationOptions, <br/>
                                - DescribeConfigurationSettings, UpdateConfigurationTemplate, <br/>
                                - ValidateConfigurationSettings <br/>
                                                <br/><br/>
                            - Platform related - CreatePlatformVersion, DeletePlatformVersion, DescribePlatformVersion, ListPlatformVersions
                                <br/>	<br/>
                            - Others - 	CheckDNSAvailability, CreateStorageLocation, DescribeEvents, <br/>
                                - DescribeInstancesHealth, ListAvailableSolutionStacks, RestartAppServer, SwapEnvironmentCNAMEs
                            <br/>                                
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>  
                      
                </div>              
            </div>
           
          </div>
      </div>
    );
  }
}
 
export default ElasticBeanStalk;