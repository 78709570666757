import React, { Component } from "react";
import SNS from "./SNS";
import SQS from "./SQS";
import SWF from "./SWF";
import TypoGraphy from '@material-ui/core/Typography'
import { Grid, Paper, Typography, List, ListItem } from "@material-ui/core";



class Messaging extends Component {
  constructor(props){
    super(props)
       window.clickLog("load", "Messaging")    
  } 

    openUrl(value){
        window.open(value, false);
      }
      
      
  render() {
    return (
      <div>
      <div style={{ marginTop: 20, padding: 30 }}>
          <Grid container spacing={40} justify="center">
              <TypoGraphy variant="h4">
                  <center>Messaging</center>
              </TypoGraphy>
          </Grid>
          <div className="pad-left-page">
              <SNS></SNS>
              <div className="pad-top-10"></div>
              <SQS></SQS>
              <div className="pad-top-10"></div>
              <SWF></SWF>
              
            
          </div>
        </div>
      </div>
    );
  }
}
 
export default Messaging;