window.readmeConstant = {
                "Dotnet":[
                {
                    "name": "ASP.NET QnAs",
                    "key": "ASPNETQnAs",
                    "link": "https://raw.githubusercontent.com/shivaramani/.net/master/ASP.NET/ASP.NET%20QnAs.md"
                },
                {
                    "name":"C# New Features",
                    "key":"C#NewFeatures",
                    "link":"https://raw.githubusercontent.com/shivaramani/.net/master/C%23%20new%20features.md"
                },
                {
                    "name":"WCF QnAs",
                    "key":"WCFQnAs",
                    "link":"https://raw.githubusercontent.com/shivaramani/.net/master/WCF/WCF%20QnAs.md"
                },
                {
                    "name":"WebApi QnAs",
                    "key":"WebApiQnAs",
                    "link":"https://raw.githubusercontent.com/shivaramani/.net/master/WebAPI/WebAPI%20QnAs.md"
                }
        
                ],
                "Java":[
                {
                    "name": "Springboot Kubernetes ",
                    "key":"SpringbootKubernetes",
                    "link": "https://raw.githubusercontent.com/shivaramani/java/master/codeandtechsvc/README.md"
                }
                ],
                "OpenSource":[
                {
                "name":"ReactJS SimpleApp",
                "key":"ReactJSSimpleApp",
                "link":"https://raw.githubusercontent.com/shivaramani/opensource/master/ReactJS/simplereactjs/Concepts.md"
                },
                {
                "name":"TypeScript QnAs",
                "key":"TypeScriptQnAs",
                "link":"https://raw.githubusercontent.com/shivaramani/opensource/master/TypeScript/README.md"
                }
                ]
            
            };