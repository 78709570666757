import React, { Component } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

class VPCNetworkSpecialty extends Component {

    openUrl(value){
        window.open(value, false);
      }
      
      
  render() {
    return (
      <div className="pad-top-30 pad-left-page">
          <div class="row">
            <div class="col-sm-7 col-md-5">
                  <div class="">
                    <h2>Networking Specialty</h2>                    
                    
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    VPC Configuration
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           
                            <AccordionItemPanel>
                            
                            <button  className="linkbutton" onClick={() => this.openUrl('https://docs.aws.amazon.com/vpc/latest/userguide/VPC_wizard.html')}>Amazon VPC console wizard configurations</button>                            
  
                            - Public subnet - subnet with route table that has a route to internet gateway <br/>
                            - Optionally configure for IPV6 (associated IpV6 CIDR blocks), instances launched in public subnet with IpV6 addresses  <br/>
                            - Security: If you associate an IPv6 CIDR block with your VPC and subnets, you must add separate rules SG<br/>
                            - Ex: WebServerSG and DBServerSG security groups to control inbound and outbound IPv6 traffic for your instances<br/>
                            - Routing: Can have "main" and "custom" route tables.<br/>
                            - Ideally have main route table with <br/>
                                - 10.0.0.0/16 - local (target) <br/>
                                - 0.0.0.0/0   - igw-id   (ideally if we have only one public subnet)<br/>
                                - 0.0.0.0/0   - nat-gateway-id<br/>
                                - ::/0        - egress-only-igw-id<br/>
                                - 0.0.0.0/0   - vgw-id<br/>
                            - For basic/default, security groups can provide access for the instances' connectivity<br/>
                            - Utilize NACL to fine grain allow/deny access between public/private subnets	<br/>
                            
                            <br/>
                            VPC PEERING:<br/>
                                - connect two VPCs to route traffic between them using private IPv4 or IPv6 addresses. <br/>
                                - Instances in either VPC can communicate with each other as if they are within the same network. <br/>
                                - can peer between own VPCs, or with a VPC in another AWS account.  <br/>
                                - uses existing infrastructure; neither a gateway nor a VPN connection, and does not rely on a separate piece of physical hardware.<br/>
                                - NO single point of failure for communication or a bandwidth bottleneck.<br/>
                                - Inter region peering is possible. refer limiatations https://docs.aws.amazon.com/vpc/latest/peering/vpc-peering-basics.html<br/>
                                - NOTE: When you use Virtual Interface, we can connect only to ONE VPC. if you want to connect multiple VPCs,<br/>
                                    - Use Private Virtual Interface for each VPC and VPC Peering for all VPCs<br/>
                                    - Use Transit interface (or public vf for vpn), Transit Gateway (using VPN or DX/DX Connect Gateway) and connect to each VPC (with each transit gateway)<br/>
                                <br/><br/>
                                <img src="https://docs.aws.amazon.com/vpc/latest/peering/images/peering-intro-diagram.png"></img>

                                <br/>
                                <img src="https://docs.aws.amazon.com/vpc/latest/peering/images/one-to-two-vpcs-flying-v.png"></img>

                                <br/>
                                Steps:<br/>
                                - Owner request peering<br/>
                                - acceptor accepts<br/>
                                - owner adds routes to one or more of their vpc route table to the IP range of the other<br/>
                                - modify the security group<br/>
                                - Enable DNS HostName resolution: by default the ips are resolved using public ips. after enabling this, instances on either side connection addresses using public dns host name, the host name resolves to the private ip address of the instance<br/>
                                - Ex: to peer VPC A, B, C. peer A to C, A to B, B to C. Ensure route tables in each VPC are updated with respective peering configuration<br/>
                                
                                <br/>
                                Limitations:<br/>
                                - Cannot have CIDR overlapping<br/>
                                - quota of active/pending VPC peering connections<br/>
                                - Does NOT support Transitive peering. Mesh peering is allowed. A to B, A to C, B to C. You cannot traverse A to C through B <br/>
                                <br/>
                                
                                <br/>
                                
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>    
                    <div className="pad-top-10"></div>
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                Amazon Virtual Private Cloud Connectivity Options 
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           
                            <AccordionItemPanel>
                                
                            - VPC connectivity to remote customer networks is best achieved when using non-overlapping IP ranges for each network being connected. <br/>
                            - For example, if you’d like to connect one or more VPCs to your home network, make sure they are configured with unique Classless Inter-Domain Routing (CIDR) ranges. <br/>
                            - AWS recommend allocating a single, contiguous, non-overlapping CIDR block to be used by each VPC.<br/><br/>
                             
     
                            <button  className="linkbutton" onClick={() => this.openUrl('https://docs.aws.amazon.com/whitepapers/latest/aws-vpc-connectivity-options/network-to-amazon-vpc-connectivity-options.html')}>Refer: </button> 

                            <br/>
                            AWS Managed VPN<br/>
                                Use Case: AWS managed IPsec VPN connection over the internet to individual VPC	<br/>
                                Advantages:<br/>
                                - Reuse existing VPN equipment and processes<br/>
                                - Reuse existing internet connections<br/>
                                - AWS managed high availability VPN service<br/>
                                - Supports static routes or dynamic Border Gateway Protocol (BGP) peering and routing policies<br/>

                                <br/>
                                Limitations:<br/>
                                - Network latency, variability, and availability are dependent on internet conditions<br/>
                                - Customer managed endpoint is responsible for implementing redundancy and failover (if required)<br/>
                                - Customer device must support single-hop BGP (when leveraging BGP for dynamic routing)<br/>

                                <br/>
                            AWS Transit Gateway + VPN<br/>
                                Use Case: AWS managed IPsec VPN connection over the internet to regional router for multiple VPCs<br/>
                                Advantages:<br/>
                                - Reuse existing VPN equipment and processes<br/>
                                - Reuse existing internet connections<br/>
                                - AWS managed high availability VPN service<br/>
                                - Supports static routes or dynamic Border Gateway Protocol (BGP) peering and routing policies<br/>
                                - AWS managed high availability and scalability regional network hub for up to 5,000 attachments<br/>

                                <br/>
                                Limitations:<br/>
                                - Network latency, variability, and availability are dependent on internet conditions<br/>
                                - Customer managed endpoint is responsible for implementing redundancy and failover (if required)<br/>
                                - Customer device must support single-hop BGP (when leveraging BGP for dynamic routing)<br/>

                                <br/>
                            AWS Direct Connect<br/>
                                Use Case: Dedicated network connection over private lines<br/>
                                Advantages: <br/>
                                - More predictable network performance<br/>
                                - Reduced bandwidth costs<br/>
                                - Supports BGP peering and routing policies<br/>
                                <br/>
                                Limitations:<br/>
                                - May require additional telecom and hosting provider relationships or new network circuits to be provisioned<br/>

                                <br/>
                            AWS Direct Connect + AWS Transit Gateway<br/>
                                Use Case: Dedicated network connection over private lines to regional router for multiple VPCs<br/>
                                Advantages: <br/>
                                - More predictable network performance<br/>
                                - Reduced bandwidth costs<br/>
                                - Supports BGP peering and routing policies<br/>
                                - AWS managed high availability and scalability regional network hub for up to 5,000 attachments<br/>
                                <br/>
                                Limitations:<br/>
                                - May require additional telecom and hosting provider relationships or new network circuits to be provisioned<br/>

                                <br/>
                            AWS Direct Connect + VPN<br/>
                                Use Case: IPsec VPN connection over private lines<br/>
                                Advantages:<br/>
                                - More predictable network performance<br/>
                                - Reduced bandwidth costs<br/>
                                - Supports BGP peering and routing policies on AWS Direct Connect<br/>
                                - Reuse existing VPN equipment and processes<br/>
                                - AWS managed high availability VPN service<br/>
                                - Supports static routes or dynamic Border Gateway Protocol (BGP) peering and routing policies on VPN connection<br/>
                                <br/>
                                Limitations:<br/>
                                - May require additional telecom and hosting provider relationships or new network circuits to be provisioned<br/>
                                - Customer managed endpoint is responsible for implementing redundancy and failover (if required)<br/>
                                - Customer device must support single-hop BGP (when leveraging BGP for dynamic routing)<br/>


                                <br/>
                            AWS Direct Connect + AWS Transit Gateway + VPN	<br/>
                                Use Case: IPSec VPN connection over private lines to regional router for multiple VPCs<br/>
                                Advantages:<br/>
                                - More predictable network performance<br/>
                                - Reduced bandwidth costs<br/>
                                - Supports BGP peering and routing policies on AWS Direct Connect<br/>
                                - Reuse existing VPN equipment and processes<br/>
                                - AWS managed high availability VPN service<br/>
                                - Supports static routes or dynamic Border Gateway Protocol (BGP) peering and routing policies on VPN connection<br/>
                                - AWS managed high availability and scalability regional network hub for up to 5,000 attachments<br/>
                                <br/>
                                Limitations:<br/>
                                - May require additional telecom and hosting provider relationships or new network circuits to be provisioned<br/>
                                - Customer managed endpoint is responsible for implementing redundancy and failover (if required)<br/>
                                - Customer device must support single-hop BGP (when leveraging BGP for dynamic routing)<br/>
                                
                                <br/>
                            AWS VPN CloudHub<br/>
                                Use Case: Connect remote branch offices in a hub-and-spoke model for primary or backup connectivity<br/>
                                Advantages:<br/>
                                - Reuse existing internet connections and AWS VPN connections<br/>
                                - AWS managed high availability VPN service<br/>
                                - Supports BGP for exchanging routes and routing priorities<br/>
                                <br/>
                                Limitations:<br/>
                                - Network latency, variability, and availability are dependent on the internet<br/>
                                - User managed branch office endpoints are responsible for implementing redundancy and failover (if required)<br/>

                                <br/>
                            Software Site-to-Site VPN<br/>
                                Use Case: Software appliance-based VPN connection over the internet<br/>
                                Advantages:<br/>
                                - Supports a wider array of VPN vendors, products, and protocols<br/>
                                - Fully customer-managed solution<br/>
                                <br/>
                                Limitations:<br/>
                                - Customer is responsible for implementing HA (high availability) solutions for all VPN endpoints (if  required)<br/>
                            
                                <br/>
                            SCENARIO 1 - VPC with a single public subnet<br/>
                            
                            - Use case: single tier/simple web application public facing<br/>
                            <img src="https://docs.aws.amazon.com/vpc/latest/userguide/images/case-1.png"></img>

                            <br/>
                            SCENARIO 2 - VPC with public and private subnets (NAT)   <br/>
                            - Use case: public-facing web application, back-end servers are not public. <br/>
                                Ex: multi-tier website, web servers in a public subnet and the database servers in a private subnet. <br/>
                                You can set up security and routing so that the web servers can communicate with the database servers<br/>
                            - The database servers can connect to the Internet for software updates using the NAT gateway, but the Internet cannot establish connections to the database servers.<br/>
                            - For IpV6, egress only internet gateways can be used (refer below)<br/>

                            <img src="https://docs.aws.amazon.com/vpc/latest/userguide/images/nat-gateway-diagram.png"></img>

                            <br/>
                            SCENARIO 3 - VPC with public and private subnets and AWS Site-to-Site VPN access<br/>
                            - VPC with a public/private subnet virtual private gateway to enable communication with your own network over an IPsec VPN tunnel<br/>
                            - Use case: to extend your network into the cloud and also directly access the internet from your VPC<br/>
                            - Refer below for Site to site VPN<br/>
                                Ex: Web app in public subnet, data in a private subnet connected to your network by an IPsec AWS Site-to-Site VPN connection.<br/>

                                <img src="https://docs.aws.amazon.com/vpc/latest/userguide/images/case-3.png"></img>

                            <br/>
                            SCENARIO 4 - VPC with a private subnet only and AWS Site-to-Site VPN access<br/>
                            - VPC with a single private subnet, and a virtual private gateway to enable communication with your own network over an IPsec VPN tunnel<br/>
                            - No internet gateway for internet communication<br/>
                            - use case: to extend your network into cloud without exposing your infrastructure into internet<br/>
                                <img src="https://docs.aws.amazon.com/vpc/latest/userguide/images/case-4.png"></img>
                                

                                <br/>
                            AWS VPN CloudHub<br/>
                            - for multiple aws site to site vpn connections<br/>
                            - BUT remote sites can communicate with each other and not just with the VPC<br/>
                            - customer gateway and the virtual private gateway advertises and re-advertises the route for them to visible to communicate across<br/>
                            - low cost hub and spoke model for primary back and connectivity between multiple/remote offices<br/>
                            - NOTE:<br/>
                            - Sites that use AWS Direct Connect connections to the virtual private gateway can also be part of the AWS VPN CloudHub. <br/>
                            - For example, your corporate headquarters in New York can have an AWS Direct Connect connection to the VPC and your branch offices can use Site-to-Site VPN connections to the VPC. <br/>
                            - The branch offices in Los Angeles and Miami can send and receive data with each other and with your corporate headquarters, all using the AWS VPN CloudHub.<br/>
                            <img src="https://docs.aws.amazon.com/vpn/latest/s2svpn/images/AWS_VPN_CloudHub-diagram.png"></img>
                            
                                
                            <br/>
                            Egress-only internet gateways<br/>
                            <button  className="linkbutton" onClick={() => this.openUrl('https://docs.aws.amazon.com/vpc/latest/userguide/egress-only-internet-gateway.html')}>Egress-only internet gateways: </button>
                            <br/>
                            -  This enables instances in the private subnet to send requests to the Internet over IPv6 <br/>
                            - Example, for software updates<br/>
                            -  An egress-only Internet gateway is necessary if you want instances in the private subnet to be able to initiate communication with the Internet over IPv6. <br/>
                            - IPv6 addresses are globally unique, and are therefore public by default. <br/>
                            - If you want your instance to be able to access the internet, but you want to prevent resources on the internet from initiating communication with your instance, you can use an egress-only internet gateway. <br/>
                            NOTE:<br/>
                            - An egress-only internet gateway is stateful: - it forwards traffic from the instances in the subnet to the internet or other AWS services, and then sends the response back to the instances.<br/>
                            - Security group: Cannot associate security group to this. can use sg of your instances in private subnet to control traffic to and from instances<br/>
                            - NACL: CAN use nacl to control traffic to and from subnet for which this can route traffic<br/>
                            - Steps to use egress only internet gateway<br/>
                            - Create an egress-only internet gateway in your VPC <br/>
                            - Add a route to your route table that points all IPv6 traffic (::/0) <br/>
                            - or a specific range of IPv6 address to the egress-only internet gateway. <br/>
                            IPv6 traffic in the subnet that's associated with the route table is routed to the egress-only internet gateway.  <br/>
                            <img src="https://docs.aws.amazon.com/vpc/latest/userguide/images/egress-only-igw-diagram.png"></img>
                            
                            <br/>
                            TERMINOLOGIES<br/>

                            VPN connection: <br/>
                            - A secure connection between your on-premises equipment and your VPCs.<br/>

                            <br/>
                            VPN tunnel: <br/>
                            - An encrypted link where data can pass from the customer network to or from AWS.<br/>
                            - Each VPN connection includes two VPN tunnels which you can simultaneously use for high availability.<br/>

                            <br/>
                            Customer gateway: <br/>
                            - An AWS resource which provides information to AWS about your customer gateway device.<br/>

                            <br/>
                            Customer gateway device: <br/>
                            - A physical device or software application on your side of the Site-to-Site VPN connection.<br/>

                            <br/>
                            Virtual private gateway: <br/>
                            - The VPN concentrator on the Amazon side of the Site-to-Site VPN connection. You use a virtual private gateway or a transit gateway as the gateway for the Amazon side of the Site-to-Site VPN connection.<br/>

                            <br/>
                            DX Virtual Interfaces (Public/Private)<br/>
                            - To use DX you need a public or private or transit virtual interface<br/>
                            Private virtual interface: <br/>
                            - Access an Amazon VPC using private IP addresses.<br/>
                            Public virtual interface: <br/>
                            - Access AWS services from your on-premises data center. <br/>
                            - Allow AWS services, or AWS customers access your public networks over the interface instead of traversing the internet.<br/>
                            Transit virtual interface: <br/>
                            - Access one or more Amazon VPC Transit Gateways associated with Direct Connect gateways. <br/>
                            - You can use transit virtual interfaces with 1/2/5/10 Gbps AWS Direct Connect connections.<br/>
                            
                            <button  className="linkbutton" onClick={() => this.openUrl('https://aws.amazon.com/premiumsupport/knowledge-center/public-private-interface-dx/')}>Premium Support: </button>

                            <br/>
                            Hosted virtual interfaces<br/>
                            - To use your AWS DX with another AWS account, you can create a hosted virtual interface for that account. <br/>
                            - The owner of the other account must accept the hosted virtual interface to begin using it. <br/>
                            - A hosted virtual interface works the same as a standard virtual interface and can connect to public resources or a VPC.<br/>
                            - A connection of less than 1 Gbps supports only one virtual interface.<br/>
                            - NOTE: you need to create a DX connection and LAG when you have multiple connections to be treated as one<br/>

                            
                            <button  className="linkbutton" onClick={() => this.openUrl('https://docs.aws.amazon.com/vpc/latest/tgw/what-is-transit-gateway.html')}>Transit gateway: </button>
                            <br/>
                            - A transit hub that can be used to interconnect your VPCs and on-premises networks. <br/>
                            - You use a transit gateway or virtual private gateway as the gateway for the Amazon side of the Site-to-Site VPN connection.<br/>
                            - Concepts:<br/>
                                - Attachments: Attach one or more VPCs, DX, VPN, peer connect another Transit gateway, connect SD-WAN/3rd party network appliance-based<br/>
                                - MTU (max transmission unit) - size, largest permission packet that can pass over connection. TGW supports upto 8500 bytes of traffic. traffic over VPN upto 1500 bytes<br/>
                                - Route propagation - vpc, vpn, DX can propage dynamically routes to TGW<br/>
                                    - with VPC create static routes to send traffic to TGW<br/>
                                    - with VPN or DX, use Border Gateway Protocol(BGP) to propagate routes from TGW to on-premise/customer <br/>
                                    - with TGW peering, create static route to the peering attachent<br/>
                                - Route table - default TGW attachments are associated with default TGW route table. you can have custom dynamic and static routes<br/>

                            <br/><br/>    
                            DHCP
                            <br/>
                            - provides a standard for passing configuration information to hosts on a TCP/IP network<br/>
                            - options field of a DHCP message contains configuration parameters, including the domain name, domain name server, and the netbios-node-type<br/>
                            - After you create a set of DHCP options, you can't modify them. <br/>
                            - If you want your VPC to use a different set of DHCP options, you must create a new set and associate them with your VPC.<br/>
                            - You can also set up your VPC to use no DHCP options at all<br/>
                            - You can have multiple sets of DHCP options, but you can associate only one set of DHCP options with a VPC at a time. <br/>
                            - If you delete a VPC, the DHCP options set that is associated with the VPC is disassociated from the VPC<br/>
                            - use cases: ntp servers to synchronize time across systems.<br/>

                            <br/>
                            DHCP options set<br/>
                            - can create as many additional DHCP options sets as you want<br/>
                            - However, you can only associate a VPC with one set of DHCP options at a time. <br/>
                            - After you create a set of DHCP options, you must configure your VPC to use it.<br/>
                            - can delete DHCP options set if you no longer need them. make sure to change the vpc that uses to use none or other<br/>

                                <br/>
                                
                                
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>  
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Other Services (For Networking Specialty)
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           
                            <AccordionItemPanel>
                            

                            AWS WorkSpaces
                            <br/>
                            - Certain ports needs to be open (varies by AWSregion/grouped in subsets)<br/>
                            - These ports need to be opened by the client/firewall<br/>
                            - For client apps - TCP 443, 4172, UDP - 4172<br/>
                            - to ensure specific/static IP address to have access, use IP access control group<br/>
                            - can create upto 25 IP access control groups with upto 10 rules per group specifying ip addresses or ranges accessible to the workspaces<br/>
                            - aws recommends 1 public/2 private subnets at least<br/>
                            - by default 2 network interfaces one for connectivity within vpc and another as management network interface (to stream workspace desktop to amazon workspace clients)<br/>
                            - WorkSpaces must have access to the internet.<br/>
                            an create a VPC with two private subnets for your WorkSpaces and a NAT gateway in a public subnet. <br/>
                            Alternatively, you can create a VPC with two public subnets for your WorkSpaces and associate an Elastic IP address with each WorkSpace<br/>
                            - AWS Directory services and AD connector works with AWS Workspaces<br/>
                            - MFA RADIUS - Remote Authentication Dial-In User Service <br/>
                            - server or a plugin to a radius server is implemented in your on-premises infrastructure. <br/>
                            - key MFA solution requirement. <br/>
                            - provides industry standard client/server protocol for authentication, authorization, and accounting management to enable users to connect network services<br/>
                            
                            <br/>
                            Active Directory <br/>
                            - simple AD <br/>
                            to try/least expensive option, very few features, 5000 or less users<br/>
                            - AWS Directory service - feature rich managed AD hosted in aws cloud. if more than 5000 users. <br/>
                            need a trust relationship between aws hosted directory and on-premises directory  <br/>
                            - AD Connector<br/>
                            just a connector service. to validate against on-premise data<br/>


                            <br/>
                            Common (Suggested) Network Practices:<br/>
                            1) Use VPC (gateway)Endpoint for S3<br/>
                            2) Using DX,<br/>
                                a) Using DX, use public virtual interface (vf) while accessing public EC2, S3, dynamodb<br/>
                                b) Using DX, use private vf to access internal private VPC resources<br/>
                                c) Using DX, use public vf if leveraging VPN (for backup)<br/>
                                d) Using DX, use transit vf if using transit gateways to peer VPC(S)<br/>
                            3) Use NAT Gateways in Pubilc subnet (if not NAT instances with redundancy/script for backup/failures) for servers in Private subnet<br/>
                                a) NAT Gateway support upto 900 connections per second/55000 per minute simultaneous connections to each destinations per minute.<br/>
                                beyond which will get port allocation error. ErrorPortAllocation metric to monitor the count. <br/>
                                b) can create additional nat gateways and split client instances load through them<br/>
                                c) supports tcp/udp/icmp protocols<br/>
                                d) does NOT support  fragmented packets for TCP<br/>
                            4) Use egress internet gateways for IpV6 instances in private subnet<br/>
                            5) Use Squid proxy with NAT instance for DNS filtering or whitelisting.<br/>
                            another usecase: <br/>
                            for private subnets defined in vpc, responses from external server needs to be relayed back to private servers on pre defined ports   <br/>
                            6) to access s3, dynamodb, ec2 in public instance use VPC endpoint. <br/>
                            - ex: usecase emr in private subnet using an existing vpc and nat gateway(or vpc endpoint or a nat instance)<br/>
                            7) IPS/DPS<br/>
                                a) use Palo alto or other networks that monitors, filters, and alerts of all potential hazard traffic entering and leaving the VPC<br/>
                                b) NOTE: packet sniffing in promiscuous mode is NOT allowed in AWS <br/>
                                c) wireshark - to monitor traffic flows on your instance, deep packet inspection <br/>
                                d) usecases: ids/ips agents on each instances in vpc, reverse proxy in front of web servers<br/>
                                <br/>
                                GuardDuty:<br/>
                                - GuardDuty identifies threats by continuously monitoring the network activity and account behavior within the AWS environment. <br/>
                                - Amazon GuardDuty comes integrated with up-to-date threat intelligence feeds<br/>
                                - In addition to detecting threats, GuardDuty also makes it easy to automate how you respond to threats, reducing your remediation and recovery time.<br/>
                                - GuardDuty makes enablement and management across multiple accounts easy. <br/>
                                - Through the multi-account feature, all member accounts findings can be aggregated with a GuardDuty administrator account.<br/>

                            <br/>
                            AWS Config service enables to assess, audit and evaluate configurations of your aws resources. provides config history. monitor resources based on configuration changes or periodically<br/>
                            AWS CloudWatch - app logs<br/>
                            AWS CloudTrail - APIs<br/>
                            AWS VPC Flow Logs - VPC/subnet/eni level logs. turn on vpc flow logs, send the logs to s3 and use athena to query.<br/>
                            can be enabled for - vpc, subnet, network interface. cannot do deep packet inspection. has details like ip/port/packets etc., <br/>
                            AWS CloudFormation - to automate your resources. use params, custom resource using lambda <br/><br/>

                            <br/>
                            Security group (stateful) and NACL(Stateless)<br/>
                            - remember outbound rule to allow ephemeral ports<br/>
                            - sg instance level and nacls are subnet level<br/>
                            - NACL cannot stop DDOS. WAF can handle blocking specific IPs list<br/>

                            <br/>    
                            Lambda@Edge<br/>
                            - ability to execute a Lambda function at an Amazon CloudFront Edge Location<br/>
                            - enables intelligent processing of HTTP requests at locations that are close (for the purposes of latency) to your customers<br/>
                            - Ex: add header in response to the Origin Response CloudFront event. Refer: https://aws.amazon.com/blogs/networking-and-content-delivery/adding-http-security-headers-using-lambdaedge-and-amazon-cloudfront/<br/><br/>
                                
                            <br/>
                            VPC Interface Endpoint (Private Link):<br/>
                            -  can be used to route traffic to the endpoint using AWS network without traversing the internet<br/>
                            - AWS PrivateLink enables you to connect to some AWS services, services hosted by other AWS accounts (referred to as endpoint services), and supported AWS Marketplace partner services, via private IP addresses in your VPC. <br/>
                            - The interface endpoints are created directly inside of your VPC, using elastic network interfaces and IP addresses in your VPC’s subnets. That means that VPC Security Groups can be used to manage access to the endpoints.<br/>
                            LINK -> DIAG -> https://docs.aws.amazon.com/whitepapers/latest/aws-vpc-connectivity-options/images/image20.png<br/>

                            <br/>
                            WAF<br/>
                            A web access control list (web ACL) gives you fine-grained control over the web requests that your protected resource responds to. <br/>
                            protect: Amazon CloudFront, Amazon API Gateway, Application Load Balancer, and AWS AppSync resources.<br/>
                            <br/>
                            to allow or block requests:<br/>
                            - IP address origin of the request<br/>
                            - Country of origin of the request<br/>
                            - String match or regular expression (regex) match in a part of the request<br/>
                            - Size of a particular part of the request<br/>
                            - Detection of malicious SQL code or scripting<br/>

                            <br/>
                            Elastic LoadBalancers<br/>
                                refer: https://aws.amazon.com/elasticloadbalancing/features/#Product_comparisons <br/>
                                <br/>
                                CLB - layer 4/7<br/>
                                - legacy apps<br/>
                                - TCP, SSL/TLS, HTTP, HTTPS<br/>
                                - ec2-classic and vpc<br/>
                                - doesnt validate client side certificate. can be passed to tcp/443 and underlying ec2 instance can handle<br/>
                                ALB - layer 7<br/>
                                - path based routing /images, /videos etc.,<br/>
                                - TCP, UDP, TLS<br/>
                                - supports redirects, fixed response, http header based routing and other layer 7 specific features<br/>
                                - on-premise server private ip can also be a target group <br/>
                                NLB - layer 4<br/>
                                - supports static addresses. supports static ip per availability zone<br/>
                                - HTTP, HTTPS, gRPC<br/>
                                - does NOT support sticky sessions<br/>
                                
                                <br/>
                                - if we dont want to do SSL termination/SSL Offloading (LB decrypts traffic requests from clients before sending to back end),<br/>
                                can use TCP mode for both frontend, backend connections and deploy certificates on registered instances. <br/>
                                in case of Amazon RDS for SSL, use REQUIRE SSL grants, configure application instances for SSL termination with TCP mode for ELB protocols<br/>
                                - to accept traffic from specific IP, use ALB's "X-Forwarded-For". TCP - "Proxy Protocol" for tcp/ssl listener<br/>                                
                                - connection draining<br/>
                                
                                <br/><br/>
                            AWS CloudHSM<br/>
                            - provides hardware security module in the cloud<br/>
                            - can create from 1 to 28 HSMs(default is 6 per account per region)	  <br/>
                            - use AWS cloudHSM to offload SSL/TLS processing of web server. EC2 autoscaling of web servers to handle the grown. <br/>
                            - Have multiple CloudHSM instances to the cluster. LB will handle the scake<br/>
                                    
                            <br/>
                            Cluster Placement Groups<br/>
                            - Logical placement of instances within single availability zone<br/>
                            - benefit from low latency, high network throughput or both<br/>
                            - use supported instance types <br/>

                            <br/>
                            Enhanced Networking<br/>
                                uses single root I/O virtualization (SR-IOV) to provide high-performance networking capabilities on supported instance types (except t2)<br/>
                                provides higher I/O performance and lower CPU utilization<br/>
                                provides higher bandwidth, higher packet per second (PPS) performance, and consistently lower inter-instance latencies<br/>
                                Elastic Network Adapter (ENA) supports network speeds of up to 100 Gbps for supported instance types.<br/>
                                Intel 82599 Virtual Function interface supports network speeds of up to 10 Gbps for supported instance types<br/>
                                for highest network performance, enable enhanced networking, use HVM AMI (NOT Para virtual AMI) and Amazon VPC<br/>
                                
                            <br/>
                            Network maximum transmission unit (MTU)<br/>
                                is the size, in bytes, of the largest permissible packet that can be passed over the connection<br/>
                                Ethernet packets consist of the frame, or the actual data you are sending, and the network overhead information that surrounds it.<br/>
                                Ethernet v2 frame format - It supports 1500 MTU, which is the largest Ethernet packet size supported over most of the internet<br/>
                                All Amazon EC2 instance types support 1500 MTU, and many current instance sizes support 9001 MTU (depends on instance types), or jumbo frames<br/>
                                Increase MTU to the instance NOT to the VPC<br/>
                                Jumbo frames (9001 MTU)<br/>
                                    - outside vpc can send only upto 1500MTU<br/>
                                    - within vpc can be upto 9001MTU<br/>
                                    - If packets are over 1500 bytes, they are fragmented, or they are dropped if the Don't Fragment flag is set in the IP header.<br/>

                            <br/>                                
                            Path MTU Discovery<br/>
                            - Path MTU Discovery (PMTUD) is used to determine the maximum transmission unit (MTU) of a network path<br/>
                            - Path MTU is the maximum packet size between the originating host and the receiving host<br/>
                            - check the path MTU between hosts using tracepath command, (part of iputils package)available in linux distros like Amazon Linux	<br/>

                            <br/>
                            CloudFront<br/>
                            - Amazon CloudFront is a web service that speeds up distribution of your static and dynamic web content, such as .html, .css, .js, and image files, to your users. CloudFront delivers your content through a worldwide network of data centers called edge locations.<br/>
                            - If the content is already in the edge location with the lowest latency, CloudFront delivers it immediately.<br/>
                            - If the content is not in that edge location, CloudFront retrieves it from an origin that you've defined—such as an Amazon S3 bucket, a MediaPackage channel, or an HTTP server<br/>
                            - can use on-premise server as origin. can enable query string forwarding and suitable TTL on the cloudfront CDN.  Offload the DNS to AWS to handle CloudFront CDN traffic<br/>
                            - Use WAF to control access to your content<br/>
                            - configure cloudfront to use custom header, configure aws waf rule on an origin LB to accept only traffic contains the header<br/>
                            - geo restriction/geo blocking<br/>
                            - to prevent users in specific geographic locations from accessing content that you're distributing through a CloudFront web distribution<br/>
                            - Use OAI with private S3 bucket, select Restrict Viewer Access option in cloudfront cache behavior to use signed urls<br/>
                            - X-Forwarded-For - request header helps to identify the op address of a client when you use http/https load balancer<br/>
                            
                            <br/>
                            Quality Of Service - <br/>
                            QoS marking can be done<br/>
                            <br/>
                            DSCP = differentiated services code point classifies and manages network traffic and of providing QiS in modern layer 3 IP networks. has 6 field in the IP header for packet clasification. provides simple and scalable mechanism <br/>
                            MPLS - Multiprotocol Label Switching<br/>
                            FEC -  forwarding equivalence class is used in MPLS to describe a set of packets with similar and or identical characters which be forwarded the same way; that is they may be bound to the same MPLS label<br/>

                            <br/><br/>
                            For the VPC or Instances<br/>
                            - DHCP is for VPC (not instances)<br/>
                            - MTU is for instances (not VPC)<br/>
                            - Enhanced networking is for instances (not VPC)<br/>
                                
                                <br/>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>  
                </div>              
            </div>
           
          </div>
      </div>
    );
  }
}
 
export default VPCNetworkSpecialty;