import React, { Component } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

class CloudFormation extends Component {

    openUrl(value){
        window.open(value, false);
      }
      
      
  render() {
    return (
      <div className="pad-top-30 pad-left-page">
          <div class="row">
            <div class="col-sm-7 col-md-5">
                  <div class="">
                    <h2>Cloudformation</h2>                    
                    
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                Cloudformation
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           
                            <AccordionItemPanel>
                            cloudformation is a json file with which we can create a complete environment we provide as template, update and deploy
					ex: sharepoint with Active directory, SQL environment etc.,
					<br/>
					template - collection of resources, attributes etc.,
					<br/>
					stack - all the resources which are created as part of the template
					<br/>

                    AWS CloudFormation introduces two concepts: <br/>
					The template, a JSON or YAML-format, text-based file that describes all the AWS resources <br/>
					you need to deploy to run your application and the stack, the set of AWS resources that are created <br/>
					and managed as a single unit when AWS CloudFormation instantiates a template.<br/><br/>

					You can easily access AWS CloudFormation through the AWS Management Console, <br/>
					which gives you a point-and-click, web-based interface to deploy and manage stacks. <br/><br/>

					You can create a new stack from inside the AWS Management Console in a few simple steps:<br/><br/>

					1.Give the stack a name: Provide a unique name for the stack.<br/>
					2.Select a template: Select a template from your local file system or from a Amazon S3 URL. <br/>
					This may be one of the sample AWS CloudFormation templates, your own custom template, <br/>
					a template you are managing in a source control repository, or a template you got from a third party.<br/>
					3.Specify any parameters: If the template allows you to configure the deployment,<br/>
					 fill in any parameters or go with the specified defaults.<br/><br/>

					 AWS CloudFormation provides several built-in functions that help you manage your stacks. <br/>
					 Use <b>intrinsic functions</b> in your templates to assign values to properties that are not available until runtime <br/>
					 - Fn::Base64, Condition Functions, Fn::FindInMap, Fn::GetAtt, <br/>
					 - Fn::GetAZs, Fn::ImportValue, Fn::Join, Fn::Select, Fn::Split, Fn::Sub, Ref,<br/><br/>


					 <b>Pseudo parameters</b> are parameters that are predefined by AWS CloudFormation. <br/>
					 You do not declare them in your template. Use them the same way as you would a parameter, as the argument for the Ref function.<br/>
					 AWS::AccountId, AWS::NotificationARNs, AWS::NoValue, AWS::Partition, AWS::Region, AWS::StackId, AWS::StackName, AWS::URLSuffix<br/>
					ex:<br/>
					{/* "Outputs" : {<br/>
					   "MyStacksRegion" : { "Value" : { "Ref" : "AWS::Region" } }<br/>
					}<br/> */}
                                
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>    
                    <div className="pad-top-10"></div>
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                ways to create and sample templates
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           
                            <AccordionItemPanel>
                            Various ways to create <br/>

                           
                            - Create a stack <br/>
                            - design a template - builds/visio style template to builds/visio <br/>
                            - create a template from existing resources <br/>

                            Sample Templates available <br/>
                            - Single and Multi-AZ samples - LAMP, Ruby on Rails, Word Press <br/>
                            - windows samples - features & roles and active directory <br/>
                            - tools - CloudFormer <br/>
                                
                            Other Information: <br/>

                             Ex: From Console - if we create a word press site with DB <br/>
                            "Specify Details" section : <br/>

                            Parameters section - DBAllocateStorage, DBClass, DBName, DBPasword, DBUser, Istance type, KeyName<br/>
                                                MultiAZDatabase, SSHLocation, Subnets, VPCId, WebServercapacity<br/>

                                <br/>
                                Other notes<br/>

                                 DeletionPolicy - Retain ex: to retain security group after deletion also<br/>
                                 Ref - a constant used to refer another parameter from the template<br/>
                                 Fn::FindInMap<br/>
                                 Fn:GetAtt <br/>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>  

                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                Different sections in template
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           
                            <AccordionItemPanel>
                            
						- AWSTemplateFormatVersion - string description  <br/>
						
						- Description - string description <br/>
						
                            - Mappings - based on certain value (ex: get images from oregon region) <br/>
                                <pre>
                              
                                </pre>
                                <br/>
                                
                                - Parameters -  this is an object. helps to select individual values at the time of execution. <br/>
                                ex: for creating "InstanceType", we can have "AllowedValues" like "t2.micro", "t2.small" etc., <br/>

                                Generally has "Default", "MinValue","MaxValue" <br/>
                                ex: <br/>
                                <pre>
                                {/* "Parameters":{
                                    "ReadCapacityUnits":{
                                        "Description": "Provisioned read throughput",
                                        "Type":"Number",
                                        "Default":"1",
                                        "MinValue":"1",
                                        "MaxValue":"1000",
                                        "ConstrainedDescription":"must be between 1 and 1000"
                                    }
                                } */}
                                </pre>
                                <br/>
                                
                                - Resources - mandatory section / object . Can create a table, EC2 instance or any resource <br/>
                                <pre>
                                {/* "Resources":{
                                    // "TableOfProducts":{
                                    //     "Type": "AWS DynamoDB Table",
                                    //     "Properties":{
                                    //         "AttributeDefinitions":[
                                    //             {
                                    //                 "AttributeName":"Id",
                                    //                 "AttributeType":"N"
                                    //             },
                                    //             {
                                    //                 "AttributeName":"ProductCategory",
                                    //                 "AttributeType":"S"
                                    //             }
                                    //         ]},
                                    //         "KeySchema":{
                                    //             // definition goes in here
                                    //         },
                                    //         "ProvisionedThroughput":{
                                    //             // definition goes in here
                                    //         },
                                    //         "GlobalSecondaryIndexes":{
                                    //             // definition goes in here
                                    //         },
                                    //     }

                                    } */}
                                }
                                </pre>
                            <br/>
                            
                            - Outputs - gives output of response. Output can be read by the application. <br/>
                            ex: RDS endpoint can be returned  a .net/java application may use it <br/>
                            ex: <br/>
                            <pre>
                            {/* "Outputs":{
                                    // "TableName":{
                                    //     "Value":{
                                    //         "Ref":"TableOfProducts",
                                    //     },
                                    //     "Description": "cloudformation template for dynamodb"
                                    } */}
                                }
                            </pre>
                            <br/>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>  
                      
                </div>              
            </div>
           
          </div>
      </div>
    );
  }
}
 
export default CloudFormation;