import React, { Component } from "react";
import KinesisTranscoders from "./KinesisTranscoders";
import Limits from "./Limits";
import GeneralTips from "./GeneralTips";
import SysopsAdminPrep from "./SysopsAdminPrep";
import TypoGraphy from '@material-ui/core/Typography'
import { Grid, Paper, Typography, List, ListItem } from "@material-ui/core";



class DeployOrchestration extends Component {

  constructor(props){
    super(props)
       window.clickLog("load", "Others")    
  } 

    openUrl(value){
        window.open(value, false);
      }
      
      
  render() {
    return (
      <div>
        <div style={{ marginTop: 20, padding: 30 }}>
            <Grid container spacing={40} justify="center">
                <TypoGraphy variant="h4">
                    <center>Others</center>
                </TypoGraphy>
            </Grid>
            <div className="pad-left-page">
                <KinesisTranscoders></KinesisTranscoders>
                <div className="pad-top-10"></div>
                <Limits></Limits>
                <div className="pad-top-10"></div>
                <GeneralTips></GeneralTips>
                <div className="pad-top-10"></div>
                <SysopsAdminPrep></SysopsAdminPrep>
              
            </div>
          </div>
      </div>
    );
  }
}
 
export default DeployOrchestration;