
import React, { Component } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

class SysopsAdminPrep extends Component {

    openUrl(value){
        window.open(value, false);
      }
      
      
  render() {
    return (
      <div className="pad-top-30 pad-left-page">
          <div class="row">
            <div class="col-sm-7 col-md-5">
                  <div class="">
                    <h2>SysOps Admin Preparation</h2>                    
                  
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                SysOps Admin Preparation
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           
                            <AccordionItemPanel>
                            With references from AWS documentation, faqs and sites like acloud.guru, jayendrapatil.com etc., i was able to start my preparation on AWS SysOpsAdmin Certification <br/>

                            PFB links details for your prep :
                            <br/>
                            <li><a href="#disasterrecovery" onclick="#">Disaster Recovery</a></li>
                            <li><a href="#monitoringcloud" onclick="#">Monitoring Cloud</a></li>
                            <li><a href="#cloudwatch" onclick="#">Cloudwatch</a></li>
                            <li><a href="#autoscaling" onclick="#">AutoScaling</a></li>
                            <li><a href="#rds" onclick="#">RDS</a></li>
                            <li><a href="#VPC" onclick="#">VPC</a></li>
                            <li><a href="#cloudformation" onclick="#">cloudformation</a></li>
                            <li><a href="#s3" onclick="#">s3</a></li>
                            <br/><br/><br/>
                            Note: Refer EBS, storage and gateway, SQS, ElasticBeanStalk/Opsworks sections
                                
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>    
                  
                      
                </div>              
            </div>
           
          </div>
      </div>
    );
  }
}
 
export default SysopsAdminPrep;