import React, { Component } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

class CloudWatch extends Component {

    openUrl(value){
        window.open(value, false);
      }
      
      
  render() {
    return (
      <div className="pad-top-30 pad-left-page">
          <div class="row">
            <div class="col-sm-7 col-md-5">
                  <div class="">
                    <h2>CloudWatch</h2>                    
                    

                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    CloudWatch
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           
                            <AccordionItemPanel>
                              	
					             Management tool provided by AWS. integrates with IAM so that you can specify which actions a user can perform.
                                Note: permission granted for IAM User applies for all cloud watch data for specific resource. ex: cannot control for only load balancer <br/>
                                provides dashboards, alarms, events, logs, metrics<br/>
                                standard/basic Monitoring - 5 minutes<br/>
                                Details Monitoring - 1 minutes<br/>
                                cloudwatch is for logging (monitoring). cloudtrail is for auditing (ex: new role/create s3 bucket)<br/>
                                use case: Real time Application and System Monitoring, Long Term Log Retention<br/><br/>

                                <b>Dashboard</b><br/>
                                can add widgets for aws services that are being suported by cloud watch<br/>
                                ex: S3, ELB, EBS, EC2, dynamodb etc.<br/>

                                    By default:<br/><br/>

                                    CPU utilization/credit balance/credit usage<br/>
                                    Disk read/write bytes, read/write ops<br/>
                                    Network - In and out, packets In/Out<br/>
                                    Status - instance level/system level<br/><br/>

                                    Custom metrics<br/>
                                    can create custom metrics for RAM/Memory, EBS volume (storage volume)<br/><br/>

                                <b>Alarms</b><br/>
                                    we can create metrics by category<br/>
                                    ex: to get metrics for cpu utilization <br/>
                                    Name, description, whenever (ex: >= 60 for 1 consecutive period)<br/>
                                    Action: state of the alarm is ALARM or OK or INSUFFICIENT DATA<br/>
                                    send email notification. requires confirmation on the email <br/><br/>

                                <b>Events</b><br/>
                                helps to respond state changes. ex: automatically invoke lambda to update DNS with new public IP<br/>
                                event triggers action<br/><br/>

                                <b>Logs</b><br/>
                                allows to monitor ec2/resources at application layer. ex: http response code for apache<br/>
                                or even log kernel logs<br/>
                                install agents in ec2 instances. the data is sent to cloudwatch <br/>

                                Metrics - ready to use (displays) metrics<br/><br/>

                                    
                                <b>Developer Notes</b><br/>

                                Dashboard - getDashboard, PutDashboard, ListDashboards, DeleteDashboards<br/>

                                Alarms - DescribeAlarms, DeleteAlarms,PutMetricAlarm, SetAlarmState,<br/>
                                        EnableAlarmActions, DisableAlarmAction, DescribeAlarmHistory,<br/>
                                        DescribeAlarmsForMetric<br/><br/>

                                Metrics - ListMetrics, PutMetricData, GetMetricStatistics<br/>

                                Events - PutEvents<br/>

                                LogEvents - getLogEvents, PutLogEvents, FilterLogEvents
                                            DescribeLogStreams, CreateLogGroup<br/>

                                
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>    
                    <div className="pad-top-10"></div>
                    
                      
                </div>              
            </div>
           
          </div>
      </div>
    );
  }
}
 
export default CloudWatch;