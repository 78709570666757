import React, { Component } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

class VPN extends Component {

    openUrl(value){
        window.open(value, false);
      }
      
      
  render() {
    return (
      <div className="pad-top-30 pad-left-page">
          <div class="row">
            <div class="col-sm-7 col-md-5">
                  <div class="">
                    <h2>VPN</h2>                    
                    
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                Create VPN Steps:
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           
                            <AccordionItemPanel>
                            
                            - Create VPN Connection in AWS Console <br/>
                            - Download the configuration file<br/>
                            - Install it on the on-prem servers<br/>
                            - Open firewall - router needs to allow UDP 500 and IP 50 traffic from the Virtual Private Gateways.<br/>
                            - <button  className="linkbutton" onClick={() => this.openUrl('https://docs.aws.amazon.com/vpn/latest/s2svpn/your-cgw.html#FirewallRules')}>Refer more</button>

                            <br/>
                            VPC's can be connected using VPN. some of the options<br/>
                                - VPC with public and private subnets and AWS Site-to-Site VPN access<br/>
                                - VPC with a private subnet only and AWS Site-to-Site VPN access<br/>

                            <br/>
                            AWS Site to Site VPN<br/>
                              
                            <button  className="linkbutton" onClick={() => this.openUrl('https://docs.aws.amazon.com/vpn/latest/s2svpn/VPC_VPN.html')}>Refer</button>
                            <br/>
                            - VPN connection here refers to the connection between your VPC and your own on-premises network. <br/>
                            - Site-to-Site VPN supports Internet Protocol security (IPsec) VPN connections<br/>
                            - NOTE:<br/>
                            - IpV6 traffic is NOT supported for VPN connections on a Virtual Private Gateway <br/>
                            - An AWS VPN connection does not support Path MTU discovery<br/>
                            - Make sure to use non overlapping CIDR blocks for your networks<br/>

                            <br/>
                            - Architectures<br/>
                                - SCENARIO: Single site to site (Virtual Private Gateway) vpn connection<br/>
                                - use case: subnets connects through the virtual private gateway, VPN and customer gateway to the corporate network<br/>
                                <img src="https://docs.aws.amazon.com/vpn/latest/s2svpn/images/vpn-basic-diagram.png"></img>

                                <br/>
                                - SCENARIO: Single Site-to-Site VPN connection with a Transit Gateway  <br/>
                                - use case: subnets connects through the transit gateway, VPN and customer gateway to the corporate network<br/>
                                <img src="https://docs.aws.amazon.com/vpn/latest/s2svpn/images/site-site-transit-gateway-basic.png"></img>

                                <br/>
                                - SCENARIO: Multiple Site-to-Site VPN connections<br/>
                                - use case: multiple vpn connections/ customer gateway to multiple data/corporate network <br/>
                                <img src="https://docs.aws.amazon.com/vpn/latest/s2svpn/images/branch-offices-diagram.png"></img>
                                
                                <br/>
                                - SCENARIO: Multiple Site-to-Site VPN connections with a Transit gateway  <br/>
                                - use case: multiple vpn connections/ customer gateway to multiple data/corporate network <br/>
                                <img src="https://docs.aws.amazon.com/vpn/latest/s2svpn/images/branch-off-transit-gateway.png"></img>
                                
                            
                                <br/>
                            Different VPN Usecases<br/>
                            - DX + Hardware VPN - DX would provide predictable network performance and support for BGP peering and routing policies. <br/>
                            VPN would provide a secure IPSec VPN connection but not over the internet<br/>
                            - EC2 Instance - More than 200 ec2/on-prem servers connectivity<br/>

                            <br/>
                                                            
                                
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>    
                    
                      
                </div>              
            </div>
           
          </div>
      </div>
    );
  }
}
 
export default VPN;