import React, { Component } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

class DirectConnect extends Component {

    openUrl(value){
        window.open(value, false);
      }
      
      
  render() {
    return (
      <div className="pad-top-30 pad-left-page">
          <div class="row">
            <div class="col-sm-7 col-md-5">
                  <div class="">
                    <h2>Direct Connect (DX)</h2>                    
                    
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    DX
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           
                            <AccordionItemPanel>
                            - Direct Connect can help reduce cost and provide a dedicated, predictable high performance data transfer route between VPC and the on-premise environment <br/>
                            - AWS Direct Connect makes it easy to establish a dedicated connection from an on-premises network to one or more VPCs in the same region. <br/>
                            - Using private VIF on AWS Direct Connect, you can establish private connectivity between AWS and your data center, office, or colocation environment<br/>
                            - To acces remote region, need public virtual interface for DX and can establish IPSec VPN.  Private virtual interface would work only with Direct Connect gateway <br/>
                            - Can connect 1G or 10GBPS dedicated<br/>
                            - can also create sub-1G connection (using AWS Partner network)<br/>
                            <br/>
                            - LAG - Link Aggregation Group<br/>
                                - aggregate multiple (upto 4) 1GIG or 10 GIG connections at a single DX end point<br/>
                                - this way you can treat it as a single managed connection<br/>
                            - NOTE: DX uses virtual interfaces to connect <br/>
                            private interface - to use DX directly to a region.<br/> 
                            <br/><br/>
                            refer <br/>
                            <img src="https://docs.aws.amazon.com/whitepapers/latest/aws-vpc-connectivity-options/images/image6.png"></img>

                            <br/><br/>
                            public interface  - when we use VPN with IPSec Tunnel connecting to multiple region. 
                            <br/><br/>
                            refer <br/>
                            <img src="https://docs.aws.amazon.com/whitepapers/latest/aws-vpc-connectivity-options/images/image10.png"></img>
                            <br/>
                            <br/><br/>
                            transit interface - when we use transit gateway and DX gateway connect to connect multiple VPCs. <br/>
                            <br/><br/>refer <br/>
                            <img src="https://docs.aws.amazon.com/whitepapers/latest/aws-vpc-connectivity-options/images/image9.png"></img>
                            <br/><br/>
                            - Steps:<br/>
                            - Configure DX between on-premise and VPC<br/>
                            - complete the configuration - virtual interface<br/>
                            - advertise the routes. Ensure BGP status is UP<br/>
                            - enable route propagate to Virtual Gateway (VGW). Note it is always on VGW and NOT on Customer Gateway (CGW)<br/>
                            - Modify route tables for VPC to route back to on-premise environment<br/><br/>


                            <br/>
                            Creation of Public Virtual Interface:<br/>
                            - virtual interface name, connection, vlan, BGP ASN<br/>
                            - additional settings - ipv4 or ipv6 peer (your or amazon router peer ip), BGP MD5 key, Tags<br/>

                            <br/>
                            Creation of Private Virtual Interface:<br/>
                            - virtual interface name, connection, vlan, BGP ASN<br/>
                            - Gateway Type: Virtual Private Gateway or Direct Connect Gateway, Virtual Interface Owner (if another AWS account - id)<br/>
                            - additional settings - ipv4 or ipv6(your or amazon router peer ip), MTU (default 1500 or Jumbo frames 9001), Tags<br/>

                            <br/>
                            DirectConnect (without redundancy)<br/>
                            - NOTE: DX by default is NOT fault tolerant and can be a single point of failure<br/>
                            - AWS End: AWS Region is connect to Virtual private gateway (always one per region) through Private Virtual interface<br/>
                            - Customer End: Customer router is connected (note firewall) using Single fiber mode 802.1q VLANs to the DirectConnect<br/>
                            - There can be a CROSS CONNECT/RACT on the DirectConnect <br/>
                            <br/>
                            <img src="https://docs.aws.amazon.com/whitepapers/latest/aws-vpc-connectivity-options/images/image6.png"></img>

                            <br/>
                            DirectConnect (with redundancy)<br/>
                            - Can have another DX for redudancy and fault tolerance<br/>
                            - In this case Virtual private gateway is connected with two Private Virtual interfaces <br/>
                            - each private VFs route thorugh respective DX<br/>

                            <br/>
                            <img src="https://docs.aws.amazon.com/whitepapers/latest/aws-vpc-connectivity-options/images/image7.png"></img>

                            <br/>
                            DirectConnect With VPN<br/>
                            - can combine AWS Direct Connect dedicated network connections with the Amazon VPC VPN<br/>
                            - end-to-end secure IPSec connection <br/>
                            - provides low latency, increased bandwidth of the DX to provide a more consistent network experience than internet-based VPN connections<br/>

                            <br/>
                            <img src="https://docs.aws.amazon.com/whitepapers/latest/aws-vpc-connectivity-options/images/image10.png"></img>
                            
                            <br/>
                            DirectConnect with TransitGateway (using DirectConnect Gateway)<br/>
                            - connect multiple regions to transit gateway (for each region one TGW) and route to DX Gateway and DX and route it to customer/on-prem<br/>
                            - can connect upto 3 centralized routers over a dedicated private connection<br/>
                            - each transit gateway acts as a hub to interconnect vpcs in that respective region<br/>
                            - can reduce network costs, increase bandwidth throughput, and provide a more consistent network experience than internet-based connections<br/>

                            <br/>
                            <img src="https://docs.aws.amazon.com/whitepapers/latest/aws-vpc-connectivity-options/images/image9.png"></img>

                            <br/>

                                
                                <br/>
                                
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>    
                    <div className="pad-top-10"></div>
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Concepts
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           
                            <AccordionItemPanel>
                            Border Gateway Protocol BGP: <br/>

                            BGP community tags on public prefixes that you advertise to amazon to indicate how far to propagate your prefixes in amazon network <br/>
                            - local region only, all regions within continent or all public regions<br/><br/>

                            7224:8100 local aws region. Routes that originate from the same AWS Region in which the AWS Direct Connect point of presence is associated.<br/>
                            7224:8200 continent. Routes that originate from the same continent with which the AWS Direct Connect point of presence is associated.<br/>
                            no tag    all public aws regions<br/><br/>

                            Public virtual interface BGP communities<br/>
                            AWS Direct Connect supports scope BGP community tags and the NO_EXPORT BGP community tag to help control the scope (Regional or global) and route preference of traffic on public virtual interfaces.<br/><br/>

                            Public virtual interface routing policies<br/>
                            - use ipv4 or v6 to advertise with BGP<br/>
                            - inbound rules and outbound rules apply (refer: https://docs.aws.amazon.com/directconnect/latest/UserGuide/routing-and-bgp.html)<br/>
                            - ex: AS_PATH and Longest Prefix Match is used for routing path, DX is preferred path for traffic sourced from amazon<br/>

                            <br/>
                            Scope BGP communities<br/>
                            7224:9100—Local AWS Region<br/>
                            7224:9200—All AWS Regions for a continent<br/>
                            7224:9300—Global (all public AWS Regions)<br/>
                            7224:1 – 7224:65535 are reserved by AWS Direct Connect.<br/>

                            <br/>
                            Local preference BGP communities<br/>
                            use local preference BGP community tags to achieve load balancing and route preference for incoming traffic to your network.<br/>

                            <br/>
                            The following local preference BGP community tags are supported:<br/>
                            7224:7100—Low preference<br/>
                            7224:7200—Medium preference<br/>
                            7224:7300—High preference<br/>
                            <br/>

                            NOTE: Local preference BGP community tags are evaluated before any AS_PATH attribute, <br/>
                            and are evaluated in order from lowest to highest preference (where highest preference is preferred).<br/>

                            <br/>
                            Bidirectional forwarding<br/>
                            - BFD is a networking fault detection protocol that provides fast failure detection times. <br/>
                            - configure BFD to detect link or path failures and upadte dynamic routing because DX quickly terminates BGP peering so that backup routes can activate<br/>
                            - async BFD is by default enabled on aws side. need to be configured on customer router side. <br/>
                            - DX default sets BFD liveness detection minimum interval to 300ms and BFD liveness detection multiplier to 3<br/>
                            - it does NOT provide resiliency to failover<br/>

                            <br/>
                            AS Path prepending<br/>
                            - using AS path advertised by the routers (primary and secondary), <br/>
                            - when two or more routes exist to reach a particular prefix, the default in BGP is to prefer the route with the shortest AS path.<br/>

                            <br/>
                            Active Passive Fail over<br/>
                            - local preference is used to prefer an exit point from local autonomous system (AS)<br/>
                            - if there are multiple exit points from the AS, the local pref attribute is used to select the exit point for a specific route<br/>
                            - advertise more specific prefixes over DX<br/>
                            - AS Path prepending . NOTE: If DX is using private AS, AS Path prepending is not supported. <br/><br/>

                            <br/>
                            Best practices using DX and VPN<br/>
                            - advertise using same static prefixes for DX and VPN will switch over to vpn if DX fails<br/>
                            - while using static VPN, use same static prefixes for DX interface and VPN <br/>
                            - DX is preferred over VPN if you advertise same routes toward VPC. NOTE: irrespective of AS Path prepending.<br/>
                            - use same VGW for both DX and VPN connection<br/>
                                
                                <br/>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>  
                      
                </div>              
            </div>
           
          </div>
      </div>
    );
  }
}
 
export default DirectConnect;