import React, { Component } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

class DynamoDB extends Component {

    openUrl(value){
        window.open(value, false);
      }
      
      
  render() {
    return (
      <div className="pad-top-30 pad-left-page">
          <div class="row">
            <div class="col-sm-7 col-md-5">
                  <div class="">
                    <h2>DynamoDB</h2>                    
                    
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                Amazon DynamoDB
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           
                            <AccordionItemPanel>
                                                
                                stored on SSD storage
                                <br/>
                                spread across 3 geographically distinct data centers
                                <br/>
                                - eventual read consistent - default
                                    <br/>
                                - consistently across all copies of data usually reached within a second
                                <br/>
                                - if app can wait for a second 
                                <br/>
                                - repeating a read after a short time shouuld return teh updated data (best read performance)
                                <br/>
                                - 
                                strongly consistent reads
                                - if it needs to be in sync all the time
                                    <br/>
                                - may not get read performance
                                <br/>
                                - returns result that reflects all writes that received a successful response prior to the read
                                <br/>
                                
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>    
                    <div className="pad-top-10"></div>
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                Environment
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           
                            <AccordionItemPanel>
                                
                            Tables
                            <br/>
                            - Items - similar to a row
                            - Attributes - similar to a column<br/>
                            
                            <br/>
                            Pricing
                            <br/>
                            - 
                            - provisioned through capacity
                            <br/>
                            - 
                            - write throughput - 0.0065 per hour for every 10 units
                            <br/>
                            - 
                            - read throughput - 0.0065 per hour for every 50 units
                            <br/>
                            
                            
                            <br/>
                            Indexes & Streams
                            <br/>
                            Primary Keys
                            <br/>
                            - 2 types of primary key
                            <br/>
                            * Single Attribute - similar to unique ID
                                <br/>
                            - Partition Key (Hash Key) composed of one Attribute
                            <br/>
                            <br/>
                            *Composite (unique ID and date range)
                                <br/>
                            - Partition Key & Sort Key (Hash & Range) - ex: user id and date range. user id as primary key and date range as sort range
                                <br/>
                            <br/><br/>
                            - 
                            Partition Key (Primary Key)<br/>
                            - dynamodb uses the partition keys value as input  to an internal hash function.
                            - cannot have same unique id<br/>
                            <br/>
                            - 
                            Partition Key & Sort Key (Primary Key & range)<br/>
                            - dynamodb uses the partition keys value as input  to an internal hash function<br/>
                            - two items can have same partition key but different sort key<br/>
                            - all items with same partition key but sorted by srt key<br/>
                            <br/>
                            
                            <br/>

                            Indexes<br/>
                            Local Secondary Indexes<br/>
                            - Has the SAME partition key, different sort key<br/>
                            - can ONLY be created when creating a table. cannot remove or modify later<br/><br/>

                            Global Secondary Indexes<br/>
                            - has DIFFERENT partition key and different  sort key<br/>
                            - can be created at table creation or added LATER<br/><br/>

                            Streams - May or may not feature in exams<br/>


                            - used to capture any kind of modification of the dynamo tables<br/>
                            ex:<br/>
                            1) if new item is added<br/>
                            2) if an item is updated<br/>
                            3) if an item is deleted<br/>
                            <br/>
                            generally stores for 24 hours. can write triggers like LAMBDA TRIGGERS and process the data. ex: send email (SES) etc.,<br/>

                            
                            <br/><br/><br/>
                            difference between query and a scan
                            <br/><br/>
                            Query<br/>
                            Query finds the data from a table using primary key. query results are sorted always by sort key in ascending order<br/>
                            ScanIndexForward - to reverse the order set the "ScanIndexForward" parameter to false<br/>
                            <br/><br/>
                            Scan<br/>
                            scan examines every item in the table. <br/>
                            by default scan returns all the data attributes for every item<br/>
                            Use "ProjectionExpresssion" parameter to return some of the attrinbutes instead of all<br/>
                            
                            <br/><br/>
                            Scan Vs Query<br/>
                            query is more efficient<br/>
                            scan scans entire table and filters out desired values by removing data from the resultset<br/>
                            if the table grows scan slows down<br/>
                            for better response times use Query with "Get", "BatchGetItem"<br/>

                            <br/><br/><br/>

                            DynamoDB allows Provisioned Throughput (reads and writes)<br/>

                            Read through put<br/>

                            X items Y kilobytes   -  Ex: 10 items, 6 KB<br/>

                            read - eventual  consistency = <br/>
                            1) Y ceil it to 4KB.   -  6KB ceil to 4KB is totally 8KB<br/>
                            2) divide by 4KB       -  8KB/4KB = 2KB<br/>
                            3) multiply by X       - 2KB multiplied by 10 = 20<br/>
                            4) Divide by 2         - divide by 2 = 10<br/>
                            <br/><br/>
                            read - strong consistency<br/>
                            1) Y ceil it to 4KB.   -  6KB ceil to 4KB is totally 8KB<br/>
                            2) divide by 4KB       -  8KB/4KB = 2KB<br/>
                            3) multiply by X       - 2KB multiplied by 10 = 20<br/>
                            4) DO NOT divide by 2<br/>

                            <br/><br/>
                            Write throughput<br/>
                            Simply multiply X items and Y kilobytes<br/>
                            <br/>
                            ex: 10 items and 6 KB = 60 KB write throughput<br/>

                            NOTE: After quota we will get 400 HTTP Status code - ProvisionedThroughputExceededException<br/>

                            <br/>

                            if you create a table and request 10 units of write capacity and 200 units of read capacity of provisioned throughput
                            how much would you be charged 
                            <br/>
                            write capacity units - 10<br/>
                            read capacity units - 200<br/>
                            <br/>
                            Answer - $0.01 + (4 x $0.01) = $0.05 per hour<br/>

                            <br/><br/>
                            web Identity providers with dynamodb
                            <br/>
                            like facebook, google or any open-id connect<br/>

                            1) autheinticate with identity providers (ex: facebook)<br/>
                            2) facebook returns web identity token<br/>
                            3) AssumeRoleWithWebIdentity API Request<br/>
                            4) send it to AWS with security token service<br/>
                            5) amazon will send back a temporary security credentials (default is 1 hour)<br/>
                            a) accesskeyid, secretaccesskey, sessiontoken<br/>
                            b) expiration - timelimit - 1 hour<br/>
                            c) assumeroleid<br/>
                            d) subjectfromwebidentitytoken - unique id appears in IAM policy<br/>
                            
                            <br/><br/>
                            
                            Other Key facts<br/>
                            
                            Conditional Writes<br/>
                            
                            since data is spread across 3 different data centers, if 2 user wants to update the same item which is 10$ (ex: 15$ or 6$ by another)<br/>
                            idempotent - conditional write makes sure it updates the data only if its 10$ (for first uer when he updates 15$ or for the second user when he updates to be 6$)<br/>
                            
                            <br/><br/>
                            Atomic Counters<br/>
                            **********<br/>
                            atomic counter updates are not idempotent. ex: website counter - web site hit counts can have a margin of error then atommic counters can be used<br/>
                            otherwise if its critical like voting etc conditional writes are the best<br/>

                            <br/>
                            Batch operations<br/>
                            --------------<br/>
                            BatchGetItem API - if we need to read multiple items.<br/>
                            a single batchgetitem request can get up to 1MB of data which can contain as many as 100 items. <br/>
                            in addition, a single batchgetitem request can retrieve items from multiple table<br/>
                                
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>  
                    <div className="pad-top-10"></div>
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                Indexes - Local secondary index, global secondary index
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           
                            <AccordionItemPanel>
                            LSI 
                            -  uses parent tables RCU and WCU<br/>
                            -  both SCR and ECR are supported<br/>
                            -  updating LSI will consume provisioned WCU<br/>

                            GSI <br/>
                            -  uses RCU & WCIs<br/>
                            -  only ECR supported. Max 8KB per RCU<br/>
                            -  add/update/delete of an item will consume provisioned WCU<br/>

                            <br/><br/>

                            <b>ProvisionedThroughPutExceededException</b> - 400 HTTP Status code <br/>
                            request that exceeded RCUs and/or WCUs<br/>
                            if GSIs are used, all GSIs must have enough WCU or else throttling will happen<br/>
                                
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>  
                    <div className="pad-top-10"></div>
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                DynamoDB Triggers 
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           
                            <AccordionItemPanel>
                            - execute custom action based on item-level updates on a dynamodb table
                            - ex: to send notification, updating an aggregate table, connecting dynamodb tables to other sources
                            - custom logic of trigger stores in lambda function.
                            a) associate a lambda function to the stream on a dyanomodb table<br/>
                            b) when the table is updated updaates are published to dynamodb streams<br/>
                            c) lambda reads the updaets from the associated stream and executes the code in teh function<br/>
                            - there is no limit to no of triggers for a table
                            - supported languages - Javascript, java, python
                            - no native apis to create, edit, delete triggers. use lambda
                            - deleting the associated lambda function deletes the trigger
                                
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>  
                    <div className="pad-top-10"></div>
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Cross region replication
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           
                            <AccordionItemPanel>
                            to maintain identical copies (replicas) of a dynamodb table (master table) in one or more AWS regions <br/>
                            <pre>
                            when to use  
                            a) disaster recovery
                            b) faster reads - for customer across multiple region<br/>
                            c) traffic management - using replicas to distribute load<br/>
                            d) regional migration<br/>
                            e) live data migration<br/>
                            can create multiple replicas for a single master table
                            replicas are updated asynchronously. There could be a slight delay before the data propagates to all replica tables.
                            creating replica table from replication : secondary index will NOT be automatically created on replica table
                            you will have to add/update/delete index on each of the replica tables using console
                            </pre>
                        
                        <br/>
                        <h3>
                            Developer Notes
                        </h3>
                        <p>
                            conditional operators can be used for a put, delete or update operation using "ConditionExpression". "KeyConditionExpression" parameter
                            <br/>
                                -  Boolean functions: ATTRIBUTE_EXIST, CONTAINS, and BEGINS_WITH<br/>
                                -  Comparison operators: =, BETWEEN, and IN etc<br/>
                                -  Logical operators: NOT, AND, and OR. <br/>
                            <br/>
                        </p>        
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>  
                    <div className="pad-top-10"></div>
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    API Calls
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           
                            <AccordionItemPanel>
                            - CreateTable – Creates a table and specifies the primary index used for data access.<br/>
                            - UpdateTable – Updates the provisioned throughput values for the given table.<br/>
                            - DeleteTable – Deletes a table.<br/>
                            - DescribeTable – Returns table size, status, and index information.<br/>
                            - ListTables – Returns a list of all tables associated with the current account and endpoint.<br/>
                            - PutItem – Creates a new item, or replaces an old item with a new item (including all the attributes). 
                                <br/>If an item already exists in the specified table with the same primary key, the new item completely replaces the existing item. 
                                <br/>You can also use conditional operators to replace an item only if its attribute values match certain conditions, 
                                <br/>or to insert a new item only if that item doesn’t already exist.
                                <br/>
                            - BatchWriteItem – Inserts, replaces, and deletes multiple items across multiple tables in a single request, but not as a single transaction. <br/>
                                <br/>Supports batches of up to 25 items to Put or Delete, with a maximum total request size of 16 MB.<br/>
                            - UpdateItem – Edits an existing item's attributes. You can also use conditional operators to perform an update only <br/>
                                <br/>if the items attribute values match certain conditions<br/>
                            - DeleteItem – Deletes a single item in a table by primary key. <br/>
                                    <br/>You can also use conditional operators to perform a delete an item only if the item’s attribute values match certain conditions.<br/>
                            - GetItem – The GetItem operation returns a set of Attributes for an item that matches the primary key. <br/>
                                <br/>The GetItem operation provides an eventually consistent read by default. <br/>
                                <br/>If eventually consistent reads are not acceptable for your application, use ConsistentRead.<br/>
                                <br/>"ProjectionExpression" - to get only specific fields<br/>
                            - BatchGetItem – The BatchGetItem operation returns the attributes for multiple items from multiple tables using their primary keys. <br/>
                                <br/>A single response has a size limit of 16 MB and returns a maximum of 100 items. Supports both strong and eventual consistency.<br/>
                                <br/>"ProjectionExpression" - to get only specific fields<br/>
                            - Query –  Gets one or more items using the table primary key, or from a secondary index using the index key. <br/>
                                <br/>You can narrow the scope of the query on a table by using comparison operators or expressions. <br/>
                                <br/>You can also filter the query results using filters on non-key attributes. <br/>
                                <br/>Supports both strong and eventual consistency. A single response has a size limit of 1 MB.<br/>
                                <br/>"ProjectionExpression" - to get only specific fields<br/>
                            - Scan – Gets all items and attributes by performing a full scan across the table or a secondary index. <br/>
                                <br/>You can limit the return set by specifying filters against one or more attributes.<br/>
                                <br/>By default, eventually consistent. you can modify the consistency model using the optional "ConsistentRead" parameter in the Scan API call<br/>
                                <br/>Limit of 1MB. after 1MB stops the operation and returns data with "LastEvaluatedKey" to apply in subsequent operation.<br/>
                                <br/>"ProjectionExpression" - to get only specific fields<br/>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>  
                      
                </div>              
            </div>
           
          </div>
      </div>
    );
  }
}
 
export default DynamoDB;