import React, { Component } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

class AzureBase extends Component {

    openUrl(value){
        window.open(value, false);
      }
      
      
  render() {
    return (
        <div className="pad-top-30 pad-left-page">
        <div class="row">
         <div class="col-sm-7 col-md-5">
               <div className="">
                 <h2>Microsoft Azure Fundamentals</h2>  <br/>      
                       
                   The idea for below was to document and reference purpose only. Please make sure to refer Microsoft Documentation and other materials.<b>There is no compression algorithm for experience!</b><br/><br/>
                   References:<br/>

                   - <a className="blogMenuText" onClick={() => this.openUrl('https://azure.microsoft.com/en-us/')}>Microsoft Azure</a><br/>
                   - <a className="blogMenuText" onClick={() => this.openUrl('https://portal.azure.com')}>Azure Portal</a><br/>
                   - <a className="blogMenuText" onClick={() => this.openUrl('https://learn.acloud.guru/course/az-900-microsoft-azure-fundamentals/dashboard')}>ACloudGuru Azure AZ-900 Fundamentals Course</a><br/>
                   - <a className="blogMenuText" onClick={() => this.openUrl('https://whizlabs.com')}> whizlabs </a> - The course video and labs are very good. Do make sure to do practice exam!<br/>
                   

                   <br/><br/>

                       <Accordion>
                           <AccordionSummary>
                              <b>Cloud Basics</b>
                              
                           </AccordionSummary>
                           <AccordionDetails>
                           <div>
                           Cloud <br/><br/>
                            
                            Private - services are offered only to selected users. complete control of infra. better security and policy<br/>
                            organizations IT is responsible for the cloud. MS is not responsible. similar to datacenter<br/>
                            private cloud is Azure on your own hardware in a location of your choice. all the benefits of public cloud, but you can lock it down. a lot of staff required<br/><br/>

                            Public - public cloud offerings like aws, azure, gcp etc. no purchase of hardware. low monthly/pay-to-go. can be accessed from anywher
                            no control (little control) of updates on hardware <br/>
                            public cloud - common way to deploy cloud computing. cloud resources are owned by cloud service provided and delivered over internet<br/>

                            hybrid - spans public and private. avoid disruptions and charge. alleviate capex investments
                            lot of complexity infrastructure
                            <br/><br/>
                            Why<br/><br/>
                            - fault tolerance - achieve high availability on the cloud<br/>
                            - elasticity/dynamic scalability - ensure that a service is scalabel even when the load the on the service increases<br/>
                            - DR - ensure that a service is running even in the event of catastrophic failure/loss<br/>
                            - low latency - can be accessed quickly/quick response times<br/>
                            - availability - highest sla 99.99% <br/>
                            hybrid cloud - infrastructure hosted in both azure public cloud and your on premises network<br/>
                            tenants  - in a public cloud you share same hardware, storage, network devices with other organizations <br/>
                            <br/><br/>
                            </div>

                           </AccordionDetails>
                       </Accordion>    
                       
                       <Accordion >
                           <AccordionSummary>
                               <b>Shared responsibility model and other common concepts</b>
                              
                           </AccordionSummary>
                           <AccordionDetails>
                               <div>
                               Service is the core of Azure. <br/><br/>
                                Iaas provides servers, storage, networking as a service<br/>
                                - use cases: lift and shift migration, test and development, storage backup recovery, web apps, high performance computing<br/>
                                PaaS is superset of IaaS. includes middleware such as database management tools. ex: Kubernetes, App Service<br/>
                                - use cases: development framework, analytics or business intelligence or additional services  <br/>
                                SaaS - services built on top of PaaS like office 365, Azure AAD, Azure Sql etc<br/>
                                Serverless - you dont manage servers. a single function can be hosted, deployed, run and manged on its own<br/><br/>

                                IAAS - servers and storage, Network & firewalls/security, Data center physical plant/building<br/>
                                PAAS - IaaS PLUS Dev tools, DB management, business analytics . Operating systems<br/>
                                SAAS - Hosted Applicaitons (apps) PLUS PAAS<br/>
                                <br/>
                                Shared Responsibility Model<br/><br/>
                                - Onprem - YOU everything<br/>
                                - IaaS - MS responsible for physical host <br/>
                                - PaaS - MS - physical infra, OS; YOU/MS - network controls, identity & directory<br/>
                                - SaaS - MS - physical infra, OS, network controls, identity & directory<br/><br/>


                                YOU/Customer responsible - App Mgmt, Info and Data, Devices and accounts<br/><br/>

                                Azure marketplace<br/><br/>
                                large selection of MS and partners. <br/>
                                Apps, VMs, templates, solution templates, SaaS applications, services and on. buy cloud services with a single click. <br/>
                                can be accessed using Merketplace website, cli, powershell<br/>
                                can provide publish your own <br/>
                                less maintenance . all offerings are certified by MS<br/>
                                market your solutions to new markets and users<br/>
                                get technical support from MS<br/><br/>



                                ##Common concepts<br/>
                                - Data transfers to Azure data center are free<br/>
                                link - https://azure.microsoft.com/en-us/pricing/details/bandwidth/<br/><br/>

                                - Azure free account - 30 days (free credit of 200$) after which users need to pay <br/>
                                link - https://azure.microsoft.com/en-us/free/free-account-faq/<br/><br/>

                                - Azure AD Plans - https://azure.microsoft.com/en-us/pricing/details/active-directory/<br/>
                                    - Azure Free<br/>
                                    - Office 365 plans<br/>
                                    - Premium P1<br/>
                                    - Premium P2<br/>

                                - Cost of a service (ex: VM, storage - azure blob) varies with region<br/>
                                - Azure SLA Service credits are calculated based on subscription basis are used to reimburse the customer<br/>
                                - removing user groups from azure ad will not reduce cost (while cleaning up unused resources)<br/>
                                - Service Ticket - beyond service limit (ex: to provision VM), increase the limit using a support ticket with microsoft<br/>
                                link - https://docs.microsoft.com/en-us/azure/azure-resource-manager/management/azure-subscription-service-limits<br/><br/>


                                Azure public previews<br/>
                                - can be accessed by azure cli/azure portal etc (not just cli)<br/>

<br/><br/>

                               </div>
                            </AccordionDetails>
                       </Accordion>    
                       <Accordion>
                           <AccordionSummary>
                              <b>All Services - High level</b>
                              
                           </AccordionSummary>
                           <AccordionDetails>
                               <div>                                        
                                Azure Cost Management - create budget and notification for the cost of azure resources <br/><br/>

                                Availability Zone - physical location within a region. AZs are specific to a region. these are combination of a fault domain and an update domain. ex: if we create 3 or more vms in 3 zones in an azure regions, vms are distributed across 3 fault domains and 3 update domains. azure offers 99.99% vm uptime sla. <br/>
                                AZs protect from complete azure data center failures<br/>
                                each AZ is made up of one or more datacenters equipped with independent power, cooling and networking within an azure region<br/>
                                NOT currently AZs are not available in all regions. check first on the availability of zones in a region<br/><br/>

                                Availability Set - an availability set consists of 2 or more VMs in the same physical location within an azure datacenter. only a subset of VM in an availability set gets affected in the event of hardware failure, OS update or a complete data center outage<br/><br/>

                                Regions - each azure region features datacenter deployed within a latency-defined parameter. <br/><br/>

                                Regional pair - consists of two regions within the same geography.  this ensures that only region is impacted in scenarios like updates, outages etc.,<br/><br/>

                                Azure Resource Groups - Grouping of resources together. helps to deploy and group them logically<br/>
                                NOTE: every resource has to be inside a resource group. how to allocate resources<br/>
                                each resource can exist in only one group. add or remove resource to a group<br/>
                                when you deploy a resource to a resource group with a set of permissions defined, the resource will inherit the permissions assigned to the resource group<br/>
                                ex: while deploying several identical vms, each of the vms to have same set of permissions to minimize administrative overhead<br/>
                                can move a resource from one to another. <br/>
                                resources from multiple regions can be in one resource group<br/>
                                can access control to entire resource group (resources within it)<br/>
                                resources can interact with each other<br/>
                                can be deleted if all the resources are not locked <br/><br/>

                                Azure Resource Manager (ARM) - to deploy resources to azure by defining dependencies between resourses so that they are deployed in correct order<br/>
                                - ARM templates for automated resource deployment<br/>
                                deploy management happens only through ARM (irrespective of cli/portal/web services anything)<br/>
                                can deploy manage and monitor for the group<br/>
                                can define dependencies between resources<br/>
                                can define who can use what<br/>
                                tagging can be done identify and billing<br/><br/>

                                AZURE RESOURCE GRAPH EXPLORER<br/>
                                when an account has multiple subscription and many correponding resources assigned to each subscription, resource graph explorer Query feature can be used to count/query<br/>
                                - ex: query the count of all key vaults across all subscriptions<br/><br/>



                                Management Groups - ability to manage user access to resources across multiple subscriptions<br/><br/>

                                Azure Storage Account - store data objects including blob, file shares, queues, tables and disks<br/><br/>
                                Similar to AWS S3<br/><br/>

                                Azure Service Bus - messaging system<br/>
                                Similar to AWS Events/rules<br/><br/>

                                Azure Monitor for logging and analytics. Azure Monitor  - get alerts. ex: cpu of vm beyond a threshold<br/>
                                Similar to AWS CloudWatch events/event bridge<br/><br/>

                                Azure App Service - PaaS to host web applications. Apps can "Scale Up" or "Scale Out"<br/>
                                Similar to AWS Elastic Bean Stalk<br/><br/>

                                Azure firewall - statefull firewall as service that protects azure virtual network resources. it cannot encryt all network traffic sent from azure to internet<br/>
                                you can configure NAT rules, network rules and application rules on Azure firewall<br/>
                                rule collectons are processed according to the rule type in priority order, lower numbers to higher numbers 100 to 65000<br/>
                                rule collection can have letters, numbers, underscores, periods or hyphen and a maximum of 80 characters<br/>
                                centrally create, enforce log application and network connectivity policies across subscriptions and virtual networks. <br/>
                                this is integrated with Azure Monitor for logging and analytics<br/><br/>

                                Azure Traffic Manager<br/>
                                DNS based traffic manager<br/>
                                this service allows to distribute traffic to your public facing applciations across the global azure regions<br/>
                                traffic manager provides your public endpoints with high availability and quick responsiveness<br/><br/>

                                Azure DDos Protection<br/>
                                - Basic  - free and active traffic monitoring with best effort support. NO metrics, NO alerts, NO mitigation reports or flow logs<br/>
                                - Standard - monthly and usage based pricing. <br/>
                                https://docs.microsoft.com/en-us/azure/ddos-protection/ddos-protection-overview<br/>

                                Similar to AWS DDoS protection<br/><br/>

                                Azure Advisor - recommendations on how to reduce cost of running azure vms like burstable, resizing, shutting down (underutilized instances) etc., advisor recommendations as owner, contributor or a reader of a subscription, resource group or resource<br/>
                                - Cost, operational excellence, performance, reliability (high availability), security<br/>
                                - Along with VM gives recommendation about application gateway, app services, availability sets, azure cache, azure data factory, azure database (for mysql/postgres, mariadb), azure expressroute, azure cosmos db, azure public ip address, azure synapse analytics, sql servers, storage accounts, traffic manager profiles<br/>
                                - can postpone or dismiss a recommendation<br/>
                                - Conditional Acces Policies - component of Azure AD to allow authentication based on condition (if-else) that must be met to either allow or deny access<br/>
                                - conditional access is the tool used by AD to bring signals together, to make decisions and enforce organizational policies. these are if-else statements. if  user wants to access a resource, then they must complete an action or meet a set of conditions<br/>

                                - https://docs.microsoft.com/en-us/azure/advisor/advisor-overview<br/>

                                Similar to AWS Trusted Advisor<br/><br/>

                                Platform logs<br/>
                                - Activity logs - insights into operations on each azure resource in subscription in addition to updates on service health events. <br/>
                                ex: what/who/when for any put/post/delete. single activity log for each azure subscription<br/>
                                - Resource logs - insights into operations that were performed within azure resoure. <br/>
                                ex: dataplane getting secret from key vault or a db request<br/>
                                - Azure Active Directory logs <br/><br/>

                                Similar to CloudWatch logs/events<br/><br/>


                                Microsoft trust enter - azure compliance with rules and regulations of the region for hosting resources. get required compliance reports<br/>
                                security, privacy, compliance offerings, policies, features and practices across microsoft cloud products<br/><br/>

                                Azure Devops - integration solution for deployment of code<br/>
                                Similar to AWS CodeBuild/Commit/Pipeline tools<br/><br/>

                                Azure security center - unified infrastructure security management system<br/>

                                Resource Group Lock - to prevent from accidental deletion of resources. you can lock a subscription, resource group or resource to prevent other users in  org from accidental deletion or modifying. Lock levels can be CanNotDelete or ReadOnly<br/>
                                note: locks dont apply to all types of operations. azure operatons can be into two categories. Control Plane or Data plane. Locks applies to only control plane operations<br/>
                                https://docs.microsoft.com/en-us/azure/azure-resource-manager/management/lock-resources?tabs=json<br/><br/>

                                Azure PowerShell - set of cmdlets for managing azure resources directly from powershell cli.  <br/>
                                in .net standard PS 5.1 version on windows works (with aditional modules). <br/>
                                for other platformms/OS require PS 6.2.4 is compatible. MS recommends PS 7.x<br/><br/>

                                Azure Kubernetes - managed service to manage and scale container based applications<br/>
                                Similar to AWS EKS<br/><br/>

                                Azure Logic Apps - automate and orchestrate tasks, business processes and workflows when you need to integrate apps, data, systems and service across enterprises or organizations.<br/>
                                Similar to AWS Step Functions<br/><br/>

                                Azure Synapse (SQL DW) - analytics service that brings enterprise data warehousing and big data analytics. <br/>
                                query data using serverless on demand or provisioned resources at scale. <br/>
                                ingest, prepare, manage and serve data for immediate bi and ml needs<br/>
                                - four components <br/>
                                - SQL Analytics, Spark (apache spark in Preview), Data integration (hybrid data integration in Preview) and Studio (UI - preview)<br/>
                                Similar to AWS Redshift <br/><br/>

                                Azure HDInsight - <br/>
                                to analyze streaming or historical data to create clusters, process and analyze big data <br/>
                                develop solutions using the frameworks like apache hadoop, spark, hive, LLAP, kafka, storm, MS machine learning server. <br/>
                                supports ETL, DW,ML and IOT<br/><br/>

                                Azure IoT hub - central message hub for bi-directional communication between your IoT application and the device it manages.<br/>
                                Similar to AWS IoT<br/><br/>

                                --------------------<br/>

                                SECURITY<br/><br/>

                                NETWORK ROUTES - USER DEFINED ROUTES (UDR)<br/><br/>
                                Create routes in azure to override azure's default system routss or to add additional routes to a subnet's route tables<br/>
                                create a "route table" then associate the route table to zero or more virtual network subnets.<br/>
                                Each subnet can have zero or one route table associated to it.<br/>
                                Once associated to subnet, the routes within it are combined with or override the default routes azure adds to a subnet buy default<br/>
                                UDR can be created without creating subnet<br/><br/>

                                Network security groups (NSG) -  <br/>
                                contains security rules that allow or deny inbound network traffic to or outbound network traffic from several types of azure resources. <br/>
                                you can filter network traffic using this. for each rule specify resource (from), destination (to), port and protocol<br/>
                                Similar to AWS Security Groups<br/><br/>

                                Application Security Groups (ASG) - <br/>
                                at application level to allow a/group of VMs and define network security policies on those groups<br/>
                                ASGs let customers group multiple vms, from which you can apply an NSG to the group, which then applies the policies to all machines in that group<br/><br/>

                                Azure Identity access and management - process of authentication and authorization of security principals<br/>
                                Similar to AWS IAM<br/><br/>

                                Azure AD - identity management, <br/><br/>

                                Azure Privileged Identity Management - <br/>
                                it is a service in Azure Active Directory (AAD) that enables you to manage, control and monitor access to important resources in the organization<br/>
                                time based approval based role activation. <br/>
                                mitigates the risk of excessive/unnecessary/mis used access permissions. <br/>
                                "just-in-time", "time-bound", "approval", "MFA", "justification", "notifications"<br/><br/>


                                -----------------------<br/><br/>

                                NETWORK<br/><br/>

                                Azure Virtual Network (VNet) - fundamental building block for private network in Azure.<br/>
                                enables vms to securely communicate with internet/on premises etc., physical hardware is abstracted<br/>
                                Address Space - range of ip addresses. IP addresses unique address for a service/resource on the VNet<br/>
                                assign address space to the resource. ip addresses<br/><br/>

                                subnet - <br/>
                                smaller network which is part of vnet. allocates a portion of ipaddress/space within the network to group specific subnets<br/>
                                network security group - security <br/><br/>

                                vnet is specific to a region and a single subscription. internal subnets/resources belong to one region<br/>
                                scaling - adding more vnets or more addresses are simple<br/>
                                vnets can be peered (through load balancer or vpn gateway)<br/>
                                isolate services within resources in vnet<br/><br/>

                                VNet Peering - connect to vnets in azure. uses internal network and doesnt traverse through network<br/>
                                low latency, high bandwdith link separate networks. data transfer between subscriptions and deployment models in separate regions<br/>
                                ex: all vnets for a company can be peered<br/><br/>

                                Similar to AWS VPC<br/><br/>

                                Load Balancer - distributes new inbound flows that arrive on the load balancer's frontend to backend pool instances according to rules and health probes<br/>
                                LB makes sure to send traffic to healthy vm<br/>
                                LB between internal traffic, internal network, port forwarding (to a specific machine or process), outbound connectivity for backend pool<br/>
                                LB to route traffic to more than one VM<br/>
                                traditional load balancers operate at OSI layer 4 TCP and UDP<br/>
                                Route traffic based on source IP address and port to a destination ip and port. <br/>
                                LB distributes new inbound internet traffic that arives on LBs front end to back end pool instances according to rules and health probes<br/><br/>

                                Vnet gateway - Similar to AWS Virtual Gateway (on the aws vpc router edge)<br/><br/>

                                Virtual Network Gateway or VPN Gateway <br/>
                                - composed of two or more vms deployed to specific subnet called as gateway subnet, subnet is a dedicated part of vnet. <br/>
                                specific type of VPN gateway where vnet gateway plus vpn becomes vpn gateway<br/>
                                provide secure connectivity across premises connectivity with on-pre and azure<br/>
                                sends encrypted between azure to local on premises network<br/>
                                vpn gateway allows you to establish an IPSec tunnel from an on-premises network to an azure virtual network over the internet<br/>
                                This is a VPN object at azure end of the vpn. a connection is what connects local network gateway and virtual network gateway to bring up the vpn<br/>
                                Similar to AWS Virtual Gateway to connect on premises servers with azure<br/><br/>

                                main components<br/>
                                Site to site connection<br/>
                                - azure vnet with Azure VPN Gateway attached which has its own public ip adress<br/>
                                - secure connection using tunnel - with encruption<br/>
                                - on premise - complementary gateway that accepts input data<br/><br/>

                                Application Gateway - <br/>
                                application gateway is similar to load balancer but can redirect traffic based on attributes in the http request (layer 7), the request coming in from the internet. <br/>
                                application gateway do not handle traffic security nor manage any virtual networks<br/>
                                it is like a loadbalancer on the cloud for application routing<br/>
                                works on http request of the traffic instead of ip address and port<br/>
                                internet - to http request to LB - to backend pool<br/>
                                - web traffic load balancer that enables you to manage traffic to your web applications. <br/>
                                - they are aware of the much more abstract high-level layer 7 in the OSI model, meaning the load balancer understands http headers and can do things such as routing requests to different places (path based) based on the url of web request.<br/>
                                data format uri=web address, wth host header/request data<br/>
                                - can scale up or down based on traffic (price varies)<br/>
                                - end to end for encryption. can disable secure transfer to backend pool<br/>
                                - zone redundancy - spans multiple az<br/>
                                - multi-site hosting - same app gateway for upto 100 websites<br/><br/>


                                Azure Content Delivery Network - <br/>
                                distributed network of servers that can deliver web content to users. <br/>
                                CDNs store cached content on edge servers in point of presence (POP) locations that are close to end users to minimize latency<br/>
                                Similar to AWS CloudFront<br/>
                                origin server - master copy of the files on the source server<br/>
                                better performance, scaling, distribution. <br/>
                                cache - collection of temporary copies of original files to speed faster response<br/>
                                caching and data invalidations happens on edge node. data expiration can be set to get new cached version<br/><br/>


                                ExpressRoute - direct link data to connect on prem and azure. high available, high fast/low latency, secure direct connection<br/>
                                Similar to AWS Direct Connect. the data does not go through internet. <br/><br/>

                                local network gateway - refers to on-premises location. used to represent a vpn device in a site to site vpn connection<br/>
                                this is an object in azure that represents customer's on-premise VPN device<br/>
                                This is typically a reference to on-premise vpn device. <br/>
                                you also specify IP address prefixes that will be routed through the vpn gateway to the vpn device<br/>
                                the address prefixes you specify are located on the on-prem network<br/>
                                if your on-premises network changes or you need to change the public ip address for the vpn device this can be updated<br/>
                                Similar to AWS Customer Gateway<br/><br/>

                                ----------------------------<br/><br/>


                                Compute
                                - Virtual Machines<br/><br/>
                                - Scale sets<br/>
                                - App Services<br/>
                                - Azure Container Instances<br/>
                                - Azure K8s<br/>
                                - Windows Virtual Desktop<br/>
                                - Functions<br/><br/>


                                Compute Options - <br/>
                                - VMs, App Service for hosting web apps, <br/>
                                - Service Fabric - distributed systems platform that can run in many environments (orchestrator of microservices), <br/>
                                - AKS, <br/>
                                - ACI Azure Container Instances - run containers in azure without provisioning any virtual machines<br/>
                                - Azure functions, Azure batch, <br/>
                                - Cloud Services - managed service for running cloud applications it uses PaaS hosting model<br/><br/>

                                Virtual Machines - server in cloud. hardware shared. virtualization is a common practice. it is an IaaS <br/>
                                blueprints/templates can be used to create vms at scale. can run windows or linux<br/>
                                pricing - calculated hourly. based on cpu/processing etc.,<br/><br/>
                                Similar to AWS EC2<br/><br/>

                                Scale Sets -  a group of identical, load balanced VMs<br/>
                                as if like single vm as baseline and clones a bunch of vms on scale<br/>
                                high availability, redundancy easy to manage multiple vms for consistency, auto scaling, large scale<br/>
                                scale set by itself does not have cost. vms spun up due to which will have<br/>
                                scale set can be configured across multiple data centers. otherwise it may not handle a single data center failures<br/>
                                Number of VMs can increase or decrease in response to demand or a defined schedule. vms can be deployed to across multiple update domains and fault domains  <br/>
                                Similar to EC2 auto scaling<br/><br/>

                                App Services - PaaS. fully managed platform - service/network and other fundamental parts are all managed by azure<br/>
                                ex: web apps (on linux or windows). .net, php, jave, node.js,python, ruby. <br/>
                                integrate with other deployment and auto scaling<br/>
                                app service is like a web site by itself. the name is unique across all azure (not just limited to your account)<br/>
                                Similar to AWS Elastic Bean Stalk. simple upload your code<br/><br/>


                                Azure Servcce Bus<br/>
                                fully managed enterprise integration message broker. to decouple applications and services from each other<br/>
                                messages are binary format containing json/xml or text which provides reliable and secure platform for asynchronous data and state transfer<br/><br/>

                                Web Apps for containers - deploy and run containerized applications in azure. <br/>
                                a container is a completely self contained. all dependencies are shipped inside the container<br/><br/>

                                API Apps - expose and connect your backend. <br/>
                                these are software interface without no UI. backend/api layer using programming languages like C#, java etc.,<br/><br/>

                                Azure Container Instances (ACI) - <br/>
                                primary azure service for running container workloads (apps or process)<br/>
                                common standard where software can be shipped (ex: in a VM). <br/>
                                comes with required interaces and dependencies (framework, OS interfaces etc)<br/>
                                - can manage app dependencies. all included as needed<br/>
                                - less overhead. no OS components. increases portability<br/>
                                - efficiency, scaling and patching is simpler<br/>
                                - consistency<br/>
                                - can be done with portal, cli or powershell<br/><br/>


                                Azure Kubernetes service (AKS) - manage containers at scale<br/>
                                Kubernetes or k8s - greek for "governor" or "captain"<br/>
                                open source container orchestration system for automating application deployment, scaling and management<br/>
                                replicate container architectures, standard azure services included (ex: infrastructure, IAM, elastic provisioning, integration with IDE)<br/>
                                similar to AWS EKS<br/><br/>

                                AKS Cluster has nodes (servers), pod has containers that runs the application.<br/><br/>

                                Azure Container Registry (ACR) - manages container images artifacts<br/>
                                azure identity and security features can be used<br/>
                                similar to AWS ECR<br/><br/>

                                Azure Virtual Desktop - windows virtual Desktop on azure. access from any device with modern web client<br/>
                                reuse existing windows 10 license. multiple users can run concurrently<br/>
                                run from anywhere almost from any device<br/>
                                use azure storage to secure your data<br/>
                                mfa, rbac, azure policies and all security concepts are integrated<br/><br/>

                                Azure Functions (Serverless) - smallest compute service on azure. a single function/action that happens/runs on azure<br/>
                                can connect with other services. called using an url. run once and stops<br/>
                                underlying vm/resource takes care of it. YOU/customer need not worry how it is being managed<br/>
                                no need to provision resource before. runs only when needed<br/>
                                similar to AWS Lambda<br/><br/>



                                ----------------------<br/><br/>

                                STORAGE<br/><br/>

                                - four main store ways<br/>
                                NOTE: Storage Account is the base for all Azure storage types.<br/><br/>

                                - BLOB  <br/>
                                - DISK <br/>
                                - FILE <br/>
                                - ARCHIVE <br/><br/>


                                Stogate Account - unique namespace for your data. this has a unique web address<br/>
                                - Storage account = unique azure namespace (every object in azure has its own web address). <br/>
                                - Base of all azure storage types<br/>
                                - Network Routing - can be microsoft network routing or internet routing. to route your traffic as it travels from the source to its azure endpoint<br/>
                                - ex: codeandtech.[storage type].core.windows.net<br/><br/>

                                Azure Storage Redundancy Types<br/>
                                LRS - locally redundant storage<br/>
                                ZRS - Zone<br/>
                                RA-GZRS - Read access geo zone redundant storage<br/><br/>

                                Redundancy types - NOTE*<br/>
                                locally redundant storage (LRS) - lowest cost /basic protection against server rack/drive failures. non critical scenarios<br/>
                                geo redundant storage (GRS) - intermediate option with failover capabilities in secondary region. recommended for backup scenarios<br/>
                                zone redundant storage (ZRS) - intermediate option with protection against datacenter level failures. recommended for high availability scenarios<br/>
                                Geo zone redundant storage (GZRS) - optimal data protection solution that includes offering of both GRS and ZRS. recommended for critical data scenarios<br/><br/>

                                AZURE FILES<br/>
                                uses SMB shares within a storage account that acts as a NFS (network file server)<br/>
                                create a storage account, create an azure file share on the storage account. azure files are hosted inside of a storage account<br/><br/>

                                BLOB - binary large object objects (like an item). these are stored as containers<br/>
                                blob containers are mixed bag of items. ex: images, files, streaming videos, audios, log files, backup/restore<br/>
                                three layers - storage accounts can have multiple containers containing blobs<br/><br/>

                                Blob Types<br/>
                                Blob storage containers are suitable for unstructured data<br/>
                                Block - store text upto 4.7TB . made of individually managed blobs of data<br/>
                                Append - block blobs that are optimized for append operations. ex: logging where data is constantly appended<br/>
                                Page - store files upto 8TB. any part of the file can be accessed any time. similar to a virtual hard drive (vhd file)<br/><br/>

                                Pricing Tiers<br/>
                                Hot - frequently accessed files. lower access times. higher access costs<br/>
                                Cool - lower storage costs, higher  access time. data remain at least for 30 days<br/>
                                Archive - lower costs. highest accessing times<br/><br/>


                                DISK Storage<br/>
                                - disk that can store data<br/>
                                - Managed disk is attached to VMs. azure will look for this and backups/restores etc.,<br/>
                                - can be easily upgraded or resized<br/><br/>

                                Types<br/>
                                - HDD - spinning hard drive. can be used for infrequent access<br/>
                                - Standard SSD - production env. higher reliability, scalability and lower latency<br/>
                                - Premium SSD - DB/lower latency workloads. super fast and very high performance<br/>
                                - Ultra Disk - for the most demanding data intensive workloads. disks upto 64TB. transaction heavy, gaming, low queue depth databases<br/><br/>

                                FILE Storage<br/>
                                - use case - file store across on premises to mitigate constraints, backups, security, file sharing<br/>
                                - share across on premises/azure, fully managed, resilient (against network/power outages etc.,)<br/>
                                - can use hybrid - supplement or replace your existing on premises storage solution<br/><br/>

                                ARCHIVE Storage<br/>
                                - archivable for compliance, legislation etc.,<br/>
                                - lowest price. can store TBs of data. higher time to retrieve data<br/>
                                - can free up old data and repurpose storage. <br/>
                                - securely stored. it is a blob storage tiering.<br/><br/>


                                ---------------------------------------------------<br/>

                                AZURE EVENT HUBS<br/>
                                Big data streaming platform and event ingestion service<br/>
                                can receive and process millions of events per second<br/>
                                data can be transformed and stored by using any real-time analytics provider or batching/storage adapters<br/>
                                uses a partitioned customer model, enabling multiple applications to process the stream concurrently and letting you control the speed of processing<br/>
                                can capture in near real-time in an azure blob storage or azure data lake storage(for long term retention or micro batch processing)<br/>
                                Similar to AWS Kinesis streams/firehose<br/><br/>
                                ----------------------------------------------------<br/>

                                DATABASES<br/><br/>

                                - Cosmos DB - globally scaled. fast reads/write<br/>
                                - Azure SQL<br/>
                                - MySQL<br/>
                                - PostgreSQL<br/>
                                - Database Migration Service<br/><br/>

                                Cosmos DB<br/>
                                - global database. single digit latency for multiple platforms of DB<br/>
                                - ex: Core SQL, Azure Cosmos DB api for mongodb, Cassandra, Gremlin (Graph), Azure Table<br/>
                                - No schema definitions required<br/>
                                - synchronization - easy with cosmos.<br/>  
                                - one click to add regions. very easy to expand to more regions. <br/>
                                - continuous synchronization<br/>
                                - scalability - cosmos db automatically scales, any no of users (infinite reousrce) can be used. pay per use<br/>
                                - can use SDKs, APIs.  <br/>
                                - can use different programming languages (c#, java, node)<br/>
                                - platforms - lots of data platforms to integrate including sql, mongodb and cassandra<br/>
                                - NOTE: can be VERY expensive. <br/><br/>

                                Azure SQL - managed microsoft SQL server<br/>
                                - DB as a service. managed service<br/>
                                - azure takes care of infrastructure, hardware etc.,<br/>
                                - usecases - migrate on-prem instances to azure, lower cost of ownership (TCO)<br/>
                                - built in machine learnings - optimization, warnings<br/>
                                - scalability, space (manage upto 100TB), secure<br/><br/>

                                SQL database vs SQL managed instance<br/>
                                Azure SQL Database<br/>
                                - more like traditional database<br/>
                                - automatic tuning<br/>
                                - geo replication<br/>
                                - autoscale with serverless models<br/>
                                - no sql profiler<br/>
                                - no sql server agent<br/>
                                - elastic pool - SQL Database elastic pools are a simple cost effective solution for managing and scaling multiple databases that have varying and unpredictable usage demands. the databases in an elastic pool are on a single azure sql database server and share a set number of resources at a set price.<br/><br/>

                                Azure managed SQL instance<br/>
                                - aimed at migrating from on-premises <br/>
                                - no automatic tuning<br/>
                                - no geo replication<br/>
                                - no autoscaling<br/>
                                - has sql profiler<br/>
                                - has sql server agent<br/><br/>

                                Azure Database for MySQL<br/>
                                - open source project <br/>
                                - relational database<br/>
                                - mature and stable<br/>
                                - PaaS -  no hardware, infrastructure maintenance. focus on core business processes<br/>
                                - ex usecase: wordpress using php and mysql<br/>
                                - use cases - web applications, e-commerce, mobile apps, digital marketing, finance management, gaming applications<br/><br/>

                                Azure Database for PostgreSQL<br/>
                                - open source postgreSQL - relational Database<br/>
                                - integration with others using jsonb, geospatial functions, integration with tools like visual studio<br/>
                                - horizontal scaling, performance recommendations, fully managed highly redundant <br/>
                                - use cases - financial industries, government for GIS (geometric data like PostGIS), manufacturing<br/><br/>

                                Data Migration Services<br/>
                                - migrate from on-prem to Azure. single tool - one step migration for MS Sql<br/>
                                - documentation available to migrate non MS sqls to MS also available<br/><br/>

                                Microsoft Intune<br/>
                                Microsoft Intune is a cloud-based service that focuses on mobile device management (MDM) and mobile application management (MAM). <br/>
                                You control how your organization’s devices are used, including mobile phones, tablets, and laptops. <br/>
                                You can also configure specific policies to control applications. <br/>
                                For example, you can prevent emails from being sent to people outside your organization. <br/><br/>

                                Microsoft Cloud Adoption Framework <br/>
                                CAF is guidance that's designed to help you create and implement business and technology strategies for the cloud. <br/>
                                It provides best practices, documentation, and tools. <br/>
                                Cloud architects, IT professionals, and business decision makers use this information to achieve their cloud adoption goals<br/>
                                - Define Strategy - Plan - Ready - Adopt - Govern - Manage<br/><br/>

                                </div>             
                               </AccordionDetails>
                       </Accordion>    
                       
                       <Accordion >
                           <AccordionSummary>
                               <b>Azure Security</b>
                              
                           </AccordionSummary>
                           <AccordionDetails>
                               <div>
                               SECURITY<br/><br/>

                                Defense in Depth - how azure secures resources<br/>
                                securing networking connectivity - entire or specific parts<br/>
                                azure security center - how it helps guids<br/>
                                azure key vault - secrets passwords. also with hardware<br/>
                                Azure informaiton protection - emails, documents and data secure. even outside of azure<br/>
                                Azure Sentinel - AI enabled to fight security breaches<br/>
                                Azure dedicated hosts - hardware for some control on vms<br/>
                                Advanced Thread Protection - suspicious activity users<br/><br/>

                                Defense In Depth<br/>
                                - 7 general layers in computing<br/>
                                - physical - actual datacenters<br/>
                                - identity and access - users/connections, authentication and authorization<br/>
                                - perimeter - ddos, protocol attacks etc.,<br/>
                                - network - filter of traffic, virtual network standards<br/>
                                - compute - <br/>
                                - application gateways and firewalls<br/>
                                - data - encryption protection<br/><br/>

                                Securing Network connectivity<br/>
                                - firewall - set of rules to allow/deny traffic. <br/>
                                - DDoS - flooding with requests per seconds and block the core service<br/>
                                - lots of computers and other connected devices target a single website. ex: 127M requests per second ongithub <br/>
                                - azure has DDoS protection.<br/>
                                - no downtime<br/>
                                - NSG - Network Security Groups<br/>
                                - personal resource firewall<br/>
                                - attach to VNet, subnet or network interface<br/>
                                - NSG determines who can access the resources attached to it using inbound/outbound rules<br/>
                                - can be additional security on top of VNet with firewall on VMs<br/>
                                - always include default securty rules. they can be deprioritized but cannot be deleted. <br/>
                                - NSGs can be used to limit the traffic between VMs in same subnet and they are not/do not perform the same functions as that of Route tables. <br/>
                                - they dont need to be attached to each subnets<br/><br/>

                                - ASG - Application Service Group  <br/>
                                - will protect an Application instead of just IP address<br/>
                                - could be an Azure function or grouped vms/logical vnet into logical application Groups<br/><br/>


                                AZURE SECURITY CENTER<br/><br/>

                                portal within azure portal. <br/>
                                shows threat alerts, can be used for hybrid, monitor each vms based on agents sending data whcih are validated by azure for attacks<br/>
                                policy and compliance are monitored<br/>
                                get secure score to make azure implemetation is secure<br/>
                                integrate with other cloud providers - can get security information from other cloud providers like AWS also<br/>
                                security center supports vms/server on different types of hybrid environment; only azure, azure and on premises, azure and other clouds, azure onpreises, other cloud<br/><br/>

                                three step process<br/>
                                - define security policy to monitor - these are set of rules to validate the configuration of your services<br/>
                                - protect your services through monitoring<br/>
                                - respond to security alerts. <br/><br/>

                                Regulatory Compliance Dashboard<br/>
                                - azure security center keeps track of compliance in this Dashboard<br/>
                                - ex: Network security, identity management, privileged access, <br/>
                                - ex: data protection, asset management, logging and threat detection, <br/>
                                - ex: incident response, posture and vulnerability management, <br/>
                                - ex: endpoint security, backup and recovery, governance and strategy<br/><br/>

                                Resource security Hygiene<br/>
                                - how resources are configured to handle security best practices<br/>
                                - ex: encryption on the disk<br/>
                                - ex: Enable MFA for accoubts, remove external accounts, install a vulnerability assesment,<br/>
                                - ex:  Enable security NSG on subnets, apply just in time network access control, <br/>
                                - ex: apply disk encryption on your virtual machines<br/><br/>

                                AZURE KEY VAULT<br/>
                                Azure key vaults may be either software or hardware (HSM) protected. <br/>
                                you can import or generate keys in hsms that shall never the hsm boundary.<br/>
                                microsoft uses nCipher hardware security modules. you can use nCipher tools to move a key from your hsm to azure key vault<br/>
                                - securely store, share and grant access to secrets<br/>
                                - management of keys and securing them encrypted, rotations etc<br/>
                                - secure place to store passwords, other secrets etc.,<br/>
                                - secure hardware, application isolation, scaling<br/>
                                - you can securely store certificates also <br/><br/>

                                AZURE INFORMATION PROTECTION (AIP)<br/>
                                Azure Information Protection helps secure email, documents, and sensitive data inside and outside your company walls. <br/>
                                You can classify sensitive data, track activities on shared files and documents, collaborate securely and much more. <br/>
                                classify, protect documents and emails by applying labels. <br/>
                                labels can be applied automatically by administrators who define rules and conditions or manually by users or a combination where users are given recommendations<br/>
                                Need specific roles that would enable AIP to users - Information Protection Administrator, Security Administrator. <br/>
                                Note: Cloud Application Administrator, Group Administrators (access to admin features) cannot enable access to AIP<br/>
                                There are no active security service included, such as scanning the files being protected<br/>
                                - to access/secure emails, documents, data outside or to others<br/>
                                - works in conjunction with office 365.<br/>
                                - classify data according to its sensitity based on policies, <br/>
                                - track activities <br/>
                                - share data - who can edit/view/print/forward etc.,<br/>
                                - integration - controls for document with other common tools <br/><br/>


                                MICROSOFT DEFENDER FOR IDENTITY <br/>
                                cloud based security solution that leverages on-premises AD sigals to identify, detect and investigate advanced threats, compromised identities and malicious insider actions<br/>
                                - cloud based security solution that works with on-premises active directory to detect threats<br/>
                                - to identify, detect and investigate advanced threats, compromised identities and malicious insider actions directed at your organization<br/>
                                - users can be unreliable<br/>
                                - monitor users - analyze user activity and information. including permission and memberships for groups<br/>
                                - baseline behavior - record what a normal behavior or routine is. any activity outside this routine will be logged as suspicious<br/>
                                - Suggest changes - suggesting best practices<br/>
                                - Use case - implement a security solution for on premises AD users and monitor user login/logout, monitor AD user account charges, monitor machine account etc.,<br/>
                                - prevent suspicious attacks and threats to resources<br/><br/>



                                Cyber attack kill chain - ways attackers can get into a system<br/>
                                - reconnaissance - if an user is searching for information about other users, device ip addresses etc., MS defender for identity will raise alerts<br/>
                                - Brute Force - any attempts to guess user credentials will be identified and flagged<br/>
                                - Increasing privileges - trying to gain more privileges <br/><br/>

                                AZURE SENTINEL - SIEM tool<br/>
                                Azure Sentinel will collate, aggregate, and analyze data from multiple Azure services to detect any unusual behavior or patterns. <br/>
                                You can then take action on the information.<br/>
                                - security information event management<br/>
                                - Behavioral Analytics - using AI to learn anomolies<br/>
                                - AWS integration - threat detection across multiple cloud <br/>
                                - Cloud scale - speed<br/>
                                - Steps how sentinel processes/tracks threats<br/>
                                - 1) data collected from vms, dns, traffic managers etc<br/>
                                - 2) data is aggregated, sorted<br/>
                                - 3) data is analyzed for threats<br/>
                                - 4) processing<br/>
                                - 5) steps to take action<br/><br/>


                                AZURE DEDICATED HOSTS<br/>
                                - entire physical server on azure. similar to vm on azure. <br/>
                                - hardware isolation is at physical level. no vms are placed. reduce impact to maintenance<br/>
                                - compliance, global infrastructure - AZ, fault isolation, High availability, scale sets, no optional extras<br/>
                                - Operating Systems - windows or linux. SQL Server on a range of VM sizes<br/>
                                <br/>

                                <br/>
                               </div>
                           
                                   
                               </AccordionDetails>
                       </Accordion>    
                       <Accordion>
                           <AccordionSummary>
                              <b>Active Directory and Subscriptions</b>
                              
                           </AccordionSummary>
                           <AccordionDetails>
                               <div>
                               Identity Management<br/><br/>

                            - Authentication - who can. Username/password<br/>
                            - confirming identity<br/>
                            - Authorization - what<br/>
                            - after authentication<br/>
                            - is this identity allowed access<br/>
                            - granular part of access<br/><br/>
                            

                            Azure Active Directory (AAD)<br/><br/>

                            - tool to manage user information and permission<br/>
                            - every Azure account will have an AAD service<br/>
                            - Every azure account has to have an account which is in AAD instance<br/>
                            - onmicrosoft.com - is the initial domain where the tenants are created (ex: when moving employee profiles into AAD)<br/>
                            - Traditional Active Directory - was designed for traditional office use with computers/printers etc.,<br/>
                            - this was designed initially not for services on the web<br/>
                            - This is not the same that of Azure Active Directory<br/>

                            Azure AD Connect<br/>
                            - Azure AD Connect is able to synchronize your on premises and Azure AD identities to manage both in a single location. <br/>
                            - you install software on the on-premises server/agents <br/><br/>


                            Azure AD Identity Protection<br/>
                            - helps in identifying vulerabilities related to identity<br/>
                            - some examples policies that can be implemented<br/>
                            - User risk policy - used if the credentials are compromised<br/>
                            - sign in risk policy - suspicious sign in / multiple attempts etc.,<br/>
                                - use case - encourage users from unindentified IP addreses to change passwords<br/><br/>
                                
                            Azure AD Identity Secure score<br/>
                            - helps in identifying the identity score (identify current identity practices) to provide recommendations<br/>
                            - identity secure score is between 1 and 223 that indicates how we are aligned to microsoft's best practice recommendations.<br/>
                            - each improvement action will be tailored to your specific configuration<br/><br/>


                            Tenant - represents the organization<br/>
                            this is a dedicated instance of AAD that an organization receives when signing up for azure<br/>
                            each tenant are distinct and separate<br/>
                            A user can be a member or guest of upto 500 AD tenants<br/><br/>


                            Subscription - Billing entities that controls the cost of resources and services associated with it<br/>
                            subscription helps to separate tenants/separate costs<br/>
                            if a subscription is not paid all the subscription and services associated with the subscription stop<br/><br/>
                            
                            Hybrid Cloud Architecture<br/>
                            - Some services are on-prem and others in Azure<br/>
                            - AAD helps to manage users between these<br/><br/>
                            
                            
                            Multi-Factor Authentication (MFA)<br/>
                            - extra layer of security<br/>
                            - two ways/forms to identify and login<br/>
                            - generally a public (username)/password with finger print/device tokens etc<br/><br/>


                            Single Sign-On<br/>
                            - one common username (email)/password) for multiple services<br/>
                            - ex: outlook. azure, skype etc., all with same email<br/>
                            - Azure SSO can be created using "Azure Active Directory Seamless Single  Sign-On"<br/>

                                    <br/>
                                </div>  
                               </AccordionDetails>
                       </Accordion>    
                       
                       <Accordion >
                           <AccordionSummary>
                               <b>Governance, Privacy, Compliance and Trust</b>
                               <br/>
                           </AccordionSummary>
                           <AccordionDetails>
                               <div>
                               Privacy, compliance and trust<br/><br/>

                                - Governance - govern instances<br/>
                                - Azure Monitor - detect, diagnose and analyze data for logs/metrics<br/>
                                - Azure Service health - azure health up time etc.,<br/>
                                - compliance - <br/>
                                - privacy - <br/>
                                - trust - <br/><br/>

                                Governance<br/>
                                - set of rules,policies, processes, permissions etc.,<br/><br/>

                                Azure Policy<br/>
                                Azure policy ensures policies applied to the resources are compliant<br/>
                                a policy is set of rules to ensure compliance resources<br/><br/>

                                Initiative definition<br/>
                                collection of policy definitions that are tailored towards achieving a single overaching goal.<br/>
                                helps to simplify manage and assign policy definition. group a set of policies also one single item<br/><br/>

                                RBAC - role based access control<br/>
                                Role-based access control (RBAC) helps you manage who has access to Azure resources, what they can do with those resources, and what areas they have access to. You have very detailed control of resource actions and you assign roles to users to let them take those actions. You can define as many roles as you wish in RBAC.<br/>
                                ensures user compliance through assigning a role to an user. <br/>
                                a role is a combination of security principal, role definition and scope<br/>
                                which user for specific resources. what specific areas the users can have access<br/><br/>

                                role assignment process - rbac has three portions <br/>
                                security principal - rbac using role assignment -  (what entity for resource like user or groups)<br/>
                                role definition - a collection of permission such as read write and delete<br/>
                                scope - the resources the access applies to. specify which role can access a resource or resource group<br/><br/>

                                LOCKS<br/>
                                Locks can be put on resources to make sure there aren't any accidental or nefarious actions taken on them. The two types of locks are delete, which means you can't delete the resource, and "read only", which means you can't make any changes to the resources.<br/><br/>

                                - specific resource can have LOCKS<br/>
                                - assigning - assign to a subscription, resource or resource group<br/>
                                - types - Delete or Read Only<br/>
                                - a lock needs to be removed before the locked actions can be performed again<br/>
                                - note read-only lock prevents both deletion AND modification of the locked resource<br/><br/>

                                AZURE BLUEPRINTS<br/>
                                - automated and repeatable environment setup in azure <br/>
                                - NOTE this is NOT automated repeatable deployment (ARM)<br/>
                                - it is able to implement Role assignments, policy assignments, ARM templates, resource groups etc.,<br/>
                                - templates for creating standard azure resources<br/>
                                - can be used to deploy <br/>
                                - to follow rules and regulations<br/>
                                - Use blueprints - resource templates, rbac, policies, samples for common regulations<br/><br/>


                                CLOUD ADOPTION FRAMEWORK<br/>
                                - collection of documents with guidance for adoption towards cloud<br/>
                                - reasons for adopting, improving processes, governance<br/>
                                - for smoother transition to the cloud<br/><br/>

                                AZURE ADVISOR FOR SECURITY ASSISTANCE<br/>
                                - Advisor helps to optimize, reduce ovreall spend by identifying idle and underutilized resources<br/>
                                - security assistance is part of azure security center<br/><br/>

                                COMPLIANCE - regulations etc.,<br/>
                                - very specific to regional. example EU has its own - GDPR<br/>
                                - industry compliance - legislations rules for the industry<br/>
                                - GDPR - general data protection regulation - individuals with personal data. companies to have measures to protect and maitaining<br/>
                                - ISO  - have categories of compliance ISO 9001:2008 <br/>
                                - NIST - national inst of standards and technology - info & securty frameworks etc., standards and guidances to organizations at risk. provides standards for United states government and Department of Defense (DOD)<br/>
                                - ITIL - best practices for delivering IT services<br/><br/>

                                Azure Compliance Manager<br/>
                                - provides a percentage score of your total alignment with your azure environments compliance requirements<br/>
                                - azure knows about compliance and resources and can give you recommendations through the compliance manager<br/>
                                - recommendations - like gdpr, iso, nist. <br/>
                                - tasks - assign compliance tasks and track process.<br/>
                                - compliance score - <br/>
                                - secure storage - upload documents to prove compliance and store them securely<br/>
                                - reports<br/><br/>


                                AZURE GOVERNMENT CLOUD<br/>
                                - US or US Govt contractor only<br/>
                                - dedicated govt cloud regions<br/>
                                - local state tribal govt only<br/>
                                - level 5 department of defense approval<br/>
                                - azure benefits - HA, managed, scalability etcs<br/>
                                - dedicated regions for data isolation <br/>
                                - screened personnel - MS employees who service govt cloud must go through additional screening requirements<br/><br/>

                                CHINA Region<br/>
                                Services in the China region are hosted on a physically isolated instance of Azure. It is operated by 21Vianet, a company based in China. All of your customer data is kept within China, and is bound by Chinese regulations.<br/>
                                - specific to china. strict requirements <br/>
                                - data is kept in china<br/>
                                - cosmos db will not work global scale<br/>

                                * Compliance is NOT negotiable* <br/><br/>


                                AZURE PRIVACY<br/>
                                Privacy is a core component of each and every Azure service, so there isn't a single service. All products are built with privacy as a first class citizen<br/><br/>

                                - Azure Information Protection - classify label and protect data based on data sensitity<br/>
                                - Azure Policy - define and enforce rules to ensure privacy and external regulations<br/>
                                - Guides - use guides on azure to respond and comply with GDPR privacy requests<br/>
                                - Compliance Manager - make sure you are following privacy guidelines like GDPR, ISO etc.,<br/><br/>

                                Microsoft Privacy Statement<br/>
                                - how data is collected and treated<br/>
                                <br/>

                                TRUST<br/>
                                You can use the Trust Center to <br/>find documentation of all the various compliance standards Azure is adhering to. You can use the Service Trust Portal to read the audit reports for any part of Microsoft's products, including Azure.<br/><br/>

                                - Trust Center - shortcut that provides details about how MS handles treat data/compliance<br/>
                                a hub for more information about trust in each product and service<br/>
                                security privacy gdpr data location, compliance and more<br/>

                                - Service Trust Portal - portal where independent reports/audits performed on MS products and services<br/>
                                Service Trust portal is the central location for all published audit reports, risk managements & security best practices of Azure platform.<br/>
                                azure complies with more standards than any other cloud provider<br/><br/>



                                    <br/><br/>

                                    <br/>


                                    <br/>
                               </div>
                           
                                   
                               </AccordionDetails>
                       </Accordion>    
                       <Accordion>
                           <AccordionSummary>
                              <b>AZURE MONITOR</b>
                              
                           </AccordionSummary>
                           <AccordionDetails>
                               <div>
                               AZURE MONITOR<br/><br/>

                                - all the data provided by azure services and processes<br/>
                                - telemetry is a collection of measurements of other data at remote or inaccessible points and their automatic transmission to receiving equipment for monitoring<br/>
                                - telemetry provides information about how services/devices are performing. azure monitor provides this details/data<br/>
                                - constant feed - even on premises services can send telemetry data to azure monitor<br/>
                                -  fully managed. <br/>
                                - Kusto query language to learn about the data fed.<br/>
                                - uses kusto query language by data explorer to retrieve and analyze the data<br/>
                                - ML - predicts and recognize problems faster with built in ML<br/>
                                - max performance availability and identify issues<br/><br/>

                                AZURE SERVICE HEALTH<br/>
                                - provides information about azure downtime<br/>
                                - notifies about planned/unplanned downtime/maintenance<br/>
                                - customer alerts<br/>
                                - realtime tracking<br/><br/>



                                    <br/>
                               </div>
                           
                                   
                               </AccordionDetails>
                       </Accordion>    
                       
                       <Accordion >
                           <AccordionSummary>
                               <b>AZURE Solutions - ML,Big Data, IoT, Serverless and Devops</b>
                              
                           </AccordionSummary>
                           <AccordionDetails>
                               <div>
                               AZURE SOLUTIONS<br/><br/>

                                - IoT - Internet Of Things<br/>
                                - Big Data<br/>
                                - Machine Learning<br/>
                                - Serverless<br/>
                                - DevOps<br/><br/>

                                INTERNET OF THINGS<br/><br/>

                                system of inter related computing devices, mechanical and digital machines, objects, animals or people that are provided with unique identifiers  and the ability to transfer data over a network without requiring human interaction<br/><br/>

                                <br/>  -<br/> Azure IoT Hubs<br/>
                                - collects all the data from things. receives data from millions of devices<br/>
                                - PaaS helps to manage deploy and secure devices.<br/>
                                - Managed infrastructure, scaled devices and authentication<br/>
                                - provides more control over the IoT data collection and processing<br/>
                                
                                - Azure IoT Central<br/><br/>

                                - SaaS - simiplifies setup of implementation of IoT solution<br/>
                                - No coding needed<br/>
                                - receives the feeds from the devices and provides dashboard, metrics and rules to manage<br/>
                                - pre made connectors to available<br/><br/>
                                
                                Azure IoT Edge<br/>
                                service that builds on top of IoT hub<br/>
                                analyze data on devices (at the edge) instead of in the cloud<br/>
                                by moving parts closer to the customer locaiton, you can spend time sending messages to the cloud and get faster response/react faster for changes in status<br/><br/>
                                
                                - Azure Time Series Insights<br/>
                                - IoT solution to ingest, process, store and query highly contextualized time series optimized IoT scale data<br/>
                                - deals with adhoc data exploration and operational analysis<br/>
                                - provides powerful data exploration and telemetry tools to help you refine operational analysis<br/><br/>

                                - Azure Sphere<br/>
                                - an all in one solution for IoT devices on azure<br/>
                                - specific hardware - you can only use hardware and chipsets certified by MS for use on Azure<br/>
                                - Security - specialized security service that manages maintenance, updates and general control<br/>
                                - Operating System - custom made for Azure sphere devices. connects to the sphere security service<br/><br/>
                                
                                
                                BIG DATA<br/><br/>

                                - modern systems collects/needs enormous data. ex: cars, temperature, etc.,<br/>
                                - business value - collection of data may be easier and getting value from data could be tougher  <br/><br/>


                                Data Lake Analytics<br/>
                                - large amounts of data, parallel processing, ready to go <br/>
                                - on demand analytics job service that simplifies big data. <br/>
                                - instead of deploying, configuring and tuning hardware you write queries to transform your data and extract valuable insights. <br/>
                                - can handle jobs of any scale by setting the dial for how much power you need<br/>
                                - pay for your job when it is running make it cost-effective<br/><br/>

                                HDInsights<br/>
                                - Open source managed analytics service in the cloud<br/>
                                - similar to data lake analytics<br/>
                                - uses open source which is free and community supported<br/>
                                - apache hadoop, spark, kafka, storm, R and others<br/><br/>

                                Azure Databricks<br/>
                                - fully managed data analytics and machine learning using Apache spark frameworks<br/>
                                - databricks is a managed apache spark service. apache spark based analytics platform<br/>
                                - based on apache spark (distributed cluster computing on azure)<br/>
                                - computing power, scale, storage and integration with Azure<br/>
                                - includes several components like MLib (ML library with common algorithms, utils, classifications, regression, clustering, dimensionality reduction, collobative filtering etc.,)<br/><br/>


                                Azure Synapse Analytics<br/>
                                - limitless analytics service that brings together enterprise data warehousing and big data analytics<br/>
                                - Fully managed PaaS solution that can analyze and query petabytes of relational data using massively parallel processes<br/>
                                - can query massive amounts of data to create various reports<br/>
                                - analytics service that brings enterprise data warehousing and big data analytics. query data using serverless on demand or provisioned resources at scale. ingest, prepare, manage and serve data for immediate bi and ml needs<br/>
                                - four components - SQL Analytics, Spark (apache spark in Preview), Data integration (hybrid data integration in Preview) and Studio (UI - preview)<br/><br/>


                                - Used to be called as "Azure SQL Data Warehouse"<br/>
                                - Dataware house solution<br/>
                                - used for reporting and data analysis solution<br/>
                                - Synapse SQL language to manipulate data<br/><br/>

                                Benefits<br/>
                                - Speed & efficiency<br/>
                                - Cost reduction - storage and procesing<br/>
                                - Better decision making<br/>
                                - New products and services<br/><br/>

                                AZURE MACHINE LEARNING/AI<br/><br/>

                                - all in one service that supports end to end process of creating, testing and deploying machine learning models<br/>
                                - this is the ideal service for creating your own machine learning models<br/>
                                - AI is the capabilithy of machines to imitate human intelligence. <br/>
                                - To analyze images, comprehend speech, interact in natural ways and predict using the data<br/><br/>

                                1) Create MODELS<br/>
                                - define models. model is a set of rules of how to use the data provided. Model finds patterns based on the rules<br/><br/>
                                
                                2) Knowledge Mining<br/>
                                - use Azure Search to find existing insights in your data. file relationships, geography connections and more<br/><br/>

                                3) Built-in Apps<br/>
                                - built in ML/AI services that provide cognitive and bot services<br/><br/>
                                
                                Azure Bot Service - PaaS that lets you build bots for Q&A services, virtual assistants and more<br/><br/>

                                - can be using visual composer (non code way) or using a programming language<br/>
                                - let user talk to your bot using natural language or speech recognition<br/>
                                - integrate with services like facebook, messenger, teams etc. <br/>
                                - use your own branding and own the data the bot uses and produces<br/><br/>

                                Azure Cognitive Services<br/>
                                - collections of tools<br/>
                                - provides a variety of pretrained machine learning APIs to perform machine learning tasks on your data<br/>
                                - Vision Service - to identify visual objects. recognize , identify and caption your videos and images <br/>
                                - Decision Service - helps to make informed decision. ex: potential offensive language, detect IoT anomolies and leverage data analytics<br/>
                                - Speech Service - automatic speech to text transcription. Speaker identification and verification<br/><br/>


                                Machine Learning Studio<br/>
                                - provides drag and drop visual interface for ML using preconfigured algorithms. <br/>
                                - Azure Machine learning is a managed cloud service for machine learning where you can train, deploy and manage models in Azure using python and cli. <br/>
                                - Visual tool <br/>
                                - supports all azure learning tools<br/>
                                - pre made modules for your project<br/>
                                - use cases - ex: twitter sentiment analysis, photo grouping, movie recommendations <br/><br/>


                                Machine Learning Service<br/>
                                - end to end service<br/>
                                - range of tools that helps to build AI applications<br/>
                                - automatically recognizes trends and applications, creates models<br/><br/>


                                SERVERLESS<br/>
                                - helps to focus on business process instead of maintaining infrastructure/servers etc<br/>
                                - servers are not managed by the customer. <br/>
                                - extreme version of PaaS<br/>
                                - Ex: Azure Functions, <br/><br/>


                                Azure Functions- <br/>
                                - Single Task as a single task is performed every time. runs only once for one invocation<br/>
                                - fundamental compute action and can be run milions of times per seconds if needed<br/><br/>

                                Logic Apps<br/>
                                - connect systems both inside and outside of azure platform, integrates systems. <br/>
                                - schedule automate and orchestrate tasks and process. <br/>
                                - no coding required. all customizable from portal<br/>
                                - Ex use case: send emails for everytime order is submitted, processed<br/><br/>

                                Note: Automation workflow can be created to perform certain action. ex: to send notification for specific threats. While creating the workflow, Logic App has to be created prior<br/><br/>

                                Event Grid<br/>
                                - serverless routing service that can send and receive events between azure services and applications<br/>
                                - routing service - for sending receiving events betweeen applications.  <br/>
                                - no management of infrastructure components. <br/>
                                - create an azure event grid and start connecting services. <br/>
                                - ease of use<br/><br/>


                                -----------------------------------<br/><br/><br/>
                                DEVOPS<br/><br/>
                                - to create robust software faster<br/>
                                - work between development and production<br/><br/>

                                Azure DevOps - platform consisting of 5 different tools<br/>
                                azure devops services provides development collboration tools including pipelines, free private git repositories, configurable kanban boards, extensive and automated continuous testing capabilities<br/>
                                - Azure Boards - keep track of work tasks, timelines, issues, planning, etc., ex: for project managers<br/>
                                - Azure pipelines - produce and test software automatically and continuously<br/>
                                - Azure repos - store source code for your application securely in a managed way. source control service that exists inside of an Azure DevOps organization<br/>
                                - Azure Test Plans - setup automated design tests for applications . example for Quality engineers/testing teams<br/>
                                - Azure Artifacts -  share applications and code libraries with other teams inside and outside your organizations<br/><br/>

                                Azure DevTest Labs<br/>
                                - focusses on environment<br/>
                                - helps devs, engineers to create brand new test envs<br/>
                                - helps to cost management and minimize wastage of resources<br/>
                                - templates helps to reuse<br/><br/>

                                Github and Github Actions<br/>
                                - bought by MS in 2018. code repository<br/>
                                - hosts many open source repository<br/><br/>

                                Github Actions<br/>
                                - similar to Azure pipelines<br/>
                                - helps to push to live/production <br/>
                                - can push to aws/azure or other platforms also<br/><br/>




                                    <br/>
                               </div>
                           
                                   
                               </AccordionDetails>
                       </Accordion>    
               </div>              
           </div>
          
         </div>
     </div>
    );
  }
}
 
export default AzureBase;