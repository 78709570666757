import React, { Component } from "react";
import MyBlogs from "./MyBlogs";
import GenAIPractitioner from "../common/GenAIPractitioner";

import TypoGraphy from '@material-ui/core/Typography'
import { Grid, Paper, Typography, List, ListItem } from "@material-ui/core";


class AWSDedication extends Component {
  constructor(props){
    super(props)
       window.clickLog("load", "AWSDedication")    
  } 
  
  openUrl(value){
    window.open(value, false);
  }

  render() {
    return (
      <div>
        <div style={{ marginTop: 20, padding: 30 }}>
            <Grid container spacing={40} justify="center">
                <TypoGraphy variant="h4">
                    <center>AWS Dedication</center>
                </TypoGraphy>
            </Grid>
            <div  className="pad-top-30 pad-left-page">
                                 
              Follow me at <button  className="linkbutton" onClick={() => this.openUrl('https://www.cloudopsguru.com')}>cloudopsguru</button>
              <GenAIPractitioner />              
              <MyBlogs></MyBlogs>

            </div>
        </div>
       </div>
    );
  }
}
 
export default AWSDedication;