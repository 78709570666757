import React, { Component } from "react";
import TypoGraphy from '@material-ui/core/Typography'
import { Grid, Paper, Typography, List, ListItem } from "@material-ui/core";

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

class Opensource extends Component {

    constructor(props){
        super(props)
           window.clickLog("load", "OpenSource")    
      } 

    openUrl(value){
        window.open(value, false);
      }
      
      
  render() {
    return (
        <div>
            <div style={{ marginTop: 20, padding: 30 }}>
            <Grid container spacing={40} justify="center">
                <TypoGraphy variant="h4">
                    <center>Open Source</center>
                </TypoGraphy>
            </Grid>
                <div className="pad-left-page">
                <div class="row">
                <div class="col-sm-7 col-md-5">
                    <div class="">
                        <p>Follow me at <button  className="linkbutton" onClick={() => this.openUrl('https://github.com/shivaramani/opensource')}>Opensource</button>
                        </p>

                        <Accordion>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Angular JS Routing
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                            
                                <AccordionItemPanel>
                                    The idea of this project is to provide a simple AngularJS Routing 
                                    <br/><br/>
                                    
                                    <b>Goal:</b> To provide a simple html application with multiple (partial views) with angular routing
                                    <br/><br/>
                                    1. Provide separate controllers for each views
                                    <br/>
                                    2. Angular ui.router provider to handle the routing
                                    <br/>
                                    3. Controlers folder - contains all the controller files
                                    <br/>
                                    4. css & scripts have the style and app js file
                                    <br/><br/>
                                    
                                    Running the example<br/><br/>
                                    
                                    The example can be run by running the attached 
                                    <button  className="linkbutton" onClick={() => this.openUrl('http://localhost/AngularRouting/Main.html')}>Main.html</button>
                                    using your web/node server
                                    <br/>
                                    
                                    Explanation of the project
                                    <br/><br/>
                                    1. Main.html - main html page that hosts the other views
                                    <br/>
                                    2. App.js - sources the routing logic
                                    <br/>
                                    3. maincontroller, controllerA, controllerB - sources simple controllers feeding respective htmls
                                    <br/>
                                    4. style.css - sample css
                                    <br/><br/>
                                    
                                    <b>For more details: Click here on the github</b><br/>
                                    <button  className="linkbutton" onClick={() => this.openUrl('https://github.com/shivaramani/AngularRouting')}>Github link</button>
                                    
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>    
                        <div className="pad-top-10"></div>
                        <Accordion>    
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    Cucumber HTTP Testing - Sample app for Behavior Driven Development
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                            
                                <AccordionItemPanel>
                                    The idea of this project is to provide a simple cucumber test implementation
                                    <br/><br/>
                                    <b>Goal: </b>To validate the expected result output from the feature file for
                                    <br/><br/>
                                    1. Addition and multiplication of two numbers
                                    <br/>
                                    2. Validating a HTTP POST service response
                                    <br/>
                                    <br/>
                                    Expected NPM Packages:
                                    <br/><br/>
                                    
                                    a) Cucumber - Core Behavior Driven Development / Test facilitator 
                                    <br/>
                                    b) Gulp - Gulp will be used to run the application ex: $gulp httptest 
                                    <br/>
                                    c) express - run the express web application 
                                    <br/>
                                    d) node-fetch, build-url - These packages will help us to GET/POST HTTP calls 
                                    <br/>
                                    e) jsonpath, unexpected - Used to validate json response
                                    <br/>
                                    ##Install the following Run - "npm install" to accomplish the installation of these
                                    <br/><br/>
                                    If you want to run individually, you may run these with -g / --save-dev options to have it run locally
                                    <br/><br/>
                                    1.npm install cucumber npm install -g cucumber npm install --save-dev cucumber
                                    <br/>
                                    2.npm install gulp
                                    <br/>
                                    3.npm install gulp-cli
                                    <br/>
                                    4.npm install gulp-shell
                                    <br/>
                                    5.npm install express
                                    <br/>
                                    6.npm install body-parser
                                    <br/>
                                    7.npm install node-fetch
                                    <br/>
                                    8.npm install build-url
                                    <br/>
                                    9.npm install jsonpath
                                    <br/>
                                    10.npm install unexpected
                                    <br/><br/>
                                    
                                    Running examples
                                    <br/><br/>
                                    
                                    The example can be run by running the sample node server along with Gulp by calling as follows
                                    <br/>
                                    
                                    run the node http server
                                    <br/>
                                    
                                    a) Change directory - $ cd httptest\server 
                                    <br/>
                                    b) $ node server.js
                                    <br/>
                                    run gulp for testing the feature files
                                    <br/>
                                    $ gulp httptest
                                    <br/>
                                    or
                                    <br/>
                                    $ npm run httptest
                                    <br/><br/>
                                    known issues
                                    <br/><br/>
                                    gulpfile.js has reference of "cucumber_js" instead of "cucumber.js" - This is a known implementation due to a possible cucumber bug
                                    <br/>
                                    if cucumber_js did not work, please try changing it to cucumber.js (works in linux)
                                    <br/><br/>

                                    <b>For more details: Click here on the github</b><br/>
                                    <button  className="linkbutton" onClick={() => this.openUrl('https://github.com/shivaramani/cucumber_http_test')}>github link</button>
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>
                        <div className="pad-top-10"></div>
                        <Accordion>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        configure CGI
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                
                                <AccordionItemPanel>
                                    1) Install apache2
                                    <br/>
                                    2) if doesn't exist - > mkdir /usr/lib/cgi-bin
                                    this is where the cgi files can be kept
                                    <br/>
                                    3) configure apache available sites default.conf to serice cgi files
                                    sudo nano /etc/apache2/sites-available/000-default.conf
                                    <br/>
                                    4) Add or modify the below lines (just above errorlog...)
                                    ScriptAlias /cgi-bin /usr/lib/cgi-bin/
                                    
                                    AllowOverride None
                                    Options +ExecCGI -MultiViews +SymLinksIfOwnerMatch
                                    #Order allow, deny
                                    Require all granted
                                    Allow from all
                                    <br/>  
                                    5) sudo service apache2 restart
                                    <br/>
                                    6) Create first file in cgi and provide access
                                    cd /usr/lib/cgi-bin
                                    sudo nano hello.cgi
                                    "Hello.cgi" - contents
                                    *******************************
                                    #!/bin/bash
                                    echo -e "Content-type: text/html \n\n"
                                    echo "<h1>1" Hello World "</h1>"
                                    ******************************
                                    <br/>
                                    7) Provide access to the file
                                    sudo chmod +x /usr/lib/cgi-bin/hello.cgi
                                    <br/>
                                    8) run the website in localhost
                                    http://localhost/cg-bin/hello.cgi
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>
                        <div className="pad-top-10"></div>
                        <Accordion>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        configure PHP
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                
                                <AccordionItemPanel>
                                    1) Install apache if not installed
                                    sudo apt-get update
                                    sudo apt-get install apache2
                                    <br/>
                                    2) install php
                                    sudo apt-get install php5 libapache2-mod-php5 php5-mcrypt
                                    <br/>
                                    3) add index.php (if already not in dir.conf)
                                    sudo nano /etc/apache2/mods-enabled/dir.conf
                                    
                                            DirectoryIndex index.php index.html index.cgi index.pl index.php index.xhtml index.htm
                                    <br/>
                                    4) Create sample php file and move it to \var\www\html
                                    sudo nano /var/www/info.php
                                    <br/>
                                    5) restart apache
                                    sudo service apache2 restart
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>
                        <div className="pad-top-10"></div>
                        <Accordion>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Raspberry Pi
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                
                                <AccordionItemPanel>
                                    The Raspberry Pi is a low cost, credit-card sized computer that plugs into a computer monitor or TV, 
                                    and uses a standard keyboard and mouse. 
                                    <br/><br/>
                                    It is a capable little device that enables people of all ages 
                                    to explore computing, and to learn how to program in languages like Scratch and Python. 
                                    It’s capable of doing everything you’d expect a desktop computer to do, from browsing the internet and playing 
                                    high-definition video, to making spreadsheets, word-processing, and playing games. 
                                    <br/><br/>
                                    What’s more, the Raspberry  Pi has the ability to interact with the outside world, and has been used in a wide array of digital maker 
                                    projects, from music machines and parent detectors to weather stations and tweeting birdhouses with infra-red 
                                    cameras. 
                                    <br/><br/>
                                    We want to see the Raspberry Pi being used by kids all over the world to learn to program and 
                                    understand how computers work 
                                    In this article we will discuss about setting up few items in our Pi .

                                    <br/>
                                    <br/>
                                    <b>For more details: Click here</b><br/>
                                                        
                                    <button  className="linkbutton" onClick={() => this.openUrl('https://www.raspberrypi.org')}>RaspberryPi</button>
                                </AccordionItemPanel>
                            </AccordionItem>

                        </Accordion>
                        
                    </div>              
                </div>
            
            </div>
            </div>
            </div>
        </div>
    );
  }
}
 
export default Opensource;