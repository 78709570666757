import React, { Component } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

class SNS extends Component {

    openUrl(value){
        window.open(value, false);
      }
      
      
  render() {
    return (
      <div className="pad-top-30 pad-left-page">
          <div class="row">
            <div class="col-sm-7 col-md-5">
                  <div class="">
                    <h2>Open source</h2>  <br/>                  
                    

                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                SNS - simple notification service
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           
                            <AccordionItemPanel>
                            SNS - simple notification service
                            pub-sub messaging. using push mechanism to appe, google, fire os, ios, baidu etc. deliver to lambda,sms/email/sqs or any http end point
                            web service makes it to set up operate, send notifications from the cloud
                            <br/>
                            ex: push notificaitons to apple, google, fire os, windows devices as well as any android services
                            <br/>
                            sns can deliver sms, email, sqs, message, http end points. it can trigger a lambda functions. lambda function receives the message payload as input parameter
                            <br/>
                            sns allows you to group multiple recipients using topics. a topic is an access point for allowing recipients to dynamically subscribe identical copies of the same notification. ex: a same topic message to iphone, windows etc
                            <br/>
                            SNS benefits
                            -  no polling. push based. instantaneous (SQS polls and requires ec2)<br/>
                            -  simple apis and easy integration<br/>
                            -  flexible  multiple protocols<br/>
                            -  inexpensive pay as you go<br/>
                            -  web based<br/>
                            <br/>
                                
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>    
                    <div className="pad-top-10"></div>
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                API Interfaces
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           
                            <AccordionItemPanel>
                                    
                            - AddPermission<br/>
                            - CheckIfPhoneNumberIsOptedOut<br/>
                            - ConfirmSubscription<br/>
                            - CreatePlatformApplication
                            <br/>- CreatePlatformEndpoint

                            <br/>- CreateTopic - Users can create at most 100,000 topics, 1 and 256 characters long

                            <br/>- DeleteEndpoint - Deletes the endpoint for a device and mobile app from Amazon SNS. This action is idempotent

                            <br/>- DeletePlatformApplication

                            <br/>- DeleteTopic - Deletes a topic and all its subscriptions
                                idempotent, so deleting a topic that does not exist does not result in an error

                            <br/>- GetEndpointAttributes
                            <br/>- GetPlatformApplicationAttributes
                            <br/>- GetSMSAttributes
                            <br/>- GetSubscriptionAttributes
                            <br/>- GetTopicAttributes
                            <br/>- ListEndpointsByPlatformApplication
                            <br/>- ListPhoneNumbersOptedOut
                            <br/>- ListPlatformApplications
                            <br/>- ListSubscriptions
                            <br/>- ListSubscriptionsByTopic
                            <br/>- ListTopics
                            <br/>- OptInPhoneNumber

                            Publish - Sends a message to an Amazon SNS topic or sends a text message (SMS message) directly to a phone number.

                            <br/>- RemovePermission
                            <br/>- SetEndpointAttributes
                            <br/>- SetPlatformApplicationAttributes
                            <br/>- SetSMSAttributes
                            <br/>- SetSubscriptionAttributes
                            <br/>- SetTopicAttributes

                            <br/>- Subscribe - Prepares to subscribe an endpoint by sending the endpoint a confirmation message. 
                                To actually create a subscription, the endpoint owner must call the ConfirmSubscription action with the token from the confirmation message. 
                                Confirmation tokens are valid for three days.

                            <br/>- Unsubscribe <br/>
                                
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>  
                    <div className="pad-top-10"></div>
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                Developer Notes
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           
                            <AccordionItemPanel>
                            Message format

                            - MessageId: A Universally Unique Identifier, unique for each notification published.
                            <br/>- Timestamp: The time (in GMT) at which the notification was published.
                            <br/>- TopicArn: The topic to which this message was published
                            <br/>- Type: The type of the delivery message, set to “Notification” for notification deliveries.
                            <br/>- UnsubscribeURL: A link to unsubscribe the end-point from this topic, and prevent receiving any further notifications.
                            <br/>- Message: The payload (body) of the message, as received from the publisher.
                            <br/>- Subject: The Subject field – if one was included as an optional parameter to the publish API call along with the message.
                            <br/>- Signature: Base64-encoded “SHA1withRSA” signature of the Message, MessageId, Subject (if present), Type, Timestamp, and Topic values.
                            <br/>- SignatureVersion: Version of the Amazon SNS signature used.
                            <br/>
                                
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>  
                      
                </div>              
            </div>
           
          </div>
      </div>
    );
  }
}
 
export default SNS;