import React, { Component } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

class MyBlogs extends Component {
  constructor(props){
    super(props)
       window.clickLog("load", "MyBlogs")    
  } 
  
  openUrl(value){
    window.open(value, false);
  }

  render() {
    return (
      <div className="pad-top-30 pad-left-page">
          <div class="row">
            <div class="col-sm-7 col-md-5">
                  <div class="">
                   
                     <br/><br/>
                    <Accordion expanded={true} >
                        <AccordionSummary>
                            <b>My Earlier AWS Blogs!</b>
                            
                        </AccordionSummary>
                        <AccordionDetails>
                            <div>
                                
                              <b>AWS Batch Application Orchestration using AWS Fargate blog! - OCT 11 2021</b><br/><br/>
                              - Overview – An example of running AWS Batch Application Orchestration using AWS Fargate blog <br/><br/>
                              - Many customers prefer to use Docker images with AWS Batch and AWS Cloudformation for cost-effective and faster processing of complex jobs. To run batch workloads in the cloud, customers have to consider various orchestration needs, such as queueing workloads, submitting to a compute resource, prioritizing jobs, handling dependencies and retries, scaling compute, and tracking utilization and resource management. While AWS Batch simplifies all the queuing, scheduling, and lifecycle management for customers, and even provisions and manages compute in the customer account, customers continue to look for even more time-efficient and simpler workflows to get their application jobs up and running in minutes. <br/><br/>
                              - Read more: <button  className="linkbutton" onClick={() => this.openUrl('https://aws.amazon.com/blogs/developer/aws-batch-application-orchestration-using-aws-fargate//')}>AWS Batch Application Orchestration using AWS Fargate</button>
                              
                              <br/><br/> 

                              <b>AWS Java Kubernetes Job On EKS Step Functions Blog! - MAR 29 2021</b><br/><br/>
                              - Overview – Running a Kubernetes Job in Amazon EKS on AWS Fargate Using AWS StepFunctions <br/><br/>
                              - In a previous AWS Blog, I shared an application orchestration process to run Amazon Elastic Container Service (Amazon ECS) Tasks on AWS Fargate using AWS Step Functions. This blog will be similar continuation but here we will be running the same application on Amazon EKS as a Kubernetes job on Fargate using StepFunctions. <br/><br/>
                              - Read more: <button  className="linkbutton" onClick={() => this.openUrl('https://aws.amazon.com/blogs/developer/running-a-kubernetes-job-in-amazon-eks-on-aws-fargate-using-aws-stepfunctions/')}>AWS Java Kubernetes Job on EKS Fargate with Step Functions</button>
                              
                              <br/><br/> 

                              <b>AWS Java ECS Fargate Step Functions Blog! - FEB 10 2021</b><br/><br/>
                              - Overview – An example of running Amazon ECS tasks on AWS Fargate – Terraform (By HashiCorp) blog <br/><br/>
                              -In this blog, we will walk you through a use case of running an Amazon ECS Task on AWS Fargate that can be initiated using AWS Step Functions. We will use Terraform to model the AWS infrastructure. The example solution leverages Amazon ECS a scalable, high performance container management service that supports Docker containers that are provisioned by Fargate to automatically scale, load balance, and manage scheduling of your containers for availability. For defining the infrastructure, you can use AWS CloudFormation, AWS CDK or Terraform by HashiCorp. In the solution presented in this post, we use  Terraform by HashiCorp, an AWS Partner Network (APN) Advanced Technology Partner and member of the AWS DevOps Competency. <br/><br/>
                              - Read more: <button  className="linkbutton" onClick={() => this.openUrl('https://aws.amazon.com/blogs/developer/provision-aws-infrastructure-using-terraform-by-hashicorp-an-example-of-running-amazon-ecs-tasks-on-aws-fargate/')}>AWS Java ECS Fargate Step Functions</button>
                              
                              <br/><br/> 

                              <b>AWS .NET CDK CDK8s Blog! - SEP 4, 2020</b><br/><br/>
                              - Overview – Build and Deploy .Net Core WebAPI Container to Amazon EKS using CDK and cdk8s blog <br/><br/>
                              - In this blog, we will leverage the development capabilities of the CDK for Kubernetes framework also known as cdk8s along with the AWS Cloud Development Kit (AWS CDK) framework to provision infrastructure through AWS CloudFormation. cdk8s allows us to define Kubernetes apps and components using familiar languages. cdk8s is an open-source software development framework for defining Kubernetes applications and reusable abstractions using familiar programming languages and rich object-oriented APIs. cdk8s apps synthesize into standard Kubernetes manifests which can be applied to any Kubernetes cluster. cdk8s lets you define applications using Typescript, JavaScript, and Python. In this blog we will use Python. <br/><br/>
                              - Read more: <button  className="linkbutton" onClick={() => this.openUrl('https://aws.amazon.com/blogs/developer/build-and-deploy-net-core-webapi-container-to-amazon-eks-using-cdk-cdk8s/')}>AWS .NET CDK CDK8s</button>
                              
                              <br/><br/> 
                      
                              <b>Data ingestion into AWS - Provision AWS infrastructure using Terraform (By HashiCorp) ! - MAY 15, 2020 </b><br/><br/>
                              - Overview –Provision AWS infrastructure using Terraform (By HashiCorp) blog <br/><br/>
                              - Many web and mobile applications can make use of AWS services and infrastructure to log or ingest data from customer actions and behaviors on the websites or mobile apps, to provide recommendations for better user experience. There are several ‘infrastructure as code’ frameworks available today, to help customers define their infrastructure, such as the AWS CDK or Terraform by HashiCorp. In this blog, we will walk you through a use case of logging customer behavior data on web-application and will use Terraform to model the AWS infrastructure. <br/><br/>
                              - The data ingestion process is exposed with an API Gateway endpoint. The Amazon API Gateway processes the incoming data into an AWS Lambda during which the system validates the request using a Lambda Authorizer and pushes the data to a Amazon Kinesis Data Firehose. The solution leverages Firehose’s capability to convert the incoming data into a Parquet file (an open source file format for Hadoop) before pushing it to Amazon S3 using AWS Glue catalog. Additionally, a transformational/consumer lambda does additional processing by pushing it to Amazon DynamoDB. <br/> <br/>
                              - Read more: <button  className="linkbutton" onClick={() => this.openUrl('https://aws.amazon.com/blogs/developer/provision-aws-infrastructure-using-terraform-by-hashicorp-an-example-of-web-application-logging-customer-data/')}>AWS Data Ingestion using Terraform</button>
                              
                              <br/><br/>                              

                              <b>AWS Batch .NET CDK Blog! - MAY 2, 2020 </b><br/><br/>
                              - Overview – AWS CDK .NET for Batch blog <br/><br/>
                              - This post provides a file processing implementation using Docker images and Amazon S3, AWS Lambda, Amazon DynamoDB, and AWS Batch. In this scenario, the user uploads a CSV file to into an Amazon S3 bucket, which is processed by AWS Batch as a job. These jobs can be packaged as Docker containers and are executed using Amazon EC2 and Amazon ECS. <br/><br/>
                              - Read more: <button  className="linkbutton" onClick={() => this.openUrl('https://aws.amazon.com/blogs/developer/orchestrating-an-application-process-with-aws-batch-using-aws-cdk/')}>AWS Batch using AWS CDK</button>
                              
                              <br/><br/>           
                              <b>AWS Batch Using CloudFormation Blog! - DECEMBER 17, 2019 </b><br/><br/>
                              - Overview – AWS .NET for Batch blog <br/><br/>
                              - This post provides a file processing implementation using Docker images and Amazon S3, AWS Lambda, Amazon DynamoDB, and AWS Batch. In this scenario, the user uploads a CSV file to into an Amazon S3 bucket, which is processed by AWS Batch as a job. These jobs can be packaged as Docker containers and are executed using Amazon EC2 and Amazon ECS. <br/><br/>
                              - Read more: <button  className="linkbutton" onClick={() => this.openUrl('https://aws.amazon.com/blogs/compute/orchestrating-an-application-process-with-aws-batch-using-aws-cloudformation/')}>AWS Batch using CloudFormation</button>
                              
                              <br/><br/>           
                              <b>AWS .NET Core Aurora CDK Blog! - NOVEMBER 25, 2019 </b><br/><br/>
                              - Overview – AWS .NET Core Aurora CDK blog <br/><br/>
                              - Many existing .NET Core applications can be containerized using docker and AWS services like Amazon EC2/ Amazon Elastic Container Service (ECS), AWS Systems Manager (SSM), Amazon Aurora Database providing a full blown API application system. The application architecture is complemented by build & pipeline tools like AWS CodeCommit, AWS CodeBuild using AWS CloudFormation. At the end of this blog, we will create a simple Microsoft .NET Web API for a ToDo Application <br/><br/>
                              - Read more: <button  className="linkbutton" onClick={() => this.openUrl('https://aws.amazon.com/blogs/developer/developing-a-microsoft-net-core-web-api-application-with-aurora-database-using-aws-cdk/')}>AWS Aurora with .NET Web API using AWS CDK</button>
                              
                              <br/><br/>           
                              <b>AWS .NET Core Aurora CloudFormation Blog! - ONOCTOBER 25, 2019 </b><br/><br/>
                              - AWS .NET Core Aurora CloudFormation Blog! <br/><br/>
                              - Many existing .NET Core applications can be containerized using docker and AWS services like Amazon EC2/ Amazon Elastic Container Service (ECS), AWS Systems Manager (SSM), Amazon Aurora Database providing a full blown API application system. The application architecture is complemented by build & pipeline tools like AWS CodeCommit, AWS CodeBuild using AWS CloudFormation. At the end of this blog, we will create a simple Microsoft .NET Web API for a ToDo Application <br/><br/>
                              - Read more: <button  className="linkbutton" onClick={() => this.openUrl('https://aws.amazon.com/blogs/developer/developing-a-microsoft-net-core-web-api-application-with-aurora-database-using-cloudformation/')}>AWS Aurora with .NET Web API using AWS CloudFormation</button>
                              
                              <br/><br/>     
                              <br/>
                            </div>      
                        </AccordionDetails>
                    </Accordion> 
                </div>              
            </div>
           
          </div>
      </div>
    );
  }
}
 
export default MyBlogs;