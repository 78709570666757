import React, { Component } from "react";
import TypoGraphy from '@material-ui/core/Typography'
import { Grid, Paper, Typography, List, ListItem } from "@material-ui/core";

class Microsoft extends Component {

  constructor(props){
    super(props)
       window.clickLog("load", "Microsoft")    
  }  

  openUrl(value){
    window.open(value, false);
  }
  
  render() {
    return (
      <div>
         <div style={{ marginTop: 20, padding: 30 }}>
          <Grid container spacing={40} justify="center">
              <TypoGraphy variant="h4">
                  <center>Microsoft</center>
              </TypoGraphy>
          </Grid>
          
      <div className="pad-left-page">
          <div class="row">
            <div class="col-sm-7 col-md-5">
                  <div class="">
                    <p>Follow me at <button  className="linkbutton" onClick={() => this.openUrl('https://github.com/shivaramani/.net')}>Microsoft Samples</button>
                    </p>
                    <h2>Microsoft .NET CORE</h2>                    
                    <p>Follow me at <button  className="linkbutton" onClick={() => this.openUrl('https://github.com/shivaramani/DotnetCore')}>.NET Core Samples</button>
                    </p>
                </div>              
            </div>
           
          </div>

            <div>
            <div class="row">
                    <div class="col-sm-7 col-md-5">
                        <div class="">
                        .NET goes cross-platform !!! I studied recently about Microsoft's new implementation of .NET Core. Thought will write an article on this 
                        
                        <br/><br/>
                        What's .NET Core? 
                        <br/><br/>
                        • .NET is Core is a new cross-platform .NET implementation with different goals than .NET Framework !!!
                        <br/>
                        • How is it different from .NET Framework - 
                        <br/>
                            The ..NET we all love is known as the framework. .NET core is a new stack & not a subset of .NET Framework 
                            <br/>
                        • First thing comes to my mind is we would still continue to use the same languages we are familiar with like.., C#, F#, VB etc., 
                        <br/>
                        <br/><br/>
                        When should i use .NET Core? 
                        <br/><br/>
                            May be these four possible use cases are good while we work with .NET Core 
                            <br/>
                        • Cross platform ASP.NET Web application 
                        <br/>
                        • Cross platform Console application 
                        <br/>
                        • Cross platform libraries and frameworks 
                        <br/>
                        • UWP apps 
                        <br/><br/>
                            How about deploying an ASP.NET web application into a linux container 
                            <br/>
                            The scope of cross-platform console apps is quite larger than many developers might actually expect. 
                            <br/><br/>
                        
                        Will my code on one stack (.NET framework) run on another (.NET core)? 
                        <br/>
                            The answer would be it depends, If the APIs you use are implemented in both platforms then should be able to run the code in both stacks.
                            <br/>
                        The Command Line: My Entry for .NET Core
                        <br/>
                            .NET Core comes with a new toolset that helps to develop application called .NET Core CLI (command line interface). As with other parts of .NET Core this is also an open source CLI from github. With so diverse set of platforms this was designed to be a good command line experience. The expectation is to be able to move between Linux, Windows and OS X and not have to re-learn the tools. The usage patterns & the syntaxes are the same 
                            <br/><br/>
                        References:
                        <br/>
                        • Github open source CLI  
                        <br/>
                        • Microsoft .NET Core getting started  
                        <br/>
                        • Few of the other references that may be of use 
                            <br/>
                        • .NET Core Runtime  
                        <br/>
                        • The .NET libraries  
                        <br/>
                        • The Rosyln Compiler  
                        <br/>
                        • MS documentation for .NET Core  
                        <br/>
                        
                        Note: This article is intended to understand the basics and "know what/how" of .NET Core and author bears no responsibility of the URLs/references. Contents are inspired by Microsoft and various other references on the product including MSDN, internet, etc.,. to promote/educate the same.  
                        <br/>
                        </div>              
                    </div>
                
                </div>
        </div>

      </div>
      </div>
    </div>
    );
  }
}
 
export default Microsoft;