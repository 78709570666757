import React, { Component } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

class OpsWorks extends Component {

    openUrl(value){
        window.open(value, false);
      }
      
      
  render() {
    return (
      <div className="pad-top-30 pad-left-page">
          <div class="row">
            <div class="col-sm-7 col-md-5">
                  <div class="">
                    <h2>OpsWorks></h2>

                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                OPSWORKS
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           
                            <AccordionItemPanel>
                                

                                management service helps to configure and operate aws apps<br/>
                                stacks are containers that may host aws resources like elb, web/app, DB servers <br/>
                                layers are specialized groups of resources. ex: set of ec2 instances, db servers<br/>
                                chef recipes - layers depends on recipes to install packages, deploy apps in instances or running scripts etc.,<br/>
                                cookbooks - these are packaged custom recipes/related files (into one or more cook books). generally stored in a repository like S3 or git<br/>
                                cloudwatch (metrics and logs), cloudtrail - can be used for monitoring stacks. AWS config cannot be used directly<br/><br/>

                                Stack features a set of lifecycle Events which automatically run specified set of recipes at appropriate time<br/>
                                    - setup, configure, deploy, undeploy, shutdown<br/><br/>

                                if you want to manage the infrastructure with a config management system like Chef. <br/>
                                lets you write chef reciipes<br/>
                                consider scaling options to be handled explicitly<br/><br/>

                                sample steps to create a stack/application with RDS:<br/><br/>

                                - Create a stack<br/>
                                - add an app layer with instances<br/>
                                - add application to the instance<br/>
                                - Add a new DB layer. add recipes to deploy actions of db/app server layers<br/>
                                - Setup Deploy JSON (for variables like RDS connection host, user, db attributes)<br/>
                                - create custom recipe - to set up connection between app and rds layer (generally using a configuration file)<br/><br/>

                                Reference links:<br/>

                                <button  className="linkbutton" onClick={() => this.openUrl('http://docs.aws.amazon.com/opsworks/latest/userguide/welcome.html')}>AWS OpsWorks</button>
                                <button  className="linkbutton" onClick={() => this.openUrl('http://jayendrapatil.com/tag/opsworks/')}>Jayendra Patil - OpsWorks</button>
                                <button  className="linkbutton" onClick={() => this.openUrl('http://docs.aws.amazon.com/opsworks/latest/userguide/customizing-rds.html')}>Customizing RDS Stacks</button>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>  
                      
                </div>              
            </div>
           
          </div>
      </div>
    );
  }
}
 
export default OpsWorks;