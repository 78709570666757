import React, { Component } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

class VPC extends Component {

    openUrl(value){
        window.open(value, false);
      }
      
      
  render() {
    return (
      <div className="pad-top-30 pad-left-page">
          <div class="row">
            <div class="col-sm-7 col-md-5">
                  <div class="">
                    <h2>VPC - Virtual Private Cloud</h2>  <br/>                  
                   

                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                VPC - Virtual Private Cloud
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           
                            <AccordionItemPanel>
                                    
                            VPC - logical data center
                            consists of internet gateways , VPNs, route tables , network access control lists, subnets
                            vpc - is aws cloud where you can launch aws resources in network we define
                            <br/><br/>
                            customize network configuration. ex: public facing subnets, can create hardware VPN connection between corporate data center and your VPC
                            <br/>
                            security groups can multiple AZs
                            one subnet across one availabiility zone. cannot span across more than on one AZ
                            <br/>
                            only one internet gateway per  VPC
                            security groups are stateful - ex: by default http is alllowed out
                            network access control lists are stateless - ex: http traffic into network ACL, then we have to create out of it
                            <br/><br/>
                            default VPC vs Custom VPC
                            default VPC:
                            default vpc is user friendly. all subnets have a route out to itnternet
                            by default public
                            if we delete public VPC we have to call AWS and restore
                            <br/><br/>
                            VPC Peering
                            allows to connect VPC directly to private ip addres
                            VPC peering are like star peering. NO TRANSITIVE Peering

                            <br/>
                            <b>Other Points</b> <br/>
                            VPCs<br/>
                        - is a virtual network dedicated to users AWS account. <br/>
                        - an user can create a subnet with VOC and instances inside subnet. As long as there are no CIDR overlaps subnet can be created<br/>
                        - can route traffic between 2 VPCs<br/>
                        - can be used with IPV4 or IPV6 schemas<br/>
                        - can be from different accounts<br/>
                        <br/><br/>
                        to connect with ONPREM<br/>
                        - VPCA (ex: pvt vpc) needs  to have a virtual private gateway setup<br/>
                        - On prem( corporate env) should have customer gateway setup<br/>
                        - edit route table in VPC A to route traffic to onprem  env<br/>
                                
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>    
                    <div className="pad-top-10"></div>
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                VPC Exam Tips - Scenarios
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           
                            <AccordionItemPanel>
                            For given VPC details - <br/>
                            VPC - 10.0.0.0/16  <br/>
                            VPN Only subnet - 10.0.0.0/24<br/>
                            Data center     - 172.28.0.0/12 <br/>
                            <br/><br/>
                            <table cellpadding="0" cellspacing="0" border="1">
                            <tr>
                                <td> SNo</td>
                                <td> VPC Scenario</td>
                                <td> Custom Route Table</td>
                                <td> Main Route Table</td>
                            </tr>
                            <tr>
                            <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                            </tr> 
                            <tr>
                                <td>1</td>
                                    <td>VPC with a single public subnet</td>
                                    <td>10.0.0.0/16 local</td>
                                    <td>NOT APPLICABLE</td>
                            </tr> 
                            <tr>
                            <td></td>
                                    <td></td>
                                    <td>0.0.0.0/0  igw-id</td>
                                    <td></td>
                            </tr>
                            <tr>
                                    <td></td>
                                    <td>ex: User data center 172.28.0.0/12</td>
                                    <td>172.28.0.0/12 vgw-id</td>
                                    <td></td>
                            </tr>
                            <tr>
                            <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                            </tr> 
                            <tr>
                            <td>2</td>
                                    <td>VPC with public, private subnet</td>
                                    <td>10.0.0.0/16 local</td>
                                    <td>10.0.0.0/16 local</td>
                            </tr> 
                            <tr>
                            <td></td>
                                    <td></td>
                                    <td>0.0.0.0/0  igw-id</td>
                                    <td>0.0.0.0/0  NAT-gateway-id (NAT Instance Id)</td>
                            </tr>
                            <tr>
                            <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                            </tr> 
                            <tr>
                            <td>3</td>
                                    <td>VPC with public, private subnet</td>
                                    <td>10.0.0.0/16 local</td>
                                    <td>10.0.0.0/16 local</td>
                            </tr> 
                            <tr>
                            <td></td>
                                    <td>+ AWS Managed VPN Access</td>
                                    <td>0.0.0.0/0  igw-id</td>
                                    <td>0.0.0.0/0  vgw-id</td>
                            </tr>
                            <tr>
                            <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                            </tr>
                            <tr>
                            <td>4</td>
                                    <td>VPC with private ONLY subnet</td>
                                    <td>10.0.0.0/16 local</td>
                                    <td>NOT APPLICABLE</td>
                            </tr> 
                            <tr>
                            <td></td>
                                    <td>+ AWS Managed VPN Access</td>
                                    <td>0.0.0.0/0  vgw-id</td>
                                    <td></td>
                            </tr>
                            </table>
                                
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>  
                    <div className="pad-top-10"></div>
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                Sample steps for creating VPC
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           
                            <AccordionItemPanel>
                            1) Create VPC <br/><br/>
                                
                                    Name tag   - myVPC<br/>
                                    CIDR block - 10.0.0.0/16<br/>
                                    Tenancu    -  Default (or we can select "Dedicated")<br/><br/>

                                     Yes Create<br/><br/><br/>


                                        2) Subnets are not created by default<br/>
                                        3) Main Route table is created automatically<br/>
                                        4) default security group is created automatically<br/>
                                        5) default network ACL is created automatically<br/>
                                        6) Internet Gateway is not created by default<br/><br/>
                                
                            
                                2) Create Subnet<br/><br/>

                                    Name tag - 10.0.1.0-eu-central-1a<br/>
                                    VPC      - select the VPC we created myVPC from dropdown<br/>
                                    availability zone - eu-central-1a<br/>
                                    CIDR block        - 10.0.1.0/24<br/><br/>

                                     Yes Create<br/>

                                    note - ONE SUBNET EQUALs ONE AVAILABILITY ZONE<br/>

                                    aws reserves 3 addresses - 10.0.0.0 (for network addres), 10.0.0.1 (for router), 10.0.0.2<br/>
                            
                             <br/>
                             3) Create Subnet - 2nd subnet<br/><br/>
                                
                                    10.0.2-0-eu-ecntral-1b<br/>
                                    select the vpc from the drop down<br/>
                                    eu-central-1b<br/>
                                    10.0.2.0/24<br/><br/>

                                    To make the subnets public and private use internet gateway<br/>
                                
                            <br/>
                            4) Create Internet Gateway<br/><br/>
                                ex: name - myIGW<br/>

                                 Yes Create<br/>

                                by default its detached. Attach  a VPC - select "myVPC"<br/>

                                note we wil not be able to attach "myVPC" to another internet gateway<br/>
                            <br/>
                                5) Create Route<br/><br/>
                                <br/>
                                when we created the route a default route table was created.<br/>
                                we cannot make it accessible to internet since all subnets wil be public
                                <br/>
                                Create Route Table<br/>
                                Name - myPublicRoute<br/>
                                VP   - select "myVPC"<br/>
                                <br/>
                                create route out to internet<br/>
                                <br/>
                                "Routes" - Add another route<br/>
                                enter - 0.0.0.0/0<br/>
                                target - select "igw..."<br/>
                                <br/>
                                so this route table can be used as route out for internet accesible
                                <br/>
                                <br/>
                                "Subnet Associatios"
                                <br/>
                                select "10.0.1.0-eu-central-1a" - select<br/>
                                in "Subnet Actions"  modify "Auto-assign public IP"<br/>
                                <br/>
                                this can be enabled while we span a EC2 instance for the new VPC
                            
                            
                                
                            </AccordionItemPanel>
                        </AccordionItem>

                        <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    DNS Service and Hosted Zones
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           
                            <AccordionItemPanel>
                            Amazon Route 53 is a highly available and scalable Domain Name System (DNS) web service. <br/>
                            - domain registration<br/>
                            - DNS routing<br/>
                            - health checking<br/><br/>

                            Route53 as your DNS Service:<br/>
                                Amazon Route 53 when used as DNS service for your domain, such as example.com. <br/>
                                - it routes internet traffic to your website by translating friendly domain names like www.example.com into numeric IP addresses, like 192.0.2.1,<br/>
                                that computers use to connect to each other. <br/>
                                -  When someone types your domain name in a browser or sends you an email, a DNS query is forwarded to Route 53, which responds with the appropriate value. <br/>
                                - For example, Route 53 might respond with the IP address for the web server for example.com.<br/>
                                - DNS Service can be provided for the existing domain or can be configured to route for a new domain<br/>
                                ex: a new vpc can be associated to an existing route53 private hosted zone. DNS resolution for new vpc can be done by associating it with Route53 private zone and DHCP option set need not be changed. DNS forwarder can be installed on VPC and refer them from on-premise servers<br/>
                                - route 53 internal dns records work only if the dns request originates from within the vpc  <br/><br/>

                            Routing traffic to your resources:<br/>
                            - can be routed to public and private resources<br/>
                            - TCP and UDP records can be routed directly from Route53 using A records and spanned across multiple regions with latency based routing<br/>
                            - with latency based routing record set that resolves to ELB in each region, can set Evaluate Target Health flag set to true (yes)<br/><br/>

                            Public Hosted Zone:<br/>
                            - Create a public hosted zone if you want to route internet traffic to your resources,<br/>
                            - some of the options:<br/>
                            a) web servers behind ELB. route53 alias record pointing to elb dns name. <br/>
                            b) assign EIPs to all web servers. configure route53 "A" record set with all EIPs with health check and dns failover<br/>
                            c) serve the website from s3 bucket, map route53 alias record to website endpoint, create a route53 hosted zone, set NS records of the domain to use route53 name servers<br/><br/>

                            Private hosted zone:  <br/>
                            - Create a private hosted zone if you want to route traffic within an Amazon VPC.<br/>
                            - Container that holds info on how to route traffic for a domain and its subdomain within one or more VPC<br/>
                            - Ex: application using multi tier using internal ELB. create a private hosted record set with a hostname and alias record inside <br/><br/>
                            Combination can be used:<br/><br/>
                            - Split-View DNS: <br/>
                            publicly available website in S3. internally resolve using same DNS entry and can also be resolved publicly also (for same domain address)<br/>

                            <br/>
                            Notes:<br/>
                            - ensure dns resolution and dns hostnames are enabled on the target vpc<br/>
                            - install BIND dns server on your instance<br/>
                            - configure BIND server in a forward only configuration by modifying the named conf.options file. <br/>
                            by default BIND configuration files are under /etc/bind	<br/>
                            Steps:<br/>
                            1) Register a Domain<br/>
                            2) Create a hosted zone (Ex: Public hosted zone)<br/>
                            3) Create records for the hosted zone. <br/>
                            Records define where you want to route traffic for each domain name or subdomain name. For example, to route traffic for www.example.com to a web server in your data center, you typically create a www.example.com record in the example.com hosted zone.	<br/>
                            4) Create a hosted zone for the subdomain, and create records in the new hosted zone<br/>
                            ex: if you have blog.example.com (hosted zone), you can create a record - technical.blog.example.com that define how you want to route traffic for the subdomain <br/>
                            
                            <br/><br/>
                            How Amazon Route 53 routes traffic for your domain<br/>
                            <img src="https://docs.aws.amazon.com/Route53/latest/DeveloperGuide/images/how-route-53-routes-traffic.png"></img>

                            <br/>
                            Example Use Cases:<br/>
                            - ALIAS create an alias record at the top node of a DNS namespace, also known as the zone apex (example.com)<br/>
                            - Ex: Alias record : example.com to ELB (target)<br/>
                            - Ex: Alias record : www.example.com to ELB (target)<br/>
                            - CNAME - cannot create record for zone. can only do for www.example.com<br/>


                            <br/>    
                                <br/>
                                
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Concepts - Route 53, DNS, Health Checks
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           
                            <AccordionItemPanel>
                            
                            domain name: <br/>
                                such as example.com, that a user types in the address bar of a web browser to access a website or a web application<br/>

                                <br/>
                            domain registrar: <br/>
                                A company that is accredited by ICANN (Internet Corporation for Assigned Names and Numbers) to process domain registrations for specific top-level domains (TLDs). <br/>
                                For example, Amazon Registrar, Inc. is a domain registrar for .com, .net, and .org domains. <br/>
                                AWS's registrar associate, Gandi, is a domain registrar for hundreds of TLDs, such as .apartments, .boutique, and .camera.<br/>

                                <br/>
                            domain registry:<br/>
                            A company that owns the right to sell domains that have a specific top-level domain. For example, VeriSign

                            <br/>
                            domain reseller:<br/>
                            A company that sells domain names for registrars such as Amazon Registrar.<br/>

                            <br/>
                            top-level domain (TLD)<br/>
                            The last part of a domain name, such as .com, .org, or .ninja<br/>
                            can be general top level or geographic top level<br/>

                            <br/>
                        Health checking concepts:<br/>
                            DNS failover:<br/>
                            A method for routing traffic away from unhealthy resources and to healthy resources. <br/>
                            When you have more than one resource performing the same function—for example, more than one web server or mail server—you can configure Route 53 health checks to check the health of your resources and configure records in your hosted zone to route traffic only to healthy resources.<br/>

                            <br/>
                            endpoint<br/>
                            The resource, such as a web server or an email server, that you configure a health check to monitor the health of. <br/>
                            You can specify an endpoint by IPv4 address (192.0.2.243), by IPv6 address (2001:0db8:85a3:0000:0000:abcd:0001:2345), or by domain name (example.com).<br/>
                            NOTE: You can also create health checks that monitor the status of other health checks or that monitor the alarm state of a CloudWatch alarm.<br/>

                            <br/>
                            health check<br/>
                            A Route 53 component that lets you do the following:<br/>
                            - Monitor whether a specified endpoint, such as a web server, is healthy<br/>
                            - Optionally, get notified when an endpoint becomes unhealthy<br/>
                            - Optionally, configure DNS failover, which allows you to reroute internet traffic from an unhealthy resource to a healthy resource<br/>

                            <br/>
                        Domain Name System (DNS) concepts:<br/>
                            <br/>
                            IP address<br/>
                            A number that is assigned to a device on the internet<br/>

                            <br/>
                            Domain Name System (DNS)<br/>
                            A worldwide network of servers that help computers, smart phones, tablets, and other IP-enabled devices to communicate with one another. <br/>
                            The Domain Name System translates easily understood names such as example.com into the numbers, known as IP addresses, that allow computers to find each other on the internet.<br/>

                            <br/>
                            name servers<br/>
                            Servers in the Domain Name System (DNS) that help to translate domain names into the IP addresses that computers use to communicate with one another.<br/>

                            <br/>
                            private DNS<br/>
                            A local version of the Domain Name System (DNS) that lets you route traffic for a domain and its subdomains to Amazon EC2 instances within one or more Amazon virtual private clouds (VPCs).<br/>

                            <br/>
                            record (DNS record)<br/>
                            An object in a hosted zone that you use to define how you want to route traffic for the domain or a subdomain. <br/>
                            For example, you might create records for example.com and www.example.com that route traffic to a web server that has an IP address of 192.0.2.234.<br/>

                            <br/>
                            DNS resolver<br/>
                            A DNS server, often managed by an internet service provider (ISP), that acts as an intermediary between user requests and DNS name servers<br/>

                            <br/>
                            Recursive Name Server<br/>
                            A DNS resolver is also known as a recursive name server because it sends requests to a sequence of authoritative DNS name servers until it gets the response (typically an IP address) that it returns to a user's device, for example, a web browser on a laptop computer.<br/>


                            <br/>
                            alias record<br/>
                            A type of record that you can create with Amazon Route 53 to route traffic to AWS resources<br/>
                            ex: to cloudfront or S3 buckets<br/>

                            <br/>
                            authoritative name server<br/>
                            A name server that has definitive information about one part of the Domain Name System (DNS) and that responds to requests from a DNS resolver by returning the applicable information<br/>
                            ex: Route53 name servers<br/>

                            <br/>
                            DNS query<br/>
                            Usually a request that is submitted by a device, such as a computer or a smart phone, to the Domain Name System (DNS) for a resource that is associated with a domain name<br/>

                            <br/>
                            hosted zone<br/>
                            A container for records, which include information about how you want to route traffic for a domain (such as example.com) and all of its subdomains (such as www.example.com, retail.example.com, and seattle.accounting.example.com). <br/>
                            A hosted zone has the same name as the corresponding domain.<br/>

                            <br/>
                            reusable delegation set<br/>
                            A set of four authoritative name servers that you can use with more than one hosted zone. <br/>
                            By default, Route 53 assigns a random selection of name servers to each new hosted zone. <br/>
                            To make it easier to migrate DNS service to Route 53 for a large number of domains, you can create a reusable delegation set and then associate the reusable delegation set with new hosted zones. <br/>
                            You can't change the name servers that are associated with an existing hosted zone.<br/>

                            <br/>
                            subdomain<br/>
                            A domain name that has one or more labels prepended to the registered domain name. <br/>
                            For example, if you register the domain name example.com, then www.example.com is a subdomain<br/>

                            <br/>
                            time to live (TTL)<br/>
                            The amount of time, in seconds, that you want a DNS resolver to cache (store) the values for a record before submitting another request to Route 53 to get the current values for that record. <br/>
                            If the DNS resolver receives another request for the same domain before the TTL expires, the resolver returns the cached value.<br/>

                            <br/>
                            routing policy<br/>
                            A setting for records that determines how Route 53 responds to DNS queries. Route 53 supports the following routing policies:<br/>
                                Simple routing policy – Use to route internet traffic to a single resource that performs a given function for your domain, <br/>
                                for example, a web server that serves content for the example.com website.<br/>
                                - Failover routing policy – Use when you want to configure active-passive failover.<br/>
                                - Geolocation routing policy – Use when you want to route internet traffic to your resources based on the location of your users.<br/>
                                - Geoproximity routing policy – Use when you want to route traffic based on the location of your resources and, optionally, shift traffic from resources in one location to resources in another.<br/>
                                - Latency routing policy – Use when you have resources in multiple locations and you want to route traffic to the resource that provides the best latency.<br/>
                                - Multivalue answer routing policy – Use when you want Route 53 to respond to DNS queries with up to eight healthy records selected at random.<br/>
                                - Weighted routing policy – Use to route traffic to multiple resources in proportions that you specify.<br/>

                                <br/>                                
                                Complex configurations - <br/>
                                combination of alias records (such as weighted alias and failover alias) and non-alias records to build a decision tree that gives you greater control over how Route 53 responds to requests<br/>
                                

                                
                                <br/>
                            </AccordionItemPanel>
                        </AccordionItem>

                    </Accordion>    

                    </Accordion>  
                      
                </div>              
            </div>
           
          </div>
      </div>
    );
  }
}
 
export default VPC;