import React, { Component } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import { ExpandMore } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';

import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import GenAIPractitioner  from "../common/GenAIPractitioner";
import 'react-accessible-accordion/dist/fancy-example.css';


const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

const AccordionSummary = withStyles({
  content: {
    flexGrow: 0
  }
})(MuiAccordionSummary);

// const [expand, setExpand] = useState(0);
const toggleAcordion = () => {
  //setExpand((prev) => !prev);
  alert(this.state.expanded);
  this.setState({ expanded : !this.state.expanded})
};


class HomeCloudOpsGuru extends Component {

  constructor(props){
    super(props)
        this.state = {
          expanded: true
        }
        //this.toggleAcordion = this.toggleAcordion.bind(this)
    } 

  openUrl(value){
      window.open(value, false);
  }
      
      
  render() {
    return (
      <div className="pad-top-30 pad-left-page">
          <div class="row">
            <div class="col-sm-7 col-md-5">
                  <div class="">
                    <h2> AWS Certification</h2>        
                  <Accordion expanded={this.state.expanded}>
                        <AccordionSummary expandIcon={<ArrowDownwardIcon />} onClick={this.toggleAcordion} >
                            <b>AWS Certification</b>
                        </AccordionSummary>
                        <AccordionDetails expandIcon={<ExpandMore />}>
                            <div>
                            Hi Guys, with inspiration from ACloud.Guru and Jayendrapatil.com, I thought will provide a AWS help/tips for exam aspirants.<br/>
                                <br/>
                                AWS Solution Architect/Developer/SysOpsAdmin – Associate Exam Resources <br/>
                                AWS Devops/Solutions Architect - Professional <br/>
                                AWS Security Specialty <br/>
                                <br/><br/>
                                - Learnt the CSA, CDA, SysOps course from ACloud.Guru. This is a very good material which covers 50/60 % for the exams <br/>
                                - Udemy.com - Did some lessons and & exams - This is similar to ACloud.Guru<br/>
                                - aws.training - Took practice exams for CSA, CDA, SysOps Associate <br/>
                                - Whizlabs.com - Took exams for CSA, CDA, SysOps Associate<br/>
                                - theCertSchool.com - Took exams for CSA, CDA, SysOps Associate <br/>
                                - devcertguru.com, aiotestking.com - These have a ton of sample exams which does really help <br/>
                                
                                <br/>
                                <font color='red'>NOTE:</font> I started this for fun with sole purpose for tracking few tips and tricks that i could brush through while i rush for the exams
                                The idea was not to provide this as a comprehensive website/solution or a learning portal.
                                Nothing comes as close as learning from AWS Documentation.
                                <br/><br/>
                                Happy Coding... CloudOpsGurus !!!
                                <br/>
                            </div>      
                        </AccordionDetails>
                    </Accordion>             
                    
                   
                    <br/><br/>
                    <GenAIPractitioner></GenAIPractitioner>
                    <br/><br/>
                </div>              
            </div>
           
          </div>
      </div>
    );
  }
}
 
export default HomeCloudOpsGuru;