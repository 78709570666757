import React, { Component } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

class DNSRoute53 extends Component {

    openUrl(value){
        window.open(value, false);
      }
      
      
  render() {
    return (
      <div className="pad-top-30 pad-left-page">
          <div class="row">
            <div class="col-sm-7 col-md-5">
                  <div class="">
                    <h2>DNS / Route53</h2>                    
                    

                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                DNS / Route53
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           
                            <AccordionItemPanel>
                                	  

                            dns is like a phone book. ex: John Doe - 972 xxx xxxx. dns are human friendly names ex: https://codeandtech.com for an ip
                            <br/>
                            elastic load balancer always use dns name does not have IPv4 or IPv6
                            IP addresses are 2 forms - IPV4 and IPV6
                            <br/>
                            IPv4 - old school ip address. 32 bit field and has 4 billing addresses. everytime we add new devices new ips are required. so we ran out of it
                            <br/>
                            IPv6 - has address space of 128 bit - 340 undecillion addresses.
                            <br/>
                            amazon VPCs instances are IPv6 compatible
                            <br/>
                            top level domains - ex: .com, .edu, .gov
                            <br/>
                            second level domain - ex: .co.uk, .gov.uk (.co and .gov in this case)
                            <br/>
                            are controlled by Internet Assigned Numbers Authority (IANA), recently .cloud domain name is available
                            <br/>
                            <br/>
                            <b>Domain Registrars</b>
                            domain names are unique. a registrar is an authority that can assign domain names directly under  on or more top-level domains
                            each domain name becomes registered in a central database known as  "WhoIS" database
                            popular domain registrars - GoDaddy.com, 123-reg.co.uk etc., amazon has a domain registrar - integrated in aws console
                            <br/>
                            <li>
                            SOA Records
                            start of authority records
                            - name of the server that the supplied the data for the zone
                            - admin of the zone, who/email etc.,
                            - current version of the data file
                            - no of seconds a secondary name server should wait before checking for updates
                            - no of seconds a secondary name server should wait before retrying a failed zone transfer
                            - max no of secs that a secondary name server can use data before it must be either refreshd or expire
                            - default # of seconds for time-to-live
                            </li><br/>
                            <li>
                            NS Records
                            Name server records. used by top level domain servers to direct traffice to the content DNS server
                            </li><br/>
                            <li>
                            A records
                            fundamental type of DNS. - "A" stands for "Address"
                            </li><br/>
                            <li>
                            TTL records
                            time to leave - length of time dns record is cached either on resolving server or the users own local pc
                            </li><br/>
                            <li>
                            CNAMES
                            A canonical name CNAMe can be used to resolve one domain name to another. ex: http://m.acloud.guru or http://mobile.acloud.guru
                            </li><br/>
                            <li>
                            Alias Records
                            alias records with AWS and route 53. very similar to CNAME. this will map reserved name ex to map one dns name to another
                            ex: www.example.com to another target DNS elb1234.elb.amazonaws.com
                            <br/><br/>
                            key difference - CNAME cannot be used for naked domain names (domain name without "www" / zone apex). you cannot have a CNAME for http://acloud.guru. it must be 
                            either an A record or an Alias.
                            <br/>
                            alias record automatically maps your naked domain name or zone apex to elastic load balancer
                            <br/>
                            ex: example.com points to ELB load balancer - lb1-1234.us-east-1.elb.amazonaws.com
                            if the ip address of the load balancer changes, amazon route 53 will automatically reflect those changes in dns answers for example.com
                            without any changes to the hosted zone that contains resource record sets for example.com
                            <br/><br/>
                            </li><br/>
                            
                        
                            TIP - choose ALIAS RECORD vs CNAME . Alias record does not incur charge <br/>
                                
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>    
                    <div className="pad-top-10"></div>
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                Route53 routing policies
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           
                            <AccordionItemPanel>
                            route 53 is a global service. applies whole across like IAM
                            
                                <li>simple
                                default routing policy. ex: single resource (one web server) that performs a given function for your domain
                                dns resolving to a load balancer.ex: helloclougurus.com mapped to elb (with 2 servers) with simple routing policy
                                </li><br/>
                                <li>
                                weighted
                                ex: 20% to us-east-1 and 80% to us-west-1. ex: route 10% to a new testing site. remaining to another site
                                it need not be in different region. it can be for different load balancers on same region
                                ex: 80% to london elb and 20% to sydney elb
                                </li><br/>   
                                <li>
                                latency
                                allows you to route based on lowest network latency for your end user
                                first need to create latency based resource record set for that region, when route 53 receives a query for the site
                                it selects the latency resource set for that region that gives the user the lowest latency. responds with the value associated
                                wit that resource record set
                                </li><br/>
                                <li>
                                failover
                                similar to active/passive set up. primary as eu-west-2 and secondary as ap-southeast-2
                                route 53 checks the health check and if it fails then it routes to seconday
                                </li><br/>
                                <li>
                                geolocation
                                lets you chosse where the tracfic will be sent based on geo loc of users (location from which dns queries originate)
                                </li><br/>`
                                
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>  
                      
                </div>              
            </div>
           
          </div>
      </div>
    );
  }
}
 
export default DNSRoute53;