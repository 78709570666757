import React, { Component } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

class EBSAndStorageGateway extends Component {

    openUrl(value){
        window.open(value, false);
      }
      
      
  render() {
    return (
      <div className="pad-top-30 pad-left-page">
          <div class="row">
            <div class="col-sm-7 col-md-5">
                  <div class="">
                    <h2>EBS and Amazon Storage Gateway</h2>                    
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                Elastic Block Storage (EBS)
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           
                            <AccordionItemPanel>
                            EBS - allows you to create storage volumns and attach them to amazon EC2 instances. this is a block based storage. 
                            install OS, dB, applications etc., EBS volumes are placed in a specific availability zone where they are automatically replicated 
                            to protect you

                            EBS Volume Types
                            
                            EBS - two types of volume types
                            - SSD - transactional loads 
                                - Provisioned IOPS SSD
                                - General Purpose (gp2)
                            <br/>
                            - 
                            HDD - for throughput intensive workloads
                            - Throughput Optimized HDD(st1) - frequently accessed, with large datasets,I/O. ex:map reduce, kafka, log processing, data warehouse, ETL
                                these measure up in MB/S. can burst upto 250 MB/s
                            - Cold HDD(sc1) - provides lowest cost per GB. ideal for less frequently accessed workloads with large, cold datasets
                            <br/>  

                            General purpose SSD (GP2) - up to 10000 IOPS
                            <br/>
                            general purpose balances price and perf. ratio of 3IOPS per GB with upto 10000 IOPS 
                            <br/>
                            Provisioned IOS SSD(I01)
                            -  designed for I/O intensive apps such as large relational or NOSQL databases<br/>
                            -  use if you need more than 10000 IOPS<br/>
                            -  can go up to 20000 IOPS<br/>
                            <br/>
                            - 
                            Magnetic Storage
                            Throughput Optimized HDD (ST1)
                            - Frequently accessed workloads
                            -  Big Data
                            - Data Warehouses
                            - Log Processing
                            - cannot be a boot volume
                            <br/>
                            - 
                            Cold HDD (SC1)
                            - lowest cost storage for infrequently accessed workloads
                            - File Server
                            - cannot be a boot volume
                            <br/>
                            - 
                            Magnetic (standard)
                            - lowest cost per gigabyte of all EBS 
                            - bootable
                            - where data is accessed infrequently
                            <br/>
                            
                            you cannot mount 1 EBS volume to multiple EC2 instances instead use EFS
                            <br/>

                            EBS volumes can be changed on the fly(except for magnetic standard). magnetic standard stop the Ec2 instance & change the volume
                            <br/>
                            best practice: always stop ec2 instance. take snapshot and create a new volume
                            change of volume on the fly (change size or type) - must wait for 6 hours before making another change. it has to recheck
                            you can scale EBS volumes up only. cannot go down
                            volumes must be in same availability zone as EC2 instances
                            <br/>
                            <br/>
                            ebs - we can stop and start instances. DeleteOnTerminate flag to "N" to persis outside the life of the instance.
                            <br/>
                            local instances - only persist during life of the instance.
                            <br/>

                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>    
                    <div className="pad-top-10"></div>

                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                RAID, volumes & snapshots
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           
                            <AccordionItemPanel>
                                            
                                - RAID - redundant array of independent disks. putting whole bunch of disks together
                                <br/>
                                - 
                                RAID 0 - most common, ex: gaming pcs, striped , no redundancy, good performance. ex: 3 or 4 disks together. lose 1 lose all
                                <br/>
                                - 
                                RAID 1 - mirrored, redundancy. one disk mirrord to other. so wil not use data
                                <br/>
                                - 
                                RAID 5 - 3 disks or more. writing parity/checksum . amazon discourages RAID 5.. good for reads , bad for writes. 
                                <br/>
                                - 
                                RAID 10 - combination of 0 and 1. striped and mirrored. good redundancy, good performance
                                <br/>
                                <br/>
                                when we require disk IO. ex:database like sandra not supported by AWS. so add multiple volumes like raid array
                                when there is a single volume and expect better IO. typically raid 0 or 10
                                <br/>

                                In windows disk management > delete add the disk volumes, right click "Create Striped Volume" > add all the drives. Ex: assign it to "D" drive. Now all the drives (D, E,F, G) are assigned into new "D" RAID Drive
                                This willl help improve IO performance
                                <br/><br/>
                                Snapshot of a RAID Array: how to take snapshot since multiple volumes are added in  a raid array
                                <br/>
                                
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>    
                    <div className="pad-top-10"></div>
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                SNAPSHOT / EBS / Instance store tips
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           
                            <AccordionItemPanel>
                                                    
                            - to create a snapshot we should always stop the instance
                            
                            - 
                            snapshots of encrypted volumnes are encrypted automatically
                            <br/>
                            - 
                            volumes restored from encrypted snapshotta are encrypted
                            <br/>
                            - 
                            you can share snapshots but only if they are unencrypted
                            <br/>
                            - 
                            snapshots can be shared with other AWS accounts or made public
                            <br/>
                            - 
                            ex: might have a base web app server that can be made as snapshot and used globally
                        <br/>
                            - 
                            AMI Types - EBS vs Instance Store (Ephemeral Store)
                        <br/>
                            - 
                            AMI based on region, OS, architecture, storage of root device
                        <br/>
                            - 
                            All EMIS are either EBS or instance store
                        <br/>
                            - 
                            Instance store - we cannot START/STOP the instance. Only reboot and terminate
                            <br/>
                            - 
                            EBS - We can start, stop, reboot, terminate. Start and stop will let restart the hypervisor if there is a failed instance. EBS backed volumes 
                        <br/>
                            - 
                            EBS
                                is an amazon EBS volume created from an amazon EBS snapshot
                                Fast provisioning
                                Start, stop, reboot, terminate
                                Will not lose data
                                On terminate will not lose data. We can preserve data

                        <br/>
                            - 
                            Instance Store (also known as Ephemeral storage)
                                is an instance store volume created from a template stored in amazon S3
                                Could be slow while spinning
                                Reboot and terminate only
                                Will lose data on failure
                                On terminate will lose data

                                <br/>
                            
                                
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>  
                   
                    <div className="pad-top-10"></div>
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                Amazon Storage Gateway
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           
                            <AccordionItemPanel>
                                                        
                                is a service that connects an on premise software appliance with cloud based storage to provide seamless and secure integration between an organizations on premises IT env and AWS's storage infrastructure. 
                                The service enables you to securely store data to the AWS cloud for scalable and cost-effective storage
                                <br/><br/>
                                AWS Storage gateway's software appliance is available for dowload as a VM image that you install on a host in your data center
                                <br/>
                                Storage gateway supports either Vmware ESXi or Microsoft Hyper-V. once you installed your gateway and associated it with your AWS account through activation process, you can use the AWS management console to create the storage gateway option that is right for you
                                <br/>

                                Four types
                                <br/>
                                - 
                                File Gateway (NFS) - store flat file in S3 - Newest. Not operating system. Just like files, documents 
                                <br/>
                                - 
                                Volumes Gateway (iSCSI) - block based. Virtual hard disk running on sql/my sql. Not stored in S3
                                    - Stored Volumes - store entire copy of data on premise and aync backed up by S3
                                    - Cached Volumes - most recent/accessed data
                                <br/>
                                - 
                                Virtual Tape Gateway (VTL)  - back up and archiving solutions. To S3. use life cycle policies and can send to Glacier. popular backup applications like netbackup, backup exec, veam
                                <br/>
                                - 
                                File Gateway
                                Files are stored in s3 bucket and accessed through NFS mount point - ownership, permission, timestamps are stored in S3  in user meta data of the object associated

                                <br/>
                                - 
                                
                                Volume Gateway - virtual storage
                                Presents your apps with disk volumes using uSCSI block protocol.
                                Data written to these are asynchronously backed up / snapshots and stored in cloud
                                Incremental snapshots. All snapshot storage is also compressed to minimize your storage
                                <br/>
                                - 
                                

                                Volume Gateway - Stored Volumes - 100% complete copy of data
                                    Stores all the data 1GB to 16TB in size
                                <br/>
                                - 
                                
                                Volume Gateway - Cached Volumes
                                    Cached volumes most recent data on prem. rest of data is stored in S3. 1GB to 32TB
                                <br/>
                                - 
                                
                                Volume Gateway - Tape Gateway
                                    Tape gateway - used for backups. uses virtual tape based backup. supported by net backup, backup exec, veam. virtual tapes are sent to S3
                                <br/>
                                
                                
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>  
                    <div className="pad-top-10"></div>
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                EFS - elastic file system
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           
                            <AccordionItemPanel>
                            is a file storage service for EC2 instances. provides simple interface that allows you to create and configure
                            <br/>
                                file systems quickly and easily. storage capacity is elastic, growing and shrinking automatically as you add and
                                <br/>
                            remove files so your applications have the storage they need when they need it
                            
                            - - supports NFSv4 - network file system version protocol
                            <br/>
                            - 
                            - only pay for the storage you use - ex: 30 cents per gig
                            <br/>
                            - 
                            - can support thousands of concurrent nfs connections
                            <br/>
                            - 
                            - data is stored across multiple AZz within a region
                            <br/>
                            - 
                            - read after write consistency
                            <br/>
                            EFS is block based - can put files and share
                            <br/>
                            EFS essentially file server. centralized repository within your ec2-instances. all basically accessing same files
                            aply user level/directory level permissions
                            <br/>
                            ex: user payroll . restrict at file level and directory level
                            <br/>

                            EFS allows multiple EBS instances whereas EBS allows single instances
                            
                            
                            <br/><br/>
                            - <a href="#SnapshotsEncrptionVolumesExamTips" onclick="#">Snapshots Encrption Volumes Exam Tips</a>	<br/><br/>
                            
                                
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>  
                </div>              
            </div>
           
          </div>
      </div>
    );
  }
}
 
export default EBSAndStorageGateway;