import React, { Component } from "react";
import S3 from "./S3";
import RDS from "./RDS";
import DynamoDB from "./DynamoDB";
import CloudFront from "./CloudFront";
import TypoGraphy from '@material-ui/core/Typography'
import { Grid, Paper, Typography, List, ListItem } from "@material-ui/core";


class Storage extends Component {

  constructor(props){
    super(props)
       window.clickLog("load", "Storage")    
  } 

    openUrl(value){
        window.open(value, false);
      }
      
      
  render() {
    return (
      <div>
        <div style={{ marginTop: 20, padding: 30 }}>
            <Grid container spacing={40} justify="center">
                <TypoGraphy variant="h4">
                    <center>Storage</center>
                </TypoGraphy>
            </Grid>
          <div className="pad-left-page">
            <S3></S3>
            <div className="pad-top-10"></div>
            <RDS></RDS>
            <div className="pad-top-10"></div>
            <DynamoDB></DynamoDB>
            <div className="pad-top-10"></div>
            <CloudFront></CloudFront>
          
        </div>
        </div>
      </div>
    );
  }
}
 
export default Storage;