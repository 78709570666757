import React, { Component } from "react";
import ElasticBeanstalk from "./ElasticBeanstalk";
import CloudFormation from "./CloudFormation";
import OpsWorks from "./OpsWorks";
import TypoGraphy from '@material-ui/core/Typography'
import { Grid, Paper, Typography, List, ListItem } from "@material-ui/core";


class DeployOrchestration extends Component {

  constructor(props){
    super(props)
       window.clickLog("load", "DeployOrchestration")    
  } 

    openUrl(value){
        window.open(value, false);
      }
      
      
  render() {
    return (
      <div>
        <div style={{ marginTop: 20, padding: 30 }}>
            <Grid container spacing={40} justify="center">
                <TypoGraphy variant="h4">
                    <center>Deployment Orchestration</center>
                </TypoGraphy>
            </Grid>
            <div className="pad-left-page">
                <ElasticBeanstalk></ElasticBeanstalk>
                <div className="pad-top-10"></div>
                <CloudFormation></CloudFormation>
                <div className="pad-top-10"></div>
                <OpsWorks></OpsWorks>
                
              
            </div>
        </div>
      </div>
    );
  }
}
 
export default DeployOrchestration;