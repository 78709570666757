import React, { Component } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import { ExpandMore } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';

import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';


const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

const AccordionSummary = withStyles({
  content: {
    flexGrow: 0
  }
})(MuiAccordionSummary);



const servicesFile = `
  <!DOCTYPE html>
  <html>

    <head>
        <style>
            /* table td {<br />
                    border-top: thin solid;<br />
                    border-left: thin solid;<br />
                    border-right: thin solid;<br />
                    border-bottom: thin solid;<br />
                }
                table td:first-child {<br />
                    border-left: thin solid;<br />
                }
                table td:last-child {<br />
                    border-right: thin solid;<br />
                } */
            table.stats4 {
                text-align: center;
                font-family: Verdana, Geneva, Arial, Helvetica, sans-serif;
                font-weight: normal;
                font-size: 12px;
                color: #fff;
                background-color: #666;
                border: 0px;
                border-collapse: collapse;
                border-spacing: 7px;
            }

            table.stats4 td {
                background-color: #c9c4c4;
                color: #000;
                padding: 5px 7px 5px 7px;
                text-align: left;
                border: 1px #fff solid;
            }

            table.stats4 tr {
                background-color: #f19a9a;
                color: #000;
                padding: 5px 7px 5px 7px;
                text-align: left;
                border: 1px #fff solid;
            }

            table.stats4 td.hed {
                background-color: #666;
                color: #fff;
                text-align: left;
                border: 2px #fff solid;
                font-size: 12px;
                font-weight: bold;
            }

            tr.trHeader {
                background-color: #9f0404;
                color: #fff;
                text-align: left;
                border: 2px #fff solid;
                font-size: 12px;
                font-weight: bold;
            }
        </style>
    </head>

    <body>
        <div>
            <table class="stats4">
                <thead></thead>
                <tbody>
                    <tr class="trHeader">
                        <td>1</td>
                        <td>SNo

                        </td>
                        <td>Service

                        </td>
                        <td>Details

                        </td>
                        <td>Comments<br />

                        </td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>1

                        </td>
                        <td>SageMaker

                        </td>
                        <td>prepare data and build, train, and deploy machine learning (ML) models

                        </td>
                        <td>End to end Managed service<br />

                        </td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>

                        </td>
                        <td>SageMaker Studio

                        </td>
                        <td>single, web-based visual interface to perform all ML development steps

                        </td>
                        <td>prepare data and build, train, and deploy model, upload data, create new notebooks, train
                            and<br />
                            tune models, move back and forth between steps to adjust experiments, compare results, and<br />
                            deploy models to production
                            All ML development activities including notebooks,<br />
                            experiment management, automatic model creation, debugging and profiling, and model drift<br />
                            detection can be performed within the unified SageMaker Studio visual interface.<br />

                        </td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>

                        </td>
                        <td></td>
                        <td>

                        </td>
                        <td>

                        </td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td>

                        </td>
                        <td></td>
                        <td>

                        </td>
                        <td>

                        </td>
                    </tr>
                    <tr>
                        <td>6</td>
                        <td>

                        </td>
                        <td>SageMaker Data Wrangler

                        </td>
                        <td>For data preparation, transformation and feature engineering <br /> Prep tabular and image data
                            for<br />
                            ML <br /> Single interface for data selection, cleansing, exploration, visualization and<br />
                            processing <br /> Sql support and Data Quality tool

                        </td>
                        <td>Use case - music dataset, song ratings, listening duration<br />

                        </td>
                    </tr>
                    <tr>
                        <td>7</td>
                        <td>

                        </td>
                        <td></td>
                        <td>

                        </td>
                        <td>

                        </td>
                    </tr>
                    <tr>
                        <td>8</td>
                        <td>

                        </td>
                        <td></td>
                        <td>

                        </td>
                        <td>

                        </td>
                    </tr>
                    <tr>
                        <td>9</td>
                        <td>

                        </td>
                        <td></td>
                        <td>

                        </td>
                        <td>

                        </td>
                    </tr>
                    <tr>
                        <td>10</td>
                        <td>

                        </td>
                        <td>SageMaker Canvas

                        </td>
                        <td>No code interface <br /> Build/tune/train model using a visual interface <br /> Build your own
                            custom<br />
                            model using automl <br /> Leverage data wrangler

                        </td>
                        <td>visual drag-and-drop service that allows business analysts to build ML models and generate<br />
                            accurate predictions without writing any code or requiring ML expertise.
                            Use case<br />
                            Sentiment analysis<br />

                        </td>
                    </tr>
                    <tr>
                        <td>11</td>
                        <td>

                        </td>
                        <td>SageMaker Clarify

                        </td>
                        <td>For data preparation. <br /> Evaluate foundation models - compare Model A vs Model B <br />
                            Evaluate<br />
                            using human factors <br /> Use built in datasets or bring your own dataset <br /> Built inn
                            metrics<br />
                            and algorithms
                            Model Explainability - debug predictions. To increase the trust and<br />
                            understanding of the model

                        </td>
                        <td>To identify potential bias <br /> Bring your own employee or aws employee
                            Detect Bias<br />
                            (human) <br /> Specify input features and bias will be automatically detected<br />

                        </td>
                    </tr>
                    <tr>
                        <td>12</td>
                        <td>

                        </td>
                        <td>SageMaker Feature Store

                        </td>
                        <td>Store, share and manage features of ML models

                        </td>
                        <td>

                        </td>
                    </tr>
                    <tr>
                        <td>13</td>
                        <td>

                        </td>
                        <td>SageMaker Ground Truth, <br /> SageMaker Ground Truth Plus

                        </td>
                        <td>Labeling <br /> For RLHF - reinforcement learning from human feedback <br /> Model review<br />
                            customization and evaluation

                        </td>
                        <td>identify raw data, such as images, text files, and videos, and add informative labels to
                            create<br />
                            high-quality training datasets for your ML model<br />

                        </td>
                    </tr>
                    <tr>
                        <td>14</td>
                        <td>

                        </td>
                        <td>SageMaker Studio Notebooks

                        </td>
                        <td>Jupyter notebooks in SageMaker for the complete ML development

                        </td>
                        <td>

                        </td>
                    </tr>
                    <tr>
                        <td>15</td>
                        <td>

                        </td>
                        <td>SageMaker Studio Lab

                        </td>
                        <td>ML development environment

                        </td>
                        <td>that provides the compute, storage (up to 15 GB), and security<br />

                        </td>
                    </tr>
                    <tr>
                        <td>16</td>
                        <td>

                        </td>
                        <td>SageMaker HyperPod

                        </td>
                        <td>Train models

                        </td>
                        <td>purpose-built to accelerate foundation model (FM) training<br />

                        </td>
                    </tr>
                    <tr>
                        <td>17</td>
                        <td>

                        </td>
                        <td>SageMaker Experiments

                        </td>
                        <td>organize and track iterations to ML models

                        </td>
                        <td>

                        </td>
                    </tr>
                    <tr>
                        <td>18</td>
                        <td>

                        </td>
                        <td>SageMaker Debugger

                        </td>
                        <td>captures real-time metrics during training

                        </td>
                        <td>monitors CPUs, GPUs, network, and memory<br />

                        </td>
                    </tr>
                    <tr>
                        <td>19</td>
                        <td>

                        </td>
                        <td>SageMaker Serverless Inference

                        </td>
                        <td>deploy and scale ML models

                        </td>
                        <td>

                        </td>
                    </tr>
                    <tr>
                        <td>20</td>
                        <td>

                        </td>
                        <td>SageMaker Edge Manager

                        </td>
                        <td>Optimize, secure, monitor, and maintain ML models on fleets of edge devices

                        </td>
                        <td>smart cameras, robots, personal computers, and mobile devices<br />

                        </td>
                    </tr>
                    <tr>
                        <td>21</td>
                        <td>

                        </td>
                        <td>SageMaker Neo

                        </td>
                        <td>After training, use Neo to compile the model
                            train once and run anywhere in the cloud<br />
                            and at the edge

                        </td>
                        <td>supports the most popular DL models - AlexNet, ResNet, VGG, Inception, MobileNet,
                            SqueezeNet,<br />
                            and DenseNet models trained in MXNet and TensorFlow, and classification and random cut
                            forest<br />
                            models trained in XGBoost<br />

                        </td>
                    </tr>
                    <tr>
                        <td>22</td>
                        <td>

                        </td>
                        <td>SageMaker Model Monitor

                        </td>
                        <td>monitors the quality of Amazon SageMaker machine learning models
                            Monitors data/model<br />
                            quality, bias drift for models, feature attribution drift for models
                            Get alert for<br />
                            deviations. Either fix or retrain

                        </td>
                        <td>Continuous - real-time endpoint <br /> Continuous - batch transform job <br /> Scheduled -<br />
                            asynchronous batch transform jobs<br />

                        </td>
                    </tr>
                    <tr>
                        <td>23</td>
                        <td>

                        </td>
                        <td>SageMaker Model Registry

                        </td>
                        <td>Centralized repository allows to track/manage and version models <br /> Catalog models,
                            manage<br />
                            model versions, associate metadata with a model <br /> Manage approval status of a model,
                            automate<br />
                            model deployment, share models

                        </td>
                        <td>

                        </td>
                    </tr>
                    <tr>
                        <td>24</td>
                        <td>

                        </td>
                        <td>SageMaker Pipelines

                        </td>
                        <td>Process of building training and deploy <br /> CICD for ML <br /> Steps <br /> Processing,
                            training,<br />
                            tuning, automl,model, clarifycheck, quality check

                        </td>
                        <td>

                        </td>
                    </tr>
                    <tr>
                        <td>25</td>
                        <td>

                        </td>
                        <td>SageMaker Feature Store

                        </td>
                        <td>for sharing and managing variables (features) across multiple teams during model<br />
                            development
                            Ingests feature from variety of sources <br /> Can publish directly from<br />
                            sagemaker data wrangler into feature store

                        </td>
                        <td>

                        </td>
                    </tr>
                    <tr>
                        <td>26</td>
                        <td>

                        </td>
                        <td>SageMaker Model Cards

                        </td>
                        <td>Provide model documentation, not feature management.

                        </td>
                        <td>Use case - intended uses, risk ratings and training details<br />

                        </td>
                    </tr>
                    <tr>
                        <td>27</td>
                        <td>

                        </td>
                        <td></td>
                        <td>

                        </td>
                        <td>

                        </td>
                    </tr>
                    <tr>
                        <td>28</td>
                        <td>

                        </td>
                        <td>SageMaker JumpStart

                        </td>
                        <td>ML hub to find pretrained foundation model, computer vision models or nlp models <br /> for
                            quickly<br />
                            deploying and consuming a foundation model (FM) within a team's VPC. <br /> Models can be
                            fully<br />
                            customized or access prebuilt solutions and deployed

                        </td>
                        <td>Provides access to a wide range of pre-trained models and solutions that can be easily
                            deployed<br />
                            and consumed within a VPC. <br /> Designed to simplify and accelerate the deployment of
                            machine<br />
                            learning models, including foundation models.<br />

                        </td>
                    </tr>
                    <tr>
                        <td>29</td>
                        <td>

                        </td>
                        <td>SageMaker Role Manager

                        </td>
                        <td>Define role for personas

                        </td>
                        <td>Ex: data scientist, analyst<br />

                        </td>
                    </tr>
                    <tr>
                        <td>30</td>
                        <td>

                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                    </tr>
                    <tr>
                        <td>31</td>
                        <td>2

                        </td>
                        <td>Amazon Bedrock

                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                    </tr>
                    <tr>
                        <td>32</td>
                        <td>3

                        </td>
                        <td>Q

                        </td>
                        <td> generative AI–powered assistant for accelerating software development and leveraging
                            companies'<br />
                            internal data

                        </td>
                        <td>

                        </td>
                    </tr>
                    <tr>
                        <td>33</td>
                        <td>

                        </td>
                        <td>Q Developer

                        </td>
                        <td>Coding, testing, and upgrading applications, to diagnosing errors, performing security
                            scanning<br />
                            and fixes, and optimizing AWS resources

                        </td>
                        <td>

                        </td>
                    </tr>
                    <tr>
                        <td>34</td>
                        <td>

                        </td>
                        <td>Q business

                        </td>
                        <td>generative AI–powered assistant that can answer questions, provide summaries, generate
                            content,<br />
                            and securely complete tasks based on data and information in your enterprise systems

                        </td>
                        <td>

                        </td>
                    </tr>
                    <tr>
                        <td>35</td>
                        <td>

                        </td>
                        <td>Q for QuickSight

                        </td>
                        <td>unified business intelligence (BI)
                            multi-visual Q&amp;A responses, get AI-driven<br />
                            executive summaries of dashboards, and create detailed and customizable data stories<br />
                            highlighting key insights, trends, and drivers

                        </td>
                        <td>customers get a Generative BI assistant that allows business analysts to use natural language
                            to<br />
                            build BI dashboards in minutes and easily build visualizations and complex calculations<br />

                        </td>
                    </tr>
                    <tr>
                        <td>36</td>
                        <td>

                        </td>
                        <td></td>
                        <td>

                        </td>
                        <td>

                        </td>
                    </tr>
                    <tr>
                        <td>37</td>
                        <td>

                        </td>
                        <td></td>
                        <td>

                        </td>
                        <td>

                        </td>
                    </tr>
                    <tr>
                        <td>38</td>
                        <td>

                        </td>
                        <td>Q for Connect

                        </td>
                        <td>real-time conversation with the customer along with relevant company content to
                            automatically<br />
                            recommend what to say or what actions an agent should take to better assist customers.

                        </td>
                        <td>

                        </td>
                    </tr>
                    <tr>
                        <td>39</td>
                        <td>

                        </td>
                        <td>Q for Supply Chain

                        </td>
                        <td>inventory managers, supply and demand planners, and others will be able to ask and get<br />
                            intelligent answers about what is happening in their supply chain, why it is happening, and
                            what<br />
                            actions to take. They will also be able to explore what-if scenarios to understand the<br />
                            trade-offs between different supply chain choices

                        </td>
                        <td>

                        </td>
                    </tr>
                    <tr>
                        <td>40</td>
                        <td>

                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                    </tr>
                    <tr>
                        <td>41</td>
                        <td>3

                        </td>
                        <td>Amazon Comprehend

                        </td>
                        <td>For NLP <br /> Language, extracts key phrases, <br /> Custom classifier -- organize documents
                            into<br />
                            categories
                            Analyzes text using tokenization <br /> Supports text/pdf/word/images etc.,

                        </td>
                        <td>Text and Documents
                            Ex: analyze email, create group articles that comprehend will<br />
                            uncover <br /> Use case Custom entities - analyze text for specific terms, list of entities
                            Sentiment analysis<br />

                        </td>
                    </tr>
                    <tr>
                        <td>42</td>
                        <td>

                        </td>
                        <td>Amazon Translate

                        </td>
                        <td>Natural and accurate translate languages
                            Custom terminology - csv/tsv/tmx

                        </td>
                        <td>Text and Documents
                            Use cases - websites and applications, for international users <br />
                            Html/text documents from S3<br />

                        </td>
                    </tr>
                    <tr>
                        <td>43</td>
                        <td>

                        </td>
                        <td>Amazon Textract

                        </td>
                        <td>Extract text. Handwriting and data from any scanned documents using AI/ML
                            Ex: scan a<br />
                            image and read the text

                        </td>
                        <td>Text and Documents
                            Use cases - financial services. Health care, public sector (health<br />
                            forms etc.,_<br />

                        </td>
                    </tr>
                    <tr>
                        <td>44</td>
                        <td>

                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                    </tr>
                    <tr>
                        <td>45</td>
                        <td>4

                        </td>
                        <td>Amazon Rekognition

                        </td>
                        <td>Find objects, people, text, scenes in images and videos
                            Custom labels - identify/find<br />
                            your own pics/logos. Ex: NFL
                            Content moderation - detect inappropriate, unwanted,<br />
                            offensive content
                            Custom Moderation Adapters - extend rek capabilities by providing your<br />
                            own labeled set of images

                        </td>
                        <td>Vision
                            Use cases - labeling, content moderation, text detection, face detection and<br />
                            analysis (gender) <br /> Celebrity recognition
                            Filter out harmful images<br />

                        </td>
                    </tr>
                    <tr>
                        <td>46</td>
                        <td>

                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                    </tr>
                    <tr>
                        <td>47</td>
                        <td>5

                        </td>
                        <td>Amazon Kendra

                        </td>
                        <td>Document search service <br /> Extract answers from docs - text/pdf/html/ppt/word etc., <br />
                            Natural<br />
                            language search capabilities <br /> Creates knowledge index/powered by ML internally

                        </td>
                        <td>Search<br />

                        </td>
                    </tr>
                    <tr>
                        <td>48</td>
                        <td>

                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                    </tr>
                    <tr>
                        <td>49</td>
                        <td>6

                        </td>
                        <td>Amazon Lex

                        </td>
                        <td>Using voice and text <br /> Conversational ai with multiple languages <br /> Integrates with
                            lambda,<br />
                            Connect, comprehend, kendra

                        </td>
                        <td>Chatbots<br />

                        </td>
                    </tr>
                    <tr>
                        <td>50</td>
                        <td>

                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                    </tr>
                    <tr>
                        <td>51</td>
                        <td>7

                        </td>
                        <td>Amazon Polly

                        </td>
                        <td>Convert text to speech
                            Lexicons - <br /> - define how to read certain pieces of text <br />
                            ex: AWS =&gt; Amazon Web Services
                            SSML <br /> - Speech synthesis markup language <br /> -<br />
                            markup how the text should be pronounced
                            Voice engine <br /> - generative, neural<br />
                            standard
                            Speech mark <br /> - ex: lip syncing or highlight word as they are spoken <br />
                            encode where a sentence/word starts or ends in an audio

                        </td>
                        <td>Speech
                            Generative <br /> Long form <br /> Neural <br /> standard<br />

                        </td>
                    </tr>
                    <tr>
                        <td>52</td>
                        <td>

                        </td>
                        <td>Amazon Transcribe

                        </td>
                        <td>Convert speech to text <br /> Deep learning process called automatic speech recognition <br />
                            Removes<br />
                            PII using redaction <br /> Supports automatic language identification for multi lingual<br />
                            audio
                            Custom Vocabularies - Can capture domain specific/non-standard terms <br /> Provide<br />
                            hints to increase recognition
                            Custom language models (for context) - for domain specific

                        </td>
                        <td>Speech
                            Use cases - <br /> customer service calls, automate closed captioning/subtitling,<br />
                            generate meta data for media assets to create a fully searchable archive
                            Can transcribe<br />
                            multiple languages at the same time<br />

                        </td>
                    </tr>
                    <tr>
                        <td>53</td>
                        <td>

                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                    </tr>
                    <tr>
                        <td>54</td>
                        <td>8

                        </td>
                        <td>Amazon Personalize

                        </td>
                        <td>Ex: retail stores, media and entertainment

                        </td>
                        <td>Recommendation<br />

                        </td>
                    </tr>
                    <tr>
                        <td>55</td>
                        <td>

                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                    </tr>
                    <tr>
                        <td>56</td>
                        <td>9

                        </td>
                        <td>AWS DeepRacer

                        </td>
                        <td>Console to train and evaluate deep RL

                        </td>
                        <td>

                        </td>
                    </tr>
                    <tr>
                        <td>57</td>
                        <td>

                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                    </tr>
                    <tr>
                        <td>58</td>
                        <td>10

                        </td>
                        <td>Amazon Forecast

                        </td>
                        <td>ML to deliver highly accurate forecasts

                        </td>
                        <td>Use case - predict future sales <br /> Product demand planning, financial planning,
                            resource<br />
                            planning<br />

                        </td>
                    </tr>
                    <tr>
                        <td>59</td>
                        <td>

                        </td>
                        <td></td>
                        <td>

                        </td>
                        <td>

                        </td>
                    </tr>
                    <tr>
                        <td>60</td>
                        <td>

                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                    </tr>
                    <tr>
                        <td>61</td>
                        <td>11

                        </td>
                        <td>Amazon Mechanical Turk

                        </td>
                        <td>Crowdsourcing marketplace <br /> Distributed virtual workforce <br /> Integrates with Amazon
                            A2I,<br />
                            SageMaker Ground Truth etc.,

                        </td>
                        <td>Use case - label 1000000 images <br /> Data collection, business processing etc.,<br />

                        </td>
                    </tr>
                    <tr>
                        <td>62</td>
                        <td>

                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                    </tr>
                    <tr>
                        <td>63</td>
                        <td>12

                        </td>
                        <td>Amazon Augmented AI

                        </td>
                        <td>Human oversight of machine learning predictions in production

                        </td>
                        <td>Can be own employees or AWS/contractors<br />

                        </td>
                    </tr>
                    <tr>
                        <td>64</td>
                        <td>

                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                    </tr>
                    <tr>
                        <td>65</td>
                        <td>13

                        </td>
                        <td>Amazon Comprehend Medical and Transcribe

                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                    </tr>
                    <tr>
                        <td>66</td>
                        <td>

                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                    </tr>
                    <tr>
                        <td>67</td>
                        <td>14

                        </td>
                        <td>Amazon's Hardware for AI

                        </td>
                        <td>AWS Trainium - Trn1 instance
                            AWS Inferentia - ML chip built to deliver inference <br /> 4x<br />
                            throughput, 70% cost reduction
                            EC2, EBS,EFS, ELB, ASG <br /> EC2 user data/firewall

                        </td>
                        <td>EC2 GPU - P3, P4, P5,…. G3,.. G6<br />

                        </td>
                    </tr>
                    <tr>
                        <td>68</td>
                        <td>

                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                    </tr>
                    <tr></tr>
                </tbody>
            </table>
        </div>
        
    </body>

    </html>
`;

const MLFile = `
<!DOCTYPE html>
  <html>

    <head>
        <style>
            /* table td {<br />
                    border-top: thin solid;<br />
                    border-left: thin solid;<br />
                    border-right: thin solid;<br />
                    border-bottom: thin solid;<br />
                }
                table td:first-child {<br />
                    border-left: thin solid;<br />
                }
                table td:last-child {<br />
                    border-right: thin solid;<br />
                } */
            table.stats4 {
                text-align: center;
                font-family: Verdana, Geneva, Arial, Helvetica, sans-serif;
                font-weight: normal;
                font-size: 12px;
                color: #fff;
                background-color: #666;
                border: 0px;
                border-collapse: collapse;
                border-spacing: 7px;
            }

            table.stats4 td {
                background-color: #c9c4c4;
                color: #000;
                padding: 5px 7px 5px 7px;
                text-align: left;
                border: 1px #fff solid;
            }

            table.stats4 tr {
                background-color: #f19a9a;
                color: #000;
                padding: 5px 7px 5px 7px;
                text-align: left;
                border: 1px #fff solid;
            }

            table.stats4 td.hed {
                background-color: #666;
                color: #fff;
                text-align: left;
                border: 2px #fff solid;
                font-size: 12px;
                font-weight: bold;
            }

            tr.trHeader {
                background-color: #9f0404;
                color: #fff;
                text-align: left;
                border: 2px #fff solid;
                font-size: 12px;
                font-weight: bold;
            }
        </style>
    </head>

    <body>
        <div>
            <table class="stats4">
                <thead>
                <tbody>
                    <tr class="trHeader">
                        <td>1</td>
                        <td>SNo

                        </td>
                        <td>Type

                        </td>
                        <td>Used for

                        </td>
                        <td>Name

                        </td>
                        <td>Details

                        </td>
                        <td>Use Cases

                        </td>
                        <td>Comment
                        </td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>1

                        </td>
                        <td>Supervised Learning

                        </td>
                        <td>
                        </td>
                        <td>Linear Regression

                        </td>
                        <td>Model relationship&nbsp; between one or more input features <br />&nbsp; <br />One
                            output&nbsp;<br />
                            variable — target

                        </td>
                        <td>Historical sales data, output - no of units to be produced <br />Predict House prices,
                            stocks<br />
                            prices, sales volume etc.,

                        </td>
                        <td>
                        </td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>2

                        </td>
                        <td>Supervised

                        </td>
                        <td>
                        </td>
                        <td>Binary classification

                        </td>
                        <td>Binary outcome yes/no, true/false, +/-

                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>3

                        </td>
                        <td>Supervised

                        </td>
                        <td>
                        </td>
                        <td>Time series prediction

                        </td>
                        <td>forecasts future values based on past and present data

                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td>4

                        </td>
                        <td>Supervised

                        </td>
                        <td>
                        </td>
                        <td>Regression

                        </td>
                        <td>estimates <b>a continuous numerical value</b> based on the input features

                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                    </tr>
                    <tr>
                        <td>6</td>
                        <td>5

                        </td>
                        <td>Supervised

                        </td>
                        <td>
                        </td>
                        <td>recurrent neural network (RNN)

                        </td>
                        <td>type of neural network that can process sequential data. suited for predicting future
                            events<br />
                            based on past observations <br />&nbsp; <br />NOTE: CNN is for images and RNN is for timeseries.

                        </td>
                        <td>forecasting engine failures based on sensor readings

                        </td>
                        <td>TensorFlow, PyTorch, Keras, MXNet<br />

                        </td>
                    </tr>
                    <tr>
                        <td>7</td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>convolutional neural network (CNN)

                        </td>
                        <td>Classify an object amongst a group <br />&nbsp; <br />NOTE:&nbsp; CNN is for images and RNN is
                            for<br />
                            timeseries.

                        </td>
                        <td>an animal image as input and identify probability distribution of how likely amongst 10<br />
                            types of animals

                        </td>
                        <td>Softmax function transforms a a arbitrary real values&nbsp; into a range of<br />
                            (0,1) <br />&nbsp; <br />TensorFlow, PyTorch, Keras, MXNet<br />

                        </td>
                    </tr>
                    <tr>
                        <td>8</td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>WaveNet

                        </td>
                        <td>Generative model for raw audio <br />&nbsp; <br />WaveNet is a deep autoregressive CNN
                            with<br />
                            stacked layers of dilated convolution, used for generating speech. To deliver a more
                            human-like<br />
                            voice, <br />WaveNet: • 𝗠𝗼𝗱𝗲𝗹𝘀 𝘁𝗵𝗲 𝗿𝗮𝘄 𝘄𝗮𝘃𝗲𝗳𝗼𝗿𝗺 𝗼𝗳 𝗮𝘂𝗱𝗶𝗼<br />
                            𝘀𝗶𝗴𝗻𝗮𝗹𝘀, making the voice sound more natural and expressive <br />&nbsp; <br />In
                            WaveNet, the<br />
                            CNN takes a raw signal as an input and synthesises an output one sample at a time
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                    </tr>
                    <tr>
                        <td>9</td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>classification

                        </td>
                        <td>KNN (K nearest neighbor)

                        </td>
                        <td>finding the k most similar instances in the training data to a given query instance, and<br />
                            then predicting the output based on the average or majority of the outputs of the k
                            nearest<br />
                            neighbors <br />&nbsp; <br />handle time series data

                        </td>
                        <td>Ex: air quality data and predict for next 2 days based on last 2 year<br />
                            data <br />&nbsp; <br />Identify if imge has a logo amongst a larger group

                        </td>
                        <td>can perform both classification and regression tasks<br />

                        </td>
                    </tr>
                    <tr>
                        <td>10</td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>Latent Dirichlet Allocation (LDA)

                        </td>
                        <td>suitable for topic modeling tasks (in NLP) <br />&nbsp; <br />discover the hidden topics and
                            their<br />
                            proportions in a collection of text documents,

                        </td>
                        <td>&nbsp;news articles, tweets, reviews, etc

                        </td>
                        <td>Gensim, Scikitlearn, Mallet <br />&nbsp; <br />Not valid for images<br />

                        </td>
                    </tr>
                    <tr>
                        <td>11</td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>Factorization Machines (FM) Algorithm

                        </td>
                        <td>used for tasks dealing with high dimensional sparse datasets

                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                    </tr>
                    <tr>
                        <td>12</td>
                        <td>
                        </td>
                        <td>Unsupervised

                        </td>
                        <td>
                        </td>
                        <td>Topic Modeling

                        </td>
                        <td>Topic modeling is a type of statistical modeling that uses unsupervised Machine Learning
                            to<br />
                            identify clusters or groups of similar words within a body of text

                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                    </tr>
                    <tr>
                        <td>13</td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>BERT based models

                        </td>
                        <td>Google developed BERT to serve as a bidirectional transformer model that examines words<br />
                            within text by considering both left-to-right and right-to-left contexts <br />&nbsp;
                        </td>
                        <td>Missing words in&nbsp; text

                        </td>
                        <td>
                        </td>
                    </tr>
                    <tr>
                        <td>14</td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                    </tr>
                    <tr>
                        <td>15</td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                    </tr>
                    <tr>
                        <td>16</td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                    </tr>
                    <tr>
                        <td>17</td>
                        <td>
                        </td>
                        <td>Unsupervised

                        </td>
                        <td>
                        </td>
                        <td>Principal component analysis (PCA)

                        </td>
                        <td>reduce the dimensionality (number of features) within a dataset while still retaining as<br />
                            much <br />information as possible <br />&nbsp; <br />Used when the features are highly
                            correlated with<br />
                            each other

                        </td>
                        <td>
                        </td>
                        <td>Using finding a new set of features called components<br />

                        </td>
                    </tr>
                    <tr>
                        <td>18</td>
                        <td>6

                        </td>
                        <td>Unsupervised

                        </td>
                        <td>
                        </td>
                        <td>Random Cut Forest (RCF)

                        </td>
                        <td>assigns an anomaly score to each data point based on how different it is from the rest of<br />
                            the data

                        </td>
                        <td>Ex: realtime ingestion, identify anamoly/malicious events

                        </td>
                        <td>
                        </td>
                    </tr>
                    <tr>
                        <td>19</td>
                        <td>7

                        </td>
                        <td>Unsupervised

                        </td>
                        <td>
                        </td>
                        <td>Anomaly detection

                        </td>
                        <td>identifies outliers or abnormal patterns in the data

                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                    </tr>
                    <tr>
                        <td>20</td>
                        <td>8

                        </td>
                        <td>Unsupervised

                        </td>
                        <td>
                        </td>
                        <td>K-means clustering

                        </td>
                        <td>randomly assigning data points to a number of clusters, then iteratively updating the<br />
                            cluster centers and reassigning the data points until the clusters are stable<br />
                            <br />&nbsp; <br />result is a partition of the data into distinct and homogeneous groups

                        </td>
                        <td>exploratory data analysis, data compression, anomaly detection, and feature extraction

                        </td>
                        <td>
                        </td>
                    </tr>
                    <tr>
                        <td>21</td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>RMSE Root mean square error

                        </td>
                        <td>Goal - to predict a continuous value <br />&nbsp; <br />measures the average difference
                            between<br />
                            the predicted and the actual values
                        </td>
                        <td>Price of a house, temperature of a city

                        </td>
                        <td>Good for regression <br />NOT good fo classification<br />

                        </td>
                    </tr>
                    <tr>
                        <td>22</td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>regression

                        </td>
                        <td>MAPE Mean absolute percentage error

                        </td>
                        <td>Used for regression

                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                    </tr>
                    <tr>
                        <td>23</td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>ROC receiver operating characteristic (ROC) curve

                        </td>
                        <td>used to understand how different classification thresholds will impact the models<br />
                            performance. <br />&nbsp; <br />A ROC curve can show the trade-off between the True positive
                            rate TPR<br />
                            and the FPR for different thresholds

                        </td>
                        <td>predict whether or not a person will order a pizza

                        </td>
                        <td>
                        </td>
                    </tr>
                    <tr>
                        <td>24</td>
                        <td>9

                        </td>
                        <td>
                        </td>
                        <td>Classification (binary)

                        </td>
                        <td>Area Under ROC Curve (AOC)

                        </td>
                        <td>Compare/evaluate ML models <br />&nbsp; <br />AUC is calculated based on the Receiver
                            <br />Operating<br />
                            Characteristic (ROC) curve, which is a plot that shows the trade-off between the<br />
                            true <br />positive rate (TPR) and the false positive rate (FPR) of the classifier as the
                            decision<br />
                            threshold is <br />varied. The TPR, also known as recall or sensitivity
                        </td>
                        <td>Credit card transactions - identify 99k valid vs 1k fraudulent

                        </td>
                        <td>
                        </td>
                    </tr>
                    <tr>
                        <td>25</td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>Residual plots

                        </td>
                        <td>used to understand whether a <br />regression model is more frequently overestimating or<br />
                            underestimating the target

                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                    </tr>
                    <tr>
                        <td>26</td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>Confusion matrix

                        </td>
                        <td>table that shows the counts of true positives, false positives, true negatives, and false<br />
                            negatives for each class <br />&nbsp; <br />indicate the accuracy, precision, recall, and
                            F1-score of<br />
                            the model for each class,

                        </td>
                        <td>
                        </td>
                        <td>only applicable for classification models, not regression models. A confusion matrix
                            cannot<br />
                            show the magnitude or direction of the errors made by the model.<br />

                        </td>
                    </tr>
                    <tr>
                        <td>27</td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>Precision

                        </td>
                        <td>proportion of predicted positive cases that are actually positive. Precision is a useful<br />
                            metric when the cost of a false positive is high <br />Recall is not a good metric for
                            imbalanced<br />
                            classification problems

                        </td>
                        <td>fraudulent transactions <br />spam detection or medical diagnosis
                        </td>
                        <td>
                        </td>
                    </tr>
                    <tr>
                        <td>28</td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>Classification

                        </td>
                        <td>Recall

                        </td>
                        <td>Same as TPR (true positive rate) <br />Recall is a useful metric when the cost of a false<br />
                            negative is high <br />&nbsp; <br />Recall = True Positives / (True Positives + False Negatives)

                        </td>
                        <td>fraud detection or cancer diagnosis

                        </td>
                        <td>
                        </td>
                    </tr>
                    <tr>
                        <td>29</td>
                        <td>
                        </td>
                        <td>Supervised

                        </td>
                        <td>Classification (multi-class)

                        </td>
                        <td>XGBoost

                        </td>
                        <td>Can handle multiple features and multiple classes

                        </td>
                        <td>Categorize new products when a dataset/features is provided <br />Ex: with 15 features<br />
                            (title/weight/price) categorize books/games/movies etc from a dataset of 1200<br />
                            products <br />&nbsp; <br />&nbsp; <br />Credit card fraud detection (ex: with a large dataset
                            of<br />
                            historical data, find/predict new txns)

                        </td>
                        <td>can be used for classification, regression, ranking, and other tasks. It is based on the<br />
                            gradient boosting algorithm, which builds an ensemble of weak learners (usually decision
                            trees)<br />
                            to produce a strong learner<br />

                        </td>
                    </tr>
                    <tr>
                        <td>30</td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>classification

                        </td>
                        <td>Term frequency-inverse document frequency (TF-IDF)

                        </td>
                        <td>assigns a weight to each word in a document based on how important it is to the meaning of<br />
                            the document <br />&nbsp; <br />NOTE: The term frequency (TF) measures how often a word appears
                            in a<br />
                            document, while the inverse document frequency (IDF) measures how rare a word is across a<br />
                            collection of documents.

                        </td>
                        <td>&nbsp;
                        </td>
                        <td>
                        </td>
                    </tr>
                    <tr>
                        <td>31</td>
                        <td>&nbsp;

                        </td>
                        <td>&nbsp;

                        </td>
                        <td>Classification/ categorize

                        </td>
                        <td>Word2vec

                        </td>
                        <td>technique that can learn distributed representations of words, also known as word<br />
                            embeddings, from large amounts of text data <br />&nbsp; <br />&nbsp;

                        </td>
                        <td>when tuning parameters doesn't help a lot. Transfer learning would be better solution

                        </td>
                        <td>&nbsp;<br />

                        </td>
                    </tr>
                    <tr>
                        <td>32</td>
                        <td>&nbsp;

                        </td>
                        <td>&nbsp;

                        </td>
                        <td>&nbsp;

                        </td>
                        <td>Collaborative Filtering

                        </td>
                        <td>recommends products or services to users based on the ratings or preferences of other users

                        </td>
                        <td>customer shopping patterns and preferences based on demographics, past visits, and
                            locality<br />
                            information

                        </td>
                        <td>&nbsp;<br />

                        </td>
                    </tr>
                    <tr>
                        <td>33</td>
                        <td>&nbsp;

                        </td>
                        <td>&nbsp;

                        </td>
                        <td>&nbsp;

                        </td>
                        <td>Decision tree

                        </td>
                        <td>perform classification tasks by splitting <br />the data into smaller and purer subsets
                            based<br />
                            on a series of rules or conditions

                        </td>
                        <td>binary classifier based on two features: age of account and transaction month

                        </td>
                        <td>both linear and non-linear data, and can capture complex patterns and interactions<br />
                            among <br />the features<br />

                        </td>
                    </tr>
                    <tr>
                        <td>34</td>
                        <td>&nbsp;

                        </td>
                        <td>&nbsp;

                        </td>
                        <td>&nbsp;

                        </td>
                        <td>&nbsp;

                        </td>
                        <td>&nbsp;

                        </td>
                        <td>&nbsp;

                        </td>
                        <td>&nbsp;<br />

                        </td>
                    </tr>
                    <tr>
                        <td>35</td>
                        <td>&nbsp;

                        </td>
                        <td>Preprocessing technique

                        </td>
                        <td>&nbsp;

                        </td>
                        <td>Data normalization

                        </td>
                        <td>Scale the feature to a common range (0,1) or (-1,)

                        </td>
                        <td>&nbsp;

                        </td>
                        <td>min-max scaling, z-score standardization, or unit vector <br />normalization<br />

                        </td>
                    </tr>
                    <tr>
                        <td>36</td>
                        <td>&nbsp;

                        </td>
                        <td>Preprocessing technique

                        </td>
                        <td>&nbsp;

                        </td>
                        <td>Dimensionality reduction

                        </td>
                        <td>Reduce number of features

                        </td>
                        <td>&nbsp;

                        </td>
                        <td>&nbsp;<br />

                        </td>
                    </tr>
                    <tr>
                        <td>37</td>
                        <td>&nbsp;

                        </td>
                        <td>Preprocessing technique

                        </td>
                        <td>&nbsp;

                        </td>
                        <td>Model regularization

                        </td>
                        <td>adds a penalty term to the cost function to prevent overfitting

                        </td>
                        <td>&nbsp;

                        </td>
                        <td>&nbsp;<br />

                        </td>
                    </tr>
                    <tr>
                        <td>38</td>
                        <td>&nbsp;

                        </td>
                        <td>&nbsp;

                        </td>
                        <td>&nbsp;

                        </td>
                        <td>L1/L2 regularization

                        </td>
                        <td>Overfitting problem can be addressed by applying regularization techniques such as L1 or
                            L2<br />
                            regularization and dropouts. <br />&nbsp; <br />Regularization techniques add a penalty term to
                            the<br />
                            cost function of the model, which helps to reduce the complexity of the model and prevent
                            it<br />
                            from overfitting to the training data. Dropouts randomly turn off some of the neurons
                            during<br />
                            training, which also helps to prevent overfitting

                        </td>
                        <td>&nbsp;

                        </td>
                        <td>&nbsp;<br />

                        </td>
                    </tr>
                    <tr>
                        <td>39</td>
                        <td>&nbsp;

                        </td>
                        <td>Preprocessing technique

                        </td>
                        <td>&nbsp;

                        </td>
                        <td>Data augmentation

                        </td>
                        <td>increases the amount of data by creating synthetic <br />samples

                        </td>
                        <td>&nbsp;

                        </td>
                        <td>&nbsp;<br />

                        </td>
                    </tr>
                    <tr>
                        <td>40</td>
                        <td>&nbsp;

                        </td>
                        <td>&nbsp;

                        </td>
                        <td>&nbsp;

                        </td>
                        <td>Poisson distribution

                        </td>
                        <td>suitable for modeling the number of events that occur in a fixed interval of time or
                            space,<br />
                            given a known average rate of occurrence

                        </td>
                        <td>waiting for a bus, the interval is 10 minutes, and the average rate is 3 minutes

                        </td>
                        <td>&nbsp;<br />

                        </td>
                    </tr>
                    <tr>
                        <td>41</td>
                        <td>&nbsp;

                        </td>
                        <td>&nbsp;

                        </td>
                        <td>&nbsp;

                        </td>
                        <td>Normal distribution

                        </td>
                        <td>&nbsp;

                        </td>
                        <td>&nbsp;

                        </td>
                        <td>&nbsp;<br />

                        </td>
                    </tr>
                    <tr>
                        <td>42</td>
                        <td>&nbsp;

                        </td>
                        <td>&nbsp;

                        </td>
                        <td>&nbsp;

                        </td>
                        <td>Binomial distribution

                        </td>
                        <td>&nbsp;

                        </td>
                        <td>&nbsp;

                        </td>
                        <td>&nbsp;<br />

                        </td>
                    </tr>
                    <tr>
                        <td>43</td>
                        <td>&nbsp;

                        </td>
                        <td>&nbsp;

                        </td>
                        <td>&nbsp;

                        </td>
                        <td>Uniform distribution

                        </td>
                        <td>&nbsp;

                        </td>
                        <td>&nbsp;

                        </td>
                        <td>&nbsp;<br />

                        </td>
                    </tr>
                    <tr>
                        <td>44</td>
                        <td>&nbsp;

                        </td>
                        <td>&nbsp;

                        </td>
                        <td>&nbsp;

                        </td>
                        <td>&nbsp;

                        </td>
                        <td>&nbsp;

                        </td>
                        <td>&nbsp;

                        </td>
                        <td>&nbsp;<br />

                        </td>
                    </tr>
                    <tr>
                        <td>45</td>
                        <td>10

                        </td>
                        <td>&nbsp;

                        </td>
                        <td>&nbsp;

                        </td>
                        <td>&nbsp;

                        </td>
                        <td>&nbsp;

                        </td>
                        <td>&nbsp;

                        </td>
                        <td>&nbsp;<br />

                        </td>
                    </tr>
                </tbody>
                </thead>
            </table>
        </div>
    </body>

    </html>
`;

const MLOtherNotesFile = `
<!DOCTYPE html>
  <html>
    <head>
    </head>

    <body>
        <div>
            <b>Other Notes</b><br />
            <br />
            Data preprocessing - is the process of generating raw data for machine learning models<br />&nbsp;<br />
            Feature engineering - refers to manipulation — addition, deletion, combination, mutation — of your data set
            to<br />&nbsp;<br />
            improve machine learning model training, leading to better performance and greater accuracy.<br />&nbsp;<br />
            Exploratory data analysis (EDA) - is used by data scientists to analyze and investigate data sets and
            summarize<br />
            their main characteristics, often employing data visualization methods.<br />&nbsp;<br />
            Hyperparameter tuning - is the process of selecting the optimal values for a machine learning model's<br />
            hyperparameters<br />&nbsp;<br />
            Transfer learningis a strategy for adapting pre-trained models for new, related tasks without creating
            models<br />
            from scratch.<br />&nbsp;<br />&nbsp;
            Epochs - helps to improve accuracy<br />&nbsp;<br />
            - Increasing the number of epochs during model training allows the model to learn from the data over
            more<br />&nbsp;<br />
            iterations, potentially improving its accuracy up to a certain point. This is a common practice when
            attempting<br />
            to reach a specific level of accuracy. Increasing epochs allows the model to learn more from the data, which
            can<br />
            lead to higher accuracy.<br />&nbsp;<br />
            - Decreasing the epochs would reduce the training time, possibly preventing the model from reaching the
            desired<br />
            accuracy.<br />&nbsp;<br />&nbsp;
            Batch Size - Affects training speed<br />&nbsp;<br />
            - Decrease the batch size affects training speed and may lead to overfitting but does not directly relate
            to<br />
            achieving a specific accuracy level.<br />&nbsp;<br />&nbsp;
            Temperature - Affects randomness of predictions<br />&nbsp;<br />
            - Increase the temperature parameter affects the randomness of predictions, not model
            accuracy.<br />&nbsp;<br />
            - Decrease the temperature to produce more consistent responses to the same input prompts<br />&nbsp;
        </div>
    </body>

    </html>
`;



class GenAIPractitioner extends Component {

constructor(props){
    super(props)
    this.state = {
        expanded: true
    }
} 

  render() {
    
    return (
      <div>
        <div class="row">
          <div class="col-sm-7 col-md-5">
            <div class="">
              <h2><a Style="!background-color: #2f4f4f;" href="https://aws.amazon.com/certification/certified-ai-practitioner/" target="_blank"><b>AWS Certified AI Practitioner </b></a> Tips: Refer Sections below </h2>
                    <br/>
                    <Accordion >
                        <AccordionSummary expanded={true} expandIcon={<ArrowDownwardIcon />}>
                            <b>AWS Certified AI Practitioner</b>
                            
                        </AccordionSummary>
                        <AccordionDetails expandIcon={<ExpandMore />}>
                            <div>
                                Howdy! I was able to crack AWS Certified AI Practitioner Certification | AWS Certification | AWS (amazon.com) recently. <br/><br/>

                                Udemy course by Stephane Maarek – https://www.udemy.com/course/aws-ai-practitioner-certified <br/>
                                AWS Skill Builder course for AP Practitioner – https://explore.skillbuilder.aws/learn/public/learning_plan/view/2194/enhanced-exam-prep-plan-aws-certified-ai-practitioner-aif-c01 <br/>
                                AWS Documentation <br/>
                                Nothing comes closer to AWS Overview and FAQs <br/>
                                – https://aws.amazon.com/sagemaker/faqs <br/>
                                – https://aws.amazon.com/q/faqs/ <br/>
                                – https://aws.amazon.com/polly/faqs/ <br/>
                                – https://aws.amazon.com/transcribe/faqs <br/>
                                – Amazon Textract FAQs | AWS <br/>
                                – Amazon Kendra FAQs – Amazon Web Services <br/>
                                – Amazon Personalize FAQs – Amazon Web Services  <br/>
                                – https://aws.amazon.com/rekognition/faqs <br/>
                                – https://aws.amazon.com/lex <br/>
                                – Amazon Comprehend – FAQs <br/>
                                – AWS Translate FAQs – Amazon Web Services (AWS) <br/>
                                <br/> <br/>
                            </div>    
                        </AccordionDetails>
                    </Accordion> 
                    <br /><br />
                    <Accordion >
                        <AccordionSummary expanded={this.state.expanded} expandIcon={<ArrowDownwardIcon />}>
                            <b>AI Services</b>
                            
                        </AccordionSummary>
                        <AccordionDetails expandIcon={<ExpandMore />}>
                            <div>
                                <div dangerouslySetInnerHTML={{ __html: servicesFile }}></div>
                                
                                <br/>
                            </div>      
                        </AccordionDetails>
                    </Accordion> 
                    <Accordion >
                        <AccordionSummary expandIcon={<ExpandMore />}> 
                            <b>Machine Learning Notes</b>
                            
                        </AccordionSummary>
                        <AccordionDetails>
                            <div>
                                <div dangerouslySetInnerHTML={{ __html: MLFile }}></div>
                                
                                <br/>
                            </div>      
                        </AccordionDetails>
                    </Accordion> 
                    <Accordion >
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <b>Other Notes</b>
                            
                        </AccordionSummary>
                        <AccordionDetails>
                            <div>
                                <div dangerouslySetInnerHTML={{ __html: MLOtherNotesFile }}></div>
                                
                                <br/>
                            </div>      
                        </AccordionDetails>
                    </Accordion>                                                    
                      <br/>
              
            </div>
          </div>

        </div>

      </div>
    );
  }
}

export default GenAIPractitioner;