import React, { Component } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

class Lambda extends Component {

    openUrl(value){
        window.open(value, false);
      }
      
      
  render() {
    return (
      <div className="pad-top-30 pad-left-page">
          <div class="row">
            <div class="col-sm-7 col-md-5">
                  <div class="">
                    <h2>AWS Service Limits</h2>                    
                    
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                LIMITS
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           
                            <AccordionItemPanel>
                                
                                <button  className="linkbutton" onClick={() => this.openUrl('http://docs.aws.amazon.com/general/latest/gr/aws_service_limits.html')}>AWS Limits</button>

                                    Simple Storage service (S3) <br/>
                                    - 100 - Buckets per account<br/><br/>

                                    Auto scaling<br/>
                                    - 100 - Launch configurations per region	<br/>
                                    - 20 - Auto Scaling groups per region<br/>
                                    - 10 - sns topic per autoscaling group<br/>
                                    - 50 - load balancer per autoscaling group<br/><br/>

                                    cloudformation<br/>
                                    - 200 - stacks<br/>
                                    - 20 - stack sets<br/>
                                    - 50 - stack instances per stack set<br/><br/>

                                    cloudfront<br/>
                                    - 40gbps - datatransfer  rate per distribution<br/>
                                    - 200 - web distribution per account<br/>
                                    - 100 - RTMP distribution per account<br/>
                                    - 25 - origins per distribution<br/><br/>

                                    cloudwatch<br/>
                                    -     Cloud watch API limit<br/>

                                    - 10 - alarms per month per customer for free. 5000 per region for account<br/>
                                    - 8 TPS (transactions per second) - DescribeAlarms<br/>
                                    - 400 TPS - GetMetricStatistics<br/>
                                    - 25 tPS  - ListMetrics<br/>
                                    - 3 TPS   - PutMetricAlarm<br/>
                                    - 150 TPS - PutMetricData<br/><br/>

                                    - Cloud watch event limit<br/><br/>

                                    - 10 entries per request - PutEvents
                                    - 400 request per second - PutEvents<br/>
                                    - 256 kb - PutEvents<br/>
                                    - 100 - rules per region per account<br/>
                                    - 750 - invocations per second. after that it will be throttled<br/><br/>

                                    - RDS - Refer documentation<br/><br/>
                                    - VPC - Refer documentation<br/><br/>

                                    SNS<br/>
                                    - 100000 - # of topics<br/>
                                    - 12,500,000 - subscriptions per topic<br/>
                                    - 5000 - pending subscriptions per account<br/>
                                    - 1USD - account spend threshold per SMS<br/>
                                    - 20 msgs/sec - delivery rate for promotional sms msgs<br/>
                                    - 20 msgs/sec - delivery rate for transactional sms msgs<br/><br/>

                                    SES<br/>
                                    - 200 - max email per 24 hours<br/>
                                    - 1 - email per second<br/>
                                    - 10MB - max size of a msg<br/>
                                    - 50 - max # of recipients  per message<br/><br/>


                                    SQS<br/>
                                    - 4 - # of days message retains. 1 min to 14 days<br/>
                                    - 300 - fifo can support upto 300 msg per second<br/>
                                    - 256 - max size of a message (1 byte to 256kb)<br/>
                                    - 12hours - max message visibility<br/>
                                    - 120000 - inflight messages - standard queues<br/>
                                    - 20000 - inflight messages - fifo queues<br/>
                                    - sqs policy related - 8MB/20 statements/10 conditions/40 principals<br/><br/>

                                    Route 53<br/>
                                    - 500 - hosted zones<br/>
                                    - 50 - domains<br/>
                                    - 50 - health checks<br/>
                                    - 50 - traffic policies<br/>
                                    - 5 - policy records<br/><br/>



                                    elastic beanstalk<br/>
                                    - 75 - applications<br/>
                                    - 1000 - application versions<br/>
                                    - 200 - environments<br/>

                                    EBS<br/>
                                    - 5000 - # of ebs volumes<br/>
                                    - 10000 - # of ebs snapshots<br/>
                                    - 5 - IO1 (iops ssd), GP2 (general purpose ssd), Magnetic. concurrent snapshot allowed for a single volume<br/>
                                    - 1 - ST1 (HDD throughput optimized), SC1 (cold hdd)<br/>
                                    - 5 -Concurrent snapshot copy requests to a single destination region<br/>

                                    - 20 TiB - total vol storage of GP2, IO1, ST1, SC1, magnetic<br/>
                                    - 40000  - total provisioned iops<br/><br/>

                                    EC2<br/>
                                    - 5 - elastic ip<br/>
                                    - 500 - security group for ec2 classic instances<br/>
                                    - 100 - rules per security group<br/>
                                    - 5000 - key pairs <br/><br/>

                                    EFS<br/>
                                    - 10 - # of files systems per customer account per region<br/><br/>

                                    - IAM - Identity and Access Management<br/>
                                    - 1500 - customer managed policies in an account<br/>
                                    - 300 - groups in an account<br/>
                                    - 1000 - roles in an account<br/><br/>
                                    - 5000 - users in an account (for more use temporary security credentials)<br/><br/>


                                    Kinesis streams<br/>
                                    - 500 - shards per region in US east/west, EU Ireland<br/>
                                    - 200 - shards per region. all other regions<br/><br/>

                                    Lambda <br/>
                                    - 1000 - Concurrent executions<br/><br/>

                                    DynamoDB<br/>
                                    - 400 - Item size<br/>
                                        - 32 - json nesting<br/>
                                        - 256 - max # of tables<br/>

                                        - 1 RCU = 1 strongly consistent read per second or 2 eventually consistent reads per second for items upto 4 kb<br/>
                                        - 1 WCU = 1 write per second upto 1 KB in size<br/><br/>

                                        provisioned capacity unit<br/>
                                        - 1 - Min RCU and WCU<br/><br/>

                                        - No limit - Table Size <br/>
                                        - 5 - Local secondary index <br/>
                                        - 5 - Global secondary index <br/>
                                        - 20 - projected secondary index attributes into all of LSI, GSI<br/>

                                        - 1 byte upto 2048 byte - Partition key length, Sort key length<br/>
                                        - No limit - Partition key values, sort key values <br/>

                                        -  3 upto 255 - table name & LSI names<br/>
                                        - 1 char upto 64KB long - attribute name (exception secondary index partition, sort key names - 255 chars)<br/><br/>

                                        APIs<br/>
                                        - 10 - max # of tables in CreateTable/UpdateTable/DeleteTable simultaneously.<br/>
                                        - 100 upto 16MB - max # of items by BatchGetItem. <br/>
                                        - 25 upto 16MB - # single BatchWrite can contain upto into PutItem or DeleteItem.<br/>
                                        - upto 1MB - resultset size from query & scan. use the LastEvaluatedKey from the scan response to retrieve more results.<br/>
                                        - DescribeLimits - should not be called often. more than once in a minute will throttle<br/>
                                
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>    
                   
                      
                </div>              
            </div>
           
          </div>
      </div>
    );
  }
}
 
export default Lambda;