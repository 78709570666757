import React, { Component } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

class RDS extends Component {

    openUrl(value){
        window.open(value, false);
      }
      
      
  render() {
    return (
      <div className="pad-top-30 pad-left-page">
          <div class="row">
            <div class="col-sm-7 col-md-5">
                  <div class="">
                    <h2>RDS</h2>                    
                    

                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                RDS
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           
                            <AccordionItemPanel>
                                html content<br/>
                                <button  className="linkbutton" onClick={() => this.openUrl('url')}>link name</button>
                                
                                Amazon RDS enables you to run a fully featured relational database while offloading database administration
                                <br/>
                                <br/>

                                Terminologies
                                <br/>
                                DB instance as a database environment in the cloud with the compute and storage resources
                                <br/>

                                A DB Subnet Group is a collection of subnets that you may want to designate for your RDS DB Instances in a VPC. Each DB Subnet Group should have at least one subnet for every Availability Zone in a given Region
                                <br/>
                                <br/>
                                <b>Points</b>
                                <br/>
                                DB Instances limit - upto 40 amazon rds db instances. 10 can be oracle or sql or all 40 instances can be aurora or mysql or postgresql etc.,
                                <br/>
                                    - aurora, mysql, mariadb, postgresql - no limit <br/>
                                    - oracle - 1db per instance. no limit on # of schemas per db <br/>
                                    - mssql  - 30 dbs per instance <br/>

                                <br/><br/>
                                import data into RDS
                                <br/>
                                    - MySQL 		- mysqldump or mysqlimport utilities <br/>
                                    - Oracle 		- Data Pump , import/export or SQL Loader <br/>
                                    - MSSQL  		- import/export, full backup (.bak) or BCP (bulk copy program) <br/>
                                    - postgresql 	- pg_dump <br/>

                                <br/><br/>
                                <b>Performance monitoring</b>
                                <br/>
                                Enhanced monitoring provides granularity upto 1 sec	
                                <br/>
                                for RDS DBs to review slow/long running queries 
                                <br/>
                                    - - MySQL/MariaDB - "slow_query_log" DB parameter and query "mysql.slow_log" table  <br/>
                                    - - Oracle - oracle trace file to identify slow queries	 <br/>
                                    - - SQL - use client side SQL server traces <br/>
                                <br/>	
                                "Apply Immediately" - to upgrade immediately in the console API
                                <br/>
                                <br/>

                                <b>Important Points</b>
                                <br/>
                                Billing - DB Instance hoursm storage (per GB), I/O requests per mongth, Provisioned IOPs, backup storage, data transfer (in and out of db instance)
                                <br/>
                                Amazon RDS reserved instances give you the option to reserve a DB instance for a one or three year term. No Upfront, Partial Upfront, All Upfront. you can purchase upto 40 DB instances
                                <br/>
                                Automated backups provide "point in time" backup. standby will be in same region different availability zone
                                <br/>
                                Snapshots can be created 
                                <br/>
                                read replica, snapshot, automated backup all will provide momentary latency, delay or a snag.
                                <br/>
                                if you have multi-AZ solution - snapshot and replication will not impact much
                                <br/>
                                on multi-AZ deployment, in case of failover RDS changes the DNS/CNAME to the secondary db
                                <br/>
                                You can test a DB instance with a new version before upgrading.
                                <br/>
                                Even when the DB instance is stopped you will be charged for PIOPS and backup storage (including manual snapshot and automated backups within your specific retention window)
                                <br/>
                                Free tier - 750 hours, 20GB of general purpose SSD
                                <br/>
                                read replica of read replica is possible - upto 5 for mysql/mariadb. upto 15 for aurora
                                <br/>
                                DB Parameter group - like "container" for engine configuration. by default contains  optimized engine defaults is created if not given.
                                <br/>
                                Standby/Secondary (multi AZ deployment) - synchronous backup of primary (concurrent db writes on primary and standby). cannot be used for read/write operations. 
                                <br/>
                                read replicas - asynchronous replication. can take read only operations and can offload the primary DB
                                <br/>
                                Replica Lag - CloudWatch Metrics No of seconds read replica is behind master (primary) 
                                <br/>
                                Replication State field - if there is a replication failure. we can see tis in console (similar to state transition label for ec2)
                                <br/>
                                <br/>

                                <b>SQL Server</b>
                                <br/>
                                "Mirroring" due to MS technology not by AWS
                                <br/>
                                doesn't support increasing storage due to limitations of striped storage attached to windows
                                <br/>
                                cannot create read replicas
                                <br/><br/>

                                <b>RDS Storage Types</b>
                                <br/>
                                - - General Purpose SSD - database workloads with moderate I/O requirements <br/>
                                - - IOPS SSD - high performance OLTP workloads <br/>
                                - - Magnetic storage - ffor small database where data is less frequently accessed. not for production <br/>
                                    
                                <br/><br/>


                                <br/><br/>
                                <b>Event subscriptions </b>
                                <br/>
                                    - Name of the subscription. <br/>
                                    - Enable or disable <br/>
                                    - Target - ARN (like SNS topic, dynamodb db arn, or email ARN) or Email (with topic name and email addresses) <br/>
                                    - can be done to instances, security groups, parameter groups,  snapshots, db clusters and db cluster snapshots. cannot be done for "Option Groups" <br/>

                                <br/><br/>

                                <b>Developer Guide/Options</b>
                                <br/>
                                - DescribeDBInstances API or describe-db-instances command - to retrieve its endpoint via the DB instance description in the AWS Management Console <br/>
                                - ModifyDBInstance API or the modify-db-instance command  -  to modify the maintenance window, to manage the period of time your automated backups are retained by modifying the RetentionPeriod parameter. <br/>
                                - DescribeReservedDBInstances API or describe-reserved-db-instances command - status of your reservation of db instance <br/>
                                - DescribeReservedDBInstancesOfferings API or describe-reserved-db-instances-offerings command - can check Multi-AZ options listed among the DB Instance configurations available for purchase <br/>
                                - CreateDBSnapshot API, or create-db-snapshot command - to create db snapshot <br/>
                                - CreateDBInstance API - to create a db instance using API <br/>
                                - DescribeDBSnapshots API or describe-db-snapshots command,DeleteDBSnapshot API or delete-db-snapshot command - Describe and snapshots <br/>
                                - RestoreDBInstanceToPointInTime or RestoreDBInstanceFromSnapshot - To recover from automated backup  or snapshot. <br/>
                                - RebootDBInstance - reboot db instance - API <br/>
                                - CreateDBInstanceReadReplica  - creates read replica <br/>


                                link : <a href="#rds_creation" onclick="#');">AWS RDS Creation Steps/Details</a>
                                {/* <button  className="linkbutton" onClick={() => this.openUrl('url')}>link name</button> */}
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>    
                    
                      
                </div>              
            </div>
           
          </div>
      </div>
    );
  }
}
 
export default RDS;