import React, { Component } from "react";
import TypoGraphy from '@material-ui/core/Typography'
import { Grid, Paper, Typography, List, ListItem } from "@material-ui/core";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  } from 'react-accessible-accordion';
  
  // Demo styles, see 'Styles' section below for some notes on use.
  import 'react-accessible-accordion/dist/fancy-example.css';
  

class GIT extends Component {
  constructor(props){
    super(props)
       window.clickLog("load", "GITIt")    
  } 

    openUrl(value){
        window.open(value, false);
    }
    

  render() {
    return (
      <div>
        <div style={{ marginTop: 20, padding: 30 }}>
            <Grid container spacing={40} justify="center">
                <TypoGraphy variant="h4">
                    <center>GIT</center>
                </TypoGraphy>
            </Grid>
            <div className="pad-left-page">
            <div class="row">
              <div class="col-sm-7 col-md-5">
                    <div class="">
                                       
                      <p>Follow me @ <button  className="linkbutton" onClick={() => this.openUrl('https://github.com/shivaramani?tab=repositories')}>GIT It!!!</button>
                      </p>
                      <Accordion>
                          <AccordionItem>
                              <AccordionItemHeading>
                                  <AccordionItemButton>
                                    Microsoft - .NET, C#, WebAPI Concepts and examples
                                  </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                              <p>Follow me @ <button  className="linkbutton" onClick={() => this.openUrl('https://github.com/shivaramani/.net')}>GIT It !!!</button>
                              </p>
                              </AccordionItemPanel>
                          </AccordionItem>
                      </Accordion>
                      <div className="pad-top-10"></div>
                      <Accordion>
                          <AccordionItem>
                              <AccordionItemHeading>
                                  <AccordionItemButton>
                                  .NET Core Microsoft .NET core concepts/examples
                                  </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                              <p>Follow me @ <button  className="linkbutton" onClick={() => this.openUrl('https://github.com/shivaramani/Dotnetcore')}>GIT It !!!</button>
                              </p>
                              </AccordionItemPanel>
                          </AccordionItem>
                      </Accordion>
                      <div className="pad-top-10"></div>
                      <Accordion>
                          <AccordionItem>
                              <AccordionItemHeading>
                                  <AccordionItemButton>
                                    Open Source concepts/examples - ReactJS, TypeScripts, AngularJS, Cucumber, IoT etc.,
                                  </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                              <p>Follow me @ <button  className="linkbutton" onClick={() => this.openUrl('https://github.com/shivaramani/opensource')}>GIT It !!!</button>
                              </p>
                              </AccordionItemPanel>
                          </AccordionItem>
                      </Accordion>
                      <div className="pad-top-10"></div>
                      <Accordion>
                          <AccordionItem>
                              <AccordionItemHeading>
                                  <AccordionItemButton>Java Springboot /MVC Springboot concepts/examples</AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                              <p>Follow me @ <button  className="linkbutton" onClick={() => this.openUrl('https://github.com/shivaramani/java')}>GIT It !!!</button>
                              </p>
                              </AccordionItemPanel>
                          </AccordionItem>
                      </Accordion>
                  </div>              
              </div>
            
            </div>
        </div>
          </div>
      </div>
    );
  }
}
 
export default GIT;