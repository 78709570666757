import React, { Component } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import { ExpandMore } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';
import TypoGraphy from '@material-ui/core/Typography'
import { Grid, Paper, Typography, List, ListItem } from "@material-ui/core";


import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import GenAIPractitioner  from "../common/GenAIPractitioner";
import 'react-accessible-accordion/dist/fancy-example.css';



const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

const AccordionSummary = withStyles({
  content: {
    flexGrow: 0
  }
})(MuiAccordionSummary);

// const [expand, setExpand] = useState(0);
const toggleAcordion = () => {
  //setExpand((prev) => !prev);
  alert(this.state.expanded);
  this.setState({ expanded : !this.state.expanded})
};


class HomeCodeAndTech extends Component {

  constructor(props){
    super(props)
        this.state = {
          expanded: true
        }
        //this.toggleAcordion = this.toggleAcordion.bind(this)
    } 

  openUrl(value){
      window.open(value, false);
  }
      
      
  render() {
    return (
      <div className="pad-top-30 pad-left-page">
          <div class="row">
            <div class="col-sm-7 col-md-5">
                  <div class="">
                  <Grid container spacing={40} justify="center">
                        <TypoGraphy variant="h4">
                            <center>CodeAndTech !!!</center>
                        </TypoGraphy>
                    </Grid>
                    
                        <div className="pad-top-30 pad-left-page">
                        <br/><br/>
                          <GenAIPractitioner></GenAIPractitioner>
                        <br/><br/>
                        <div class="row">
                            <div class="col-sm-7 col-md-5">
                                <div class="">
                                        <div class="">
                                            <div class="col-4 col-m-3"  height="200" width="225">
                                                    Learn to Code 
                                                    <br/>
                                                    <img alt="LearnToCodeQnAs" onClick={() => this.NavUrl('LearnToCode')} src={window.location.origin + '/images/code.png'}   height="150" width="225"/>
                                                </div>
                                                <div class="col-4 col-m-3">
                                                    My AWS Offering
                                                    <br/>
                                                    <img alt="CloudOpsGuru.com"  onClick={() => this.NavUrl('AWSDedication')}  src="images\aws_new.jpg"  height="150" width="225"/>
                                                </div>
                                                <div class="col-4 col-m-3">
                                                    Open Source
                                                    <br/>
                                                    <img alt="OpenSource"  onClick={() => this.NavUrl('Opensource')}  src="images\opensource.png"  height="150" width="225"/>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                            </div>           
                            
                            <div class="row">
                                    <div class="">
                                        <div class="col-4 col-m-3" height="200" width="225">
                                            Microsoft
                                            <br/>
                                            <img alt="Microsoft" ClassName="handCursor" onClick={() => this.NavUrl('Microsoft')}  src="images\microsoft.jpg"  height="150" width="225"/>
                                        </div>
                                        <div class="col-4 col-m-3">
                                            GIT It !!!
                                            <br/>
                                            <img alt="github"  ClassName="handCursor" onClick={() => this.NavUrl('GIT')}  src="images\github.gif"  height="150" width="225"/>
                                        </div>
                                        <div class="col-4 col-m-3">
                                            IOT!!!
                                            <br/>
                                            <img alt="IoT"  ClassName="handCursor" onClick={() => this.NavUrl('')}  src="images\iot.png"/>
                                        </div>
                                    </div>
                            </div>
                            
                        
                        </div>
                  
                </div>              
            </div>
           
          </div>
      </div>
    );
  }
}
 
export default HomeCodeAndTech;