import React, { Component } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

class SWF extends Component {

    openUrl(value){
        window.open(value, false);
      }
      
      
  render() {
    return (
      <div className="pad-top-30 pad-left-page">
          <div class="row">
            <div class="col-sm-7 col-md-5">
                  <div class="">
                    <h2>Simple Workflow Service (SWF) </h2>                    
                    

                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                Amazon Simple Workflow Service (SWF) 
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           
                            <AccordionItemPanel>
                            is a web service that makes it easy to coordinate work across distributed application components. 
                            Amazon SWF enables applications for a range of use cases, including media processing, web application back-ends, business process workflows, 
                            and analytics pipelines, to be designed as a coordination of tasks. 

                            Tasks represent invocations of various processing steps in an application which can be performed by executable code, web service calls, 
                            human actions, and scripts.

                            <li>Workers - Workers are programs that interact with Amazon SWF to get tasks, process received tasks, and return the results.
                                Tasks are processed by workers which are programs that interact with Amazon SWF to get tasks, process them, and return their results
                            </li>
                            <li>
                            Deciders -  The decider is a program that controls the coordination of tasks, i.e. their ordering, concurrency, and scheduling 
                                according to the application logic.	a program of programming language to coordinate the application execution across workers
                            </li>

                                
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>    
                    
                      
                </div>              
            </div>
           
          </div>
      </div>
    );
  }
}
 
export default SWF;