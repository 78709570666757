import React, { Component } from "react";
import TypoGraphy from '@material-ui/core/Typography'
import { Grid, Paper, Typography, List, ListItem } from "@material-ui/core";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';


// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

const superagent = require('superagent') 
const githubContentsUrl="https://e8e92kgig4.execute-api.us-west-2.amazonaws.com/dev/";

window.$ = window.jQuery = require('jquery')

class LearnToCode extends Component {
  constructor(props){
    super(props)
    this.state = {
      GithubReadmes: []
    }
    window.clickLog("load", "LearnToCode") 
  }

  componentWillReceiveProps(nextProps){
    this.setState({
      GithubReadmes: nextProps.GithubReadmes
    })
  }

  componentWillMount(){
    this.setState({
      GithubReadmes: this.props.GithubReadmes
    })
  }

  openGithubUrl(value){
    window.open(value, false);
}

  openUrl(divId, urlValue){
      //let url = urlValue;
      //window.open(urlValue, false);

      this.getGithubContentsForUrl(githubContentsUrl, urlValue, divId);

  }

 getGithubContentsForUrl(url, key, divId){
  
  superagent
    .post(url)
    .send({url: key})
    .set('Accept', 'text/xml')
    .end((error, response) => {
        if(response && response.text && response.text){
            try{
               var jsonObject = JSON.parse(response.text);
                var data =  jsonObject.contentHTML;

               //document.getElementById(divId).textContent = data;
               //document.getElementById(divId).innerHTML = data;
               document.getElementById(divId).outerHTML = data;
            }
            catch(Exception){
                
            }
        }
    })
}

  render() {

      var readmes = this.props.GithubReadmes;

      if(readmes && readmes.Dotnet){

      }
      else{
        return(
          <div>
              Still loading !!!
          </div>
        );
      }

      var renderData = [];  
      
      //var dummyUrl = "https://github.com/shivaramani/.net/blob/master/ASP.NET/ASP.NET%20QnAs.md?raw=true";
      //var key = "ASPNETQnAs";

      for(var cnt=0; cnt< readmes.Dotnet.length; cnt ++){
        let key = readmes.Dotnet[cnt].key 
        let link = readmes.Dotnet[cnt].link 
        renderData.push(    
          <div>
                          
              <Accordion  accordion={true}>
                <AccordionItem>
                      <AccordionItemHeading>
                          <AccordionItemButton>
                              <h3  onClick={() => this.openUrl(key ,link)}>{readmes.Dotnet[cnt].name}</h3>
                          </AccordionItemButton>
                      </AccordionItemHeading>
{/* 
                      <AccordionItemTitle>
                       <h3  onClick={() => this.openUrl(key ,link)}>{readmes.Dotnet[cnt].name}</h3>
                      </AccordionItemTitle> */}
                    
                    <AccordionItemPanel>
                       <span id={readmes.Dotnet[cnt].key}> </span>    
                    </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
              <div className="pad-top-10"></div>
          </div>
         
        );
      }

      for(var jcnt=0; jcnt< readmes.Java.length; jcnt ++){
        let key = readmes.Java[jcnt].key 
        let link = readmes.Java[jcnt].link 
        renderData.push(    
          <div>
              {/* <ReactMarkdown source={input} /> */}              
              
                <AccordionItem>
                      <AccordionItemHeading>
                          <AccordionItemButton>
                             <h3  onClick={() => this.openUrl(key ,link)}>{readmes.Java[jcnt].name}</h3>
                          </AccordionItemButton>
                      </AccordionItemHeading>
                    {/* <AccordionItemTitle>
                        <h3  onClick={() => this.openUrl(key ,link)}>{readmes.Java[jcnt].name}</h3>
                    </AccordionItemTitle> */}
                    <AccordionItemPanel>
                      <span  id={readmes.Java[jcnt].key}> </span>  
                    </AccordionItemPanel>
                </AccordionItem>
                <div className="pad-top-10"></div>
          </div>
        );
      }

      for(var osCnt=0; osCnt< readmes.OpenSource.length; osCnt ++){
        let key = readmes.OpenSource[osCnt].key 
        let link = readmes.OpenSource[osCnt].link 
        renderData.push(    
          <div>
              {/* <ReactMarkdown source={input} /> */}              
              <AccordionItem>
                    <AccordionItemHeading>
                          <AccordionItemButton>
                          <h3  onClick={() => this.openUrl(key ,link)}>{readmes.OpenSource[osCnt].name}</h3>
                          </AccordionItemButton>
                      </AccordionItemHeading>
                  {/* <AccordionItemTitle>
                      <h3  onClick={() => this.openUrl(key ,link)}>{readmes.OpenSource[osCnt].name}</h3>
                  </AccordionItemTitle> */}
                  <AccordionItemPanel>
                     <span id={readmes.OpenSource[osCnt].key}> </span> 
                  </AccordionItemPanel>
              </AccordionItem>
              <div className="pad-top-10"></div>
          </div>
        );
      }
   
    return (
    <div>
      <div style={{ marginTop: 20, padding: 30 }}>
          <Grid container spacing={40} justify="center">
              <TypoGraphy variant="h4">
                  <center>AWS Dedication</center>
              </TypoGraphy>
          </Grid>
        <div className="pad-top-30 pad-left-page">
         <div class="row">
            <div class="col-sm-7 col-md-5">
                Note below contents come directly from my github readmes. You can directly refer &nbsp;
                <button  className="linkbutton" onClick={() => this.openGithubUrl('https://github.com/shivaramani?tab=repositories')}> Github </button>
                &nbsp;for individual repositories & samples.&nbsp;

                  <div class="">
                    <h3>
                        {/* <ReactMarkdown source={input} /> */}
                        <div>
                          {renderData}
                        </div>
                    </h3>
                    <p>
                        
                       
                    </p>
                </div>              
            </div>
           
          </div>
      </div>
       </div>
      </div>
    );
  }
}
 
export default LearnToCode;