import React, { Component } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

class S3 extends Component {

    openUrl(value){
        window.open(value, false);
      }
      
      
  render() {
    return (
      <div className="pad-top-30 pad-left-page">
          <div class="row">
            <div class="col-sm-7 col-md-5">
                  <div class="">
                    <h2>S3 - Simple storage service </h2> <br/>                   
                   

                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                S3 - Simple storage service 
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           
                            <AccordionItemPanel>
                              
                                
                                virtual disk in the cloud where we can save objects/files (word docs/ppts/movies/text files etc.,) . 
                                Place to install a DB, computer game - ex: block based
                                Drop box started saving their objects in S3. saves their metadata inside their data center
                                <br/>
                                s3 provides dev and it secure durable and highly scalable object  storage. s3 is easy to use, with a simple web services interface to 
                                store and retrieve any amount of data from anywhere on the web
                                <br/>
                                safe place to store your files. object based storage. spread across multiple devices and facilities
                                <br/>
                                Video, photo, pdf, word doc - flat files. S3 is NOT a place where we install operating systems / database i.e blob based
                                <br/>
                                Allows to upload files. 0 byts to 5TB. Unlimited storage.
                                Files are stored in buckets - bucket is a folder.
                                S3 is a universal namespace. So unique globally.
                                A bucket creates a universal bucket.
                                <br/>
                                ex:
                                https://s3-eu-west-1.amazonaws.com/mybucket
                                <br/>
                                When you puload a file to S3 you will receive a HTTP code 200 if succsfl
                                <br/>
                                Data consistency model for S3
                                <br/>
                                Read after write consistency for PUTS of new Objects - you can read it immediately after you put that
                                <br/>
                                Eventual consistency for overwrite PUTS or DELETES - can take some time to propagate
                                <br/>
                                Updates are atomic - you will get either get NEW or OLD data. Will NOT get partial data
                                <br/>
                                S3 is a simple key value store.
                                <br/>
                                Version ID - versioning
                                <br/>
                                Metadata - data about data you are storing. Ex: date you uploaded etc.,
                                <br/>
                                S3 Objects
                                    - Key : name of the object<br/>
                                    - Value: data of the object - made up iof sequence of bytes<br/>
                                    - Version ID - versioning<br/>
                                    - Metadata - data about data you are storing. Ex: date you uploaded etc.,<br/>
                                    - Sub resources - 
                                    - access control list - who can access
                                    - torrent - supports bittorrent types
                                    <br/>
                                    Amazon guarantees 99.9 availability of S3. guarantees 99.999999999 (11x9's) durability
                                    <br/>
                                    Tiered storage available - 90 days archival etc.,
                                    <br/>
                                    Versioning
                                    <br/>
                                    Encryption
                                    <br/>
                                    Secure - access cotrol list and bucket list
                                    <br/>
                                
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>    
                    <div className="pad-top-10"></div>
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                Storage tiers/classes 
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           
                            <AccordionItemPanel>
                               
                                - S3 - 99.99% availability<br/>
                                - S3 - Infrequently Accessed - for data access less frequently<br/>
                                - Reduced Redundancy Storage - ex: image files in a bucket and thumb nails in another bucket. <br/>
                                
                                If you want to use frequently storing thumbails in Reduced redundancy storage
                                <br/>
                                - 
                                Glacier - very cheap but for archival only . 3 to 5 hours to restore from glacier - glacier is a extremely low-cost storage se
                                <br/>

                                Amazon S3 supports the REST API.
                                s3 provides both standards based REST and soap web services interfaces
                                <br/>
                                SOAP over HTTP is deprecated (still allows HTTPS). use REST API or SDK
                                <br/>
                                1Byte to 5TB. can upload upto 5GB per time. use multi-part upload
                                <br/>
                                128 KB - mins size for standard-IA
                                <br/>
                                S3 - standards - 99.999999999% durability (11 nines). 99.99% availability
                                <br/>
                                s3 - infrequent access - infrequent access but still rapid access when needed . 99.9% availability
                                <br/>
                                s3 - glacier - saving objects with life cycle. restore can take upto 3 to 5 hours
                                <br/>
                                s3 - RRS - non critical, recreatable objects
                                <br/>

                                S3 charges - 
                                    - 
                                    - Storage
                                    <br/>
                                    - 
                                    - Requests
                                    <br/>
                                    - 
                                    - Storage management pricing - 
                                    <br/>
                                    - 
                                    - Data transfer pricing - incoming data is free. Replication or moving to another regions is priced
                                    <br/>
                                    - 
                                    - Transfer Acceleration - enables fast and secure transfers of files over long distances between your end users and an s3 bucket. transfer acceleration takes advantage of cloudfront's globally distributed edge locations. as data arrives at an edge location, data is routed to amazon s3 over an optimized network
                                    <br/>

                                S3 Versioning
                                    - 
                                    - stores all versions of an object including all writes and even if you delete. 
                                    <br/>
                                    - 
                                    - great backup tool.
                                    <br/>
                                    - 
                                    -  once versioning enabled cannot be disabled. can be suspended. 
                                    <br/>
                                    - 
                                    - Integrates with lifecycle rules
                                    - MFA multi factor authentication can help more security
                                    <br/>
                                S3 Security
                                    - 
                                    By default buckets are private
                                    Can make Access Control List using
                                    - Bucket policies
                                    - Access Control Lists - can make specific bucket public
                                    S3 buckets can be configured to create access log - log all request made to s3 bucket
                                    <br/>
                                    
                                S3 Encryption - Totally 4 different methods
                                    - 
                                    In Transit - when we send to and from to buckets
                                    - SSL / TLS - TLS is replacement to SSO. Https how we encrypt using transit
                                    <br/>
                                    - At REST
                                    - Server Side and Client Side
                                    <br/>
                                    - 
                                    Server Side Encryption
                                        ○ S3  Managed Keys - SSE-S3 - strong . Each object is done with unique key with MFA (multi factory authentication). Amazon has master key. Advanced AES 256 bit data. 
                                        ○ AWS Key Management Service - AMS KMS - Similar to SSE S3. additional benefits/charges. Envelope key protects data to your data's encryption key. Provides audit trail of who uses this key. Can create managed encryption key
                                        ○ Server Side encryption with customer provided key - SSE-C - Amazon manages the encryption. User/we manages the key
                                    <br/>
                                    - 
                                    Client Side Encryption
                                    <br/>
                                    
                                Cross region replication
                                    - 
                                    Existing objects wil not be copied
                                    <br/>
                                    - 
                                    Versioning must be enabled on the target
                                    <br/>
                                    - 
                                    New IAM role or existing role needed to create
                                    <br/>
                                    - 
                                    After updating a version it replicates all previous versions on the target bucket. Shares the same permission from the "From" bucket
                                    <br/>
                                    - 
                                    you cannot replicate to multiple buckets or use daisy chaining. delete markers are replicated. 
                                    <br/>
                                    - deleting individual versions or delete markers will not be replicated
                                    <br/>
                                Static web site
                                <br/>
                                http://mywebsite.s3-website-us-west-2.amazonaws.com/
                                <br/>
                                S3 Transfer Acceleration
                                <br/>
                                s3 transfer acceleration utilizes the cloudfront edge network to accelerate your uploads to s3. instead of uploadig directly to s3 you can use a distinct url to upload directly to an edge location which will then transfer that file 
                                Ex: to acloudguru.s3-accelerate.amazonaws.com
                                <br/>
                                Snowball
                                <br/>
                                Earlier to transfer data, AWS import/export disk accelerates moving large amounts of data in/out of AWS usoing portable storage devices for transport. AWS import/export disk transfers your data directly onto and off of storage devices ysing amazons high speed internal network and bypassing the internet
                                <br/>
                                Snowball - to transfer huge data IN/OUT of AWS petabyte scale . Is a petabyte scale data transport solution that uses secure appliances to transfer large amount of data into/out of AWS.
                                80TB snowball in all regions. Snowball uses multiple layers of security designed to protect your data tamper resistant. 256 bit encryption and an industry standard trusted platform module (TPM). Once data transfer job has been processed and verified AWS performs a snowball erasure of the snowball appliance
                                <br/>
                                Types
                                    - 
                                - Snowball (standard or regular)
                                <br/>
                                    - 
                                - Snowball Edge - like AWS storage on a box. snowball edge is a100TB data transfer device with on board storage and compute capabilities. connects to existing applicationand infrastructure using standard storage interfaces
                                    - Snowmobile - 4PB or ExaBytes are data. exabyte scale data transfer service used to move extremely large amounts of data. transfer upto 100PB per Snowmobile, a 45 foot long ruggedized shipping container pulled by a semi trailer truck
                                    <br/>
                                
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>  
                    <div className="pad-top-10"></div>
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                Developer notes:
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           
                            <AccordionItemPanel>
                                delete large number of objects - multi-object delete
                                <br/>
                                x-amz-storage-class-header - can specify STANDARD_IA (while we PUT into standard-IA)
                                <br/>
                                AWS S3 APs for standard/RRS/Glacier also
                                <br/>
                                S3 APIs - PUT, POST, COPY, DELETE
                                <br/>
                                notification can be sent to S3 or SQS
                                <br/>
                                Instead of periodic calls, we can use "s3 inventory" - ex: big data jobs from S3 as daily or weekly basis from an s3 bucket
                                <br/>
                                Amazon API Reference: <a href="#s3" onclick="window.open('https://docs.aws.amazon.com/AmazonS3/latest/API/Welcome.html');">API  Referece</a>
                                <br/>

                                S3 Interaction - APIs, CLI, upload (using html post)
                                <br/>
                                
                                - 
                                APIs
                                    - 
                                    1) Operations on Service level - GET Service - 
                                        implementation of GET returns a list of all buckets. response has "ListAllMyBucketsResult" with Name and CreationDate for each.
                                    <br/>
                                    - 
                                    2) Operations on buckets. A 200 OK can contain valid or invalid xml
                                        <br/>
                                        - GET - get returns upto 1000 objects in a bucket
                                            <br/>
                                            - Bucket GET(i.e list-type=2), accelerate(for transfer acceleration), acl, analytics, cors, 
                                            <br/>
                                                    inventory logging, metrics, notification, tagging, versioning ,website etc.,
                                                <br/>
                                                <br/>
                                        - PUT  -  bucket, accelerate, acl, analytics, crs, inventory, lifecycle, logging, metrics, notification, policy, replication,
                                                <br/>
                                                tagging, requestPayment, versioning, website
                                                <br/>
                                                <br/>
                                        - DELETE - implementation of DELETE
                                                <br/>
                                                -  DELETE Bucket, analytics, cors, inventory, lifecycle, metrics, policy, replication, tagging, website
                                                <br/>
                                                <br/>
                                        - LIST - this is a GET implementation for 
                                                <br/>
                                            - multipart uploads (list in-progress multipart uploads), 
                                                <br/>
                                                analytics configuration, inventory configuration, metrics (cloud watch configuration)
                                                <br/>
                                                <br/>
                                        - HEAD - to determine if the bucket exist and you have permission to access it.
                                                <br/>
                                            - returns 200 (success), 404 (not found), 403 (forbidden)
                                            <br/>
                                    <br/>
                                    - 		   
                                    3) Operations on Objects
                                            <br/>
                                        - GET - needs S3:GetObject permission. error depends on s3:ListBucket Permission, tagging depends on s3:GetObjectVersionTagging
                                                <br/>
                                            - by default returns teh current version. for specific version use "VersionId"
                                            <br/>
                                                Object, object ACL (needs READ_ACP access to the object), object tagging, object torrent (for only less than 5GB in size)
                                                <br/>
                                                <br/>
                                        - HEAD   - returns metadata from an object without returning the object itself
                                                <br/>
                                                -  error depends on s3:ListBucket
                                                <br/>
                                                    <br/>
                                        - OPTIONS  - browser can send preflight request to S3 so that s3 can check if actual request with specific orign, http method etc.,
                                                    <br/>
                                                    <br/>
                                        - PUT   - adds an object  to bucket. s3 never puts incomplete object into the bucket
                                                    <br/>
                                                uses standard storage by default
                                                <br/>
                                                - PUT, PUT COPY (x-amz-copy-source), Object ACL, Object Tagging
                                                <br/>
                                                <br/>
                                        - POST    - adds an object using HTML forms. alternate form of PUT - for browser based
                                                    <br/>
                                                    parameters are not passed as PUT instead as FORM Fields to POST in the multipart/form-data encoded message bdy
                                                    <br/>
                                                    <br/>
                                        - DELETE - deletes null version (if no version) or puts delete marker if versioning is enabled. 
                                                    <br/>
                                                - s3:DeleteObject, s3:DeleteObjectVersion, s3:PutLifeCycleConfguration  .use "VersionId" for specific version
                                                    <br/>
                                                - DELETE object, object tagging, multiple objects (to delete multiple objects)
                                                    <br/>
                                                    <br/>
                                        - LIST Parts - lists the parts that have been uploaded for a specific multipart upload
                                                    <br/>
                                                    <br/>
                                        - Upload - Upload Part, Upload Part - Copy
                                                    <br/>
                                                    <br/>
                                        - Multipart Upload - Initiate, Abort, Complete
                                                <br/>
                                    <br/>
                                
                                
                                <br/>
                                - 

                                    b) using CLI below
                                    
                                    <br/>
                                    CLI S3 High legel Commands - commands include cp, s3, ls, mv, rm, sync. cp, ls, mv, rm work similarly like unix commands
                                    <br/>
                                    #make bucket
                                    <br/>
                                    $sws s3 mb se://new-bucket-name
                                    <br/>
                                    #rb - remove bucket
                                    <br/>
                                    $aws s3 ls #list buckets
                                    <br/>
                                    ex: to copy a file to a bucket and make in reduced redundancy
                                    <br/>
                                    $ aws s3 cp file.txt s3://my-bucket/ --storage-class REDUCED_REDUNDANCY
                                    <br/>

                                    ex: to sync s3 buckets
                                    <br/>
                                    $ aws s3 sync . s3://my-bucket/path
                                    <br/>
                                
                                <br/>
                                - 
                                    c) using Browser based Uploads using POST
                                
                                
                                <br/><br/>
                                - 
                                    <a href="#SnapshotsEncrptionVolumesExamTips" onclick="#">Snapshots Encrption Volumes Exam Tips</a>
                                
                                <br/><br/>
                            
                                
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>  
                    
                      
                </div>              
            </div>
           
          </div>
      </div>
    );
  }
}
 
export default S3;