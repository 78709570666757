import React, { Component } from "react";
import TypoGraphy from '@material-ui/core/Typography'
import { Grid, Paper, Typography, List, ListItem } from "@material-ui/core";


import "../js/appConstants.js";
import MyBlogs from "../js/codeandtech/MyBlogs";
import HomeCloudOpsGuru from "./cloudopsguru/HomeCloudOpsGuru.js";
import HomeCodeAndTech from "./codeandtech/HomeCodeAndTech.js";
    

class Home extends Component {

constructor(props){
    super(props)
    this.state = {
        appConstants: window.appConstants
    }
    }  
    NavUrl(value){
        window.location.href = window.location.href + value;
    }
    
  render() {
    var appConstants = this.state.appConstants;

    if(appConstants && appConstants.AppName === "CodeAndTech"){
        return (
        
            <div>
                <div style={{ marginTop: 20, padding: 30 }}>
                    <div className="pad-left-page">
                        <HomeCodeAndTech></HomeCodeAndTech>
                    </div>
                </div>
            </div>
        );
    }
    else{
        return (
            <div>
                <div style={{ marginTop: 20, padding: 30 }}>
                    <Grid container spacing={40} justify="center">
                        <TypoGraphy variant="h4">
                            <center>CloudOpsGurus !!!</center>
                        </TypoGraphy>
                    </Grid>
                    <div className="pad-left-page">
                        <HomeCloudOpsGuru></HomeCloudOpsGuru>

                        <MyBlogs></MyBlogs>
                    </div>
                </div>
            </div>
        )
    }
  }
}
 
export default Home;